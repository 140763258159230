import React from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
const GodaddyInstructions = (service) => {
  const portfolioLink =
    "https://sso.godaddy.com/?app=dcc&path=%2Fcontrol%2Fportfolio%3Fplid%3D";
  const generate = (service) => {
    switch (service) {
      case "google":
      case "microsoft":
      case "other":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            {service === "other" && (
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText>
                  Consult your email provider's documentation for instructions
                  on how to set up SPF (usually available with a quick google
                  search). Your email provider should provide a value for you to
                  paste into your GoDaddy DNS record in step 4.
                </ListItemText>
              </ListItem>
            )}
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to GoDaddy and navigate to your{" "}
                <a
                  href={portfolioLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                >
                  Domain portfolio
                </a>
                . Click on the domain you wish to configure, then <b>DNS</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Add New Record</b>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Under <b>Type</b>, select <b>"TXT"</b>.
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the <b>Host</b> field, enter the "@" symbol.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the <b>Value</b> field,
                    {service === "google"
                      ? "enter: v=spf1 include:_spf.google.com ~all"
                      : service === "microsoft"
                      ? "enter: v=spf1 include:spf.protection.outlook.com -all"
                      : "enter the value given by your email provider in step 1."}
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save</b>. Allow some time for the changes to propagate
                across the internet (usually up to 48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );

      default:
        return;
    }
  };
  return <>{generate(service)}</>;
};
const NamecheapInstructions = (service) => {
  const generate = (service) => {
    switch (service) {
      case "google":
      case "microsoft":
      case "other":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            {service === "other" && (
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText>
                  Consult your email provider's documentation for instructions
                  on how to set up SPF (usually available with a quick google
                  search). Your email provider should provide a value for you to
                  paste into your Namecheap DNS record in step 4.
                </ListItemText>
              </ListItem>
            )}
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to NameCheap and click <b>Domain List</b>, then Manage
                next to the domain you'd like to manage.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Advanced DNS</b>, then the red <b>Add New Record</b>{" "}
                button.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select <b>TXT Record</b>
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the <b>Host</b> field, enter the "@" symbol.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the <b>Value</b> field,
                    {service === "google"
                      ? "enter: v=spf1 include:_spf.google.com ~all"
                      : service === "microsoft"
                      ? "enter: v=spf1 include:spf.protection.outlook.com -all"
                      : "enter the value given by your email provider in step 1."}
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>. Allow some time for the changes to
                propagate across the internet (usually up to 48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      default:
        return;
    }
  };
  return <>{generate(service)}</>;
};
const OthersInstructions = (service) => {
  const generate = (service) => {
    switch (service) {
      case "google":
      case "microsoft":
      case "other":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            {service === "other" && (
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText>
                  Consult your email provider's documentation for instructions
                  on how to set up SPF (usually available with a quick google
                  search). Your email provider should provide a value for you to
                  paste into your domain host's DNS record in step 4.
                </ListItemText>
              </ListItem>
            )}
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your domain host and navigate to your domain's DNS
                settings.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Look for the <b>Add New Record</b> button and click on it.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select <b>TXT Record</b>
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the <b>Host</b> field, enter the "@" symbol.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the <b>Value</b> field,
                    {service === "google"
                      ? "enter: v=spf1 include:_spf.google.com ~all"
                      : service === "microsoft"
                      ? "enter: v=spf1 include:spf.protection.outlook.com -all"
                      : "enter the value given by your email provider in step 1."}
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>. Allow some time for the changes to
                propagate across the internet (usually up to 48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      default:
        return;
    }
  };
  return <>{generate(service)}</>;
};
const Spf = ({ domain, service }) => {
  const getInstructions = (domain, service) => {
    switch (domain) {
      case "godaddy":
        return GodaddyInstructions(service);

      case "namecheap":
        return NamecheapInstructions(service);
      case "other":
        return OthersInstructions(service);
      default:
        return;
    }
  };
  return (
    <Box sx={{ height: { xs: "100%", sm: "auto" } }}>
      {getInstructions(domain, service)}
    </Box>
  );
};

export default Spf;
