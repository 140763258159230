import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { subDays } from "date-fns";
import { DEFAULT_DAYS_ON_CHART } from "src/config";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));
const IntegarionsPage = lazy(() => import("src/pages/dashboard/integrations"))
const DFYInfraSetupOrderStatus = lazy(() => import("src/pages/dashboard/emailAccounts/orderStatus"))
const DFYInfraSetupAddNew = lazy(() => import("src/pages/dashboard/emailAccounts/AddNew"))

const Error404Page = lazy(() => import("src/pages/dashboard/404"));

// Academy
const AcademyDashboardPage = lazy(() =>
  import("src/pages/dashboard/academy/dashboard")
);
const AcademyCoursePage = lazy(() =>
  import("src/pages/dashboard/academy/course")
);

// Blog
const BlogPostListPage = lazy(() => import("src/pages/dashboard/blog/list"));
const BlogPostDetailPage = lazy(() =>
  import("src/pages/dashboard/blog/detail")
);
const BlogPostCreatePage = lazy(() =>
  import("src/pages/dashboard/blog/create")
);

// Customers
const CustomerListPage = lazy(() =>
  import("src/pages/dashboard/customers/list")
);
const CustomerDetailPage = lazy(() =>
  import("src/pages/dashboard/customers/detail")
);
const CustomerEditPage = lazy(() =>
  import("src/pages/dashboard/customers/edit")
);

// CRM
const DealPieline = lazy(() =>
  import("src/pages/dashboard/crm/dealPipeline")
);

// Invoice
const InvoiceListPage = lazy(() => import("src/pages/dashboard/invoices/list"));
const InvoiceDetailPage = lazy(() =>
  import("src/pages/dashboard/invoices/detail")
);

// Jobs
const JobBrowsePage = lazy(() => import("src/pages/dashboard/jobs/browse"));
const JobCreatePage = lazy(() => import("src/pages/dashboard/jobs/create"));
const CompanyDetailPage = lazy(() =>
  import("src/pages/dashboard/jobs/companies/detail")
);

// Logistics
const LogisticsDashboardPage = lazy(() =>
  import("src/pages/dashboard/logistics/dashboard")
);
const LogisticsFleetPage = lazy(() =>
  import("src/pages/dashboard/logistics/fleet")
);

// Orders
const OrderListPage = lazy(() => import("src/pages/dashboard/orders/list"));
const OrderDetailPage = lazy(() => import("src/pages/dashboard/orders/detail"));

// Products
const ProductListPage = lazy(() => import("src/pages/dashboard/products/list"));
const ProductCreatePage = lazy(() =>
  import("src/pages/dashboard/products/create")
);

// Social
const SocialFeedPage = lazy(() => import("src/pages/dashboard/social/feed"));
const SocialProfilePage = lazy(() =>
  import("src/pages/dashboard/social/profile")
);

// Other
const AccountPage = lazy(() => import("src/pages/dashboard/account"));
const AnalyticsPage = lazy(() => import("src/pages/dashboard/analytics"));
const BlankPage = lazy(() => import("src/pages/dashboard/blank"));
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const SupportCenterPage = lazy(() =>
  import("src/pages/dashboard/supportCenter")
);
const CryptoPage = lazy(() => import("src/pages/dashboard/crypto"));
const EcommercePage = lazy(() => import("src/pages/dashboard/ecommerce"));
const FileManagerPage = lazy(() => import("src/pages/dashboard/file-manager"));
const KanbanPage = lazy(() => import("src/pages/dashboard/kanban"));
const MailPage = lazy(() => import("src/pages/dashboard/mail"));
const MailRepliesPage = lazy(() => import("src/pages/dashboard/mailReplies"));
const ConversationsPage = lazy(() =>
  import("src/pages/dashboard/salesConversation")
);
const UserConversationsPage = lazy(() =>
  import("src/pages/dashboard/conversations")
);

const MainConversationPage = lazy(() =>
  import("src/pages/dashboard/mainconversation")
);
const AllCustomers = lazy(() =>
  import("src/pages/dashboard/customers/allCustomers")
);
const ComingSoon = lazy(() =>
  import("src/pages/dashboard/ComingSoon")
);
const DashboardPage = lazy(() =>
  import("src/pages/dashboard/dashboard")
);
const SpamCheckerPage = lazy(() =>
  import("src/pages/dashboard/freeTools/spam-checker")
);
const MeetingSchedulerPage = lazy(() =>
  import("src/pages/dashboard/freeTools/meeting-scheduler")
);
const EventTypePage = lazy(() =>
  import("src/sections/dashboard/free-tools/meeting-scheduler/create-event-type")
);
const ProspectListsPage = lazy(() =>
  import("src/pages/dashboard/prospects/Lists")
);

const ProspectSearchPage = lazy(() =>
  import("src/pages/dashboard/prospects/Search")
);

const EmailAccountsManagePage = lazy(() =>
  import("src/pages/dashboard/emailAccounts/Manage")
);

const NewDashboardPage = lazy(() =>
  import("src/pages/dashboard/dashboard")
);
export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <Error404Page />,
      },
      { path: "bdraiagent", element: <ComingSoon /> },
      { path: "dashboard", element: <NewDashboardPage /> },
      {
        path: "leads",
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: "all",
            element: <AllCustomers />,
          },
        ],
      },
      { path: "integrations", element: <IntegarionsPage /> },
      {
        path: "academy",
        children: [
          {
            index: true,
            element: <AcademyDashboardPage />,
          },
          {
            path: "courses",
            children: [
              {
                path: ":courseId",
                element: <AcademyCoursePage />,
              },
            ],
          },
        ],
      },
      {
        path: "blog",
        children: [
          {
            index: true,
            element: <BlogPostListPage />,
          },
          {
            path: "create",
            element: <BlogPostCreatePage />,
          },
          {
            path: ":postId",
            element: <BlogPostDetailPage />,
          },
        ],
      },
      {
        path: "contacts",
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: "all",
            element: <AllCustomers />,
          },
          {
            path: ":customerId",
            element: <CustomerDetailPage />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEditPage />,
          },
        ],
      },
      {
        path: "userconversations",
        children: [
          {
            index: true,
            element: <UserConversationsPage />,
          },
        ],
      },
      {
        path: "conversations",
        children: [
          {
            index: true,
            element: <ConversationsPage />,
          },
        ],
      },
      {
        path: "emailreplies",
        children: [
          {
            index: true,
            element: <ConversationsPage />,
          },
        ],
      },
      {
        path: "mainconversation",
        children: [
          {
            index: true,
            element: <MainConversationPage />,
          },
        ],
      },
      {
        path: "invoices",
        children: [
          {
            index: true,
            element: <InvoiceListPage />,
          },
          {
            path: ":invoiceId",
            element: <InvoiceDetailPage />,
          },
        ],
      },
      {
        path: "campaigns",
        children: [
          {
            index: true,
            element: <JobBrowsePage />,
          },
          {
            path: "create",
            element: <JobCreatePage />,
          },
          {
            path: "edit",
            element: <JobCreatePage />,
          },
          {
            path: "companies",
            children: [
              {
                path: ":companyId",
                element: <CompanyDetailPage />,
              },
            ],
          },
        ],
      },
      {
        path: "crm",
        children: [
          {
            path: "index",
            // element: <ProspectListsPage />,
            element: <DealPieline />
          },
          {
            path: "lists",
            element: <ProspectListsPage />,
            // element: <ComingSoon />
          },
          {
            path: "search",
            element: <ProspectSearchPage />,
            // element: <ComingSoon />
          },
          {
            path: "enrich",
            element: <ComingSoon />,
          },
          {
            path: "validate",
            element: <ComingSoon />,
          },
          {
            path: "cleanup",
            element: <ComingSoon />,
          },
          {
            path: "intentdata",
            element: <ComingSoon />,
          },
        ],
      },
      {
        path: "emailaccounts",
        // element: <ComingSoon />,
        children: [
          {
            index: true,
            // element: <ProspectListsPage />,
            element: <ComingSoon />
          },
          {
            path: "manage",
            // element: <ProspectListsPage />,
            element: <EmailAccountsManagePage />,
          },
          {
            path: "unifiedinbox",
            // element: <ProspectListsPage />,
            element: <ComingSoon />
          },
          {
            path: "warmup",
            // element: <ProspectSearchPage />,
            element: <ComingSoon />
          },
          {
            path: "engagecampaigns",
            element: <ComingSoon />,
          },
          {
            path: "orderstatus",
            element: <DFYInfraSetupOrderStatus />,

          },
          {
            path: "orderstatus/add",
            element: <DFYInfraSetupAddNew />,
          },
        ],
      },
      {
        path: "freetools",
        children: [
          {
            index: true,
            element: <ComingSoon />,
          },
          
          {
            path: "spamchecker",
            element: <SpamCheckerPage />,
          },
          {
            path: "unifiedcalendar",
            element: <ComingSoon />,
          },
          {
            path: "unifiedinbox",
            element: <ComingSoon />,
          },
          {
            path: "meetingscheduler",
            children:[
              {
                index:true,
                element: <ComingSoon />,
              },
              {
                path:"event_types",
                element:<ComingSoon />
              }
            ]
          },
        ]
      },
      {
        path: "logistics",
        children: [
          {
            index: true,
            element: <LogisticsDashboardPage />,
          },
          {
            path: "fleet",
            element: <LogisticsFleetPage />,
          },
        ],
      },
      // {
      //   path: "campaigns",
      //   children: [
      //     {
      //       index: true,
      //       element: <OrderListPage />,
      //     },
      //     {
      //       path: ":orderId",
      //       element: <OrderDetailPage />,
      //     },
      //   ],
      // },
      {
        path: "icp",
        children: [
          {
            index: true,
            element: <ProductCreatePage />,
          },
          {
            path: "create",
            element: <ProductListPage />,
          },
        ],
      },
      {
        path: "social",
        children: [
          {
            path: "feed",
            element: <SocialFeedPage />,
          },
          {
            path: "profile",
            element: <SocialProfilePage />,
          },
        ],
      },
      {
        path: "replies",
        element: <MailRepliesPage />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "analytics",
        element: <AnalyticsPage />,
      },
      {
        path: "blank",
        element: <BlankPage />,
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "chat",
        element: <ChatPage />,
      },
      {
        path: "supportCenter",
        element: <SupportCenterPage />,
      },
      {
        path: "crypto",
        element: <CryptoPage />,
      },
      {
        path: "ecommerce",
        element: <EcommercePage />,
      },
      {
        path: "file-manager",
        element: <FileManagerPage />,
      },
      {
        path: "kanban",
        element: <KanbanPage />,
      },
      {
        path: "mail",
        element: <MailPage />,
      },
      {
        path: "*",
        element: <Error404Page />,
      },
    ],
  },
];
