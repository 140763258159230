import Parser from "react-html-parser";

export const html = (txt) => {
  return Parser(txt);
};

export function textFromHTML(html) {
  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}
