import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import { GmailIcon } from "src/components/logos/gmailIcon";
import { MicrosoftIcon } from "src/components/logos/microsoftIcon";
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  PlayArrowOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";

const ConnectGoogleAccount = ({
  activeStepGAcc,
  setActiveStepGAcc,
  handleConnectGoogleAccount,
}) => {
  return (
    <>
      {activeStepGAcc === 0 ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              borderBottom: `1px solid  ${alpha("#000000", 0.05)}`,
              pb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#28287B",
              }}
            >
              Before moving forward{" "}
              <span style={{ fontWeight: 700 }}> please enable IMAP </span>
              access on your Google account
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              pb: 1,
              borderBottom: `1px solid  ${alpha("#000000", 0.05)}`,
            }}
          >
            <Box
              sx={{
                width: "9 0%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#28287B",
                }}
              >
                <ol style={{ paddingLeft: "12px" }}>
                  <li style={{ marginTop: "16px" }}>
                    Open Gmail on your computer.
                  </li>
                  <li style={{ marginTop: "16px" }}>
                    Click the <span style={{ fontWeight: 700 }}>Gear</span> icon
                    in the top right.
                  </li>
                  <li style={{ marginTop: "16px" }}>
                    Select{" "}
                    <span style={{ fontWeight: 700 }}>All Settings.</span>
                  </li>
                  <li style={{ marginTop: "16px" }}>
                    Navigate to the{" "}
                    <a
                      style={{
                        //color: theme.palette.primary.main,
                        cursor: "pointer",
                        textDecoration: "none",
                        fontSize: "13px",
                        fontWeight: "700",
                      }}
                      target="_blank"
                      href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                      rel="noreferrer"
                    >
                      Forwarding and POP/IMAP
                    </a>{" "}
                    tab.
                  </li>
                  <li style={{ marginTop: "20px" }}>
                    Under "IMAP access", select{" "}
                    <span style={{ fontWeight: 700 }}>Enable IMAP.</span>
                  </li>
                  <li style={{ marginTop: "20px" }}>
                    Click <span style={{ fontWeight: 700 }}>Save Changes.</span>
                  </li>
                </ol>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              href="https://app.guidde.com/share/playbooks/wFYrPhKqeENCyMwSjgWiUS?origin=krEdJl3tAuZrHcztlBEPVGgKak32"
              target="_blank"
              fullWidth
            //sx={{ color: theme.palette.primary.main, fontWeight: 700, fontSize: "14px", mt: 2 }}
            >
              Show me step-by-step
              <ArrowForwardOutlined
                fontSize="small"
                sx={{ color: "#0071F6", ml: 1 }}
              />
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                fontSize: "14px",
                px: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #0071F6",
                py: 1.5,
                // width: { xs: "100%", sm: "fit-content" },

                "&:hover": {
                  boxShadow: 15,
                },
              }}
              onClick={() => {
                setActiveStepGAcc(1);
              }}
            >
              I confirm that IMAP has been enabled
            </Button>
          </Box>
        </>
      ) : activeStepGAcc === 1 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              gap: 2,
              borderBottom: `1px solid  ${alpha("#000000", 0.05)}`,
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                borderRadius: 2,
                p: 4,
                py: 3,
                backgroundColor: alpha("#5862ff", 0.1), // 'rgb(245, 245, 245)',
                border: "1px solid #5862ff", //'1px solid rgb(224, 224, 224)',

                // boxShadow: "0px 0px 10px -1px rgba(5, 124, 251, 0.75)",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                width: { xs: "100%", sm: "45%", md: "330px" },
                cursor: "pointer",
                height: { xs: "fit-content", sm: "100%" },
                "&:hover": {
                  backgroundColor: alpha("#5862ff", 0.15),
                },
              }}
              onClick={() => {
                setActiveStepGAcc(3);
              }}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: 600, fontSize: "15px" }}
              >
                oAuth{" "}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter, sans-serif",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1,
                    color: "#050C46",
                    mt: 3,
                  }}
                >
                  <Box sx={{ pt: 0.3 }}>
                    {" "}
                    <TaskAltOutlined sx={{ fontSize: "16px" }} />
                  </Box>
                  Faster & easier to setup
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter, sans-serif",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1,
                    color: "#050C46",
                    my: 1,
                  }}
                >
                  <Box sx={{ pt: 0.3 }}>
                    {" "}
                    <TaskAltOutlined sx={{ fontSize: "16px" }} />
                  </Box>
                  Greater stability and fewer disconnections
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter, sans-serif",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1,
                    color: "#050C46",
                  }}
                >
                  <Box sx={{ pt: 0.3 }}>
                    {" "}
                    <TaskAltOutlined sx={{ fontSize: "16px" }} />
                  </Box>
                  Available for GSuite users
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#00AA38",
                  border: "1px solid #00AA38",
                  py: 1,
                  px: 2,
                  borderRadius: 1,
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Inter, sans-serif",
                  mt: 2,
                  backgroundColor: "#ffffff",
                }}
              >
                Recommended
              </Typography>
            </Box>
          </Box>{" "}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "#0071F6" }}
              onClick={() => {
                setActiveStepGAcc(0);
              }}
            >
              <ArrowBackOutlined fontSize="small" sx={{ mr: 1 }} />
              Back
            </Button>
          </Box>
        </>
      ) : activeStepGAcc === 3 ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#28287B",
              }}
            >
              Please grant B2brocket.ai access to your Google Workspace.
            </Typography>
            <Typography
              sx={{
                // backgroundColor: "#DBF9E5",
                py: 1,
                px: 0.75,
                borderRadius: 1,
                fontSize: "11px",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",

                my: 0.8,
                mt: 1.5,
                color: "#00AA38",
                border: "1px solid #DAEFDF",
              }}
            >
              This action is necessary only once for each domain
            </Typography>
            <Button
              href="https://app.guidde.com/share/playbooks/6XKKtvUaYNDRTpJmnjdzLT?origin=VHyVOGLjEjUdXmnOjVbUzsMXXI22"
              target="_blank"
              sx={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #0071F6",
                mb: 3,
                "&:hover": {
                  boxShadow: 15,
                },
              }}
              variant="contained"
              onClick={() => {
                window.location.href =
                  "https://app.guidde.com/share/playbooks/6XKKtvUaYNDRTpJmnjdzLT?origin=VHyVOGLjEjUdXmnOjVbUzsMXXI22";
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: 1,
                }}
              >
                <PlayArrowOutlined
                  sx={{ fontSize: "24px", color: "#ffffff" }}
                />
              </Box>
              Watch Tutorial Video
            </Button>
          </Box>
          <Box sx={{ width: "100%", py: 2 }}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#28287B",
              }}
            >
              <ol style={{ paddingLeft: "12px" }}>
                <li>
                  Open your{" "}
                  <span
                    style={{
                      color: "#036AFC",
                      cursor: "pointer",
                      fontWeight: 700,
                    }}
                  >
                    <a
                      href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps"
                      target="_blank"
                    >
                      Google Workspace Admin Panel
                    </a>
                  </span>
                </li>
                <li style={{ marginTop: "25px" }}>
                  Click on <span style={{ fontWeight: 700 }}>"Add App"</span>{" "}
                  and choose{" "}
                  <span style={{ fontWeight: 700 }}>
                    "OAuth App Name or Client ID"
                  </span>
                </li>
                <li style={{ marginTop: "25px" }}>
                  Use the following Client ID to locate B2brocket.ai:
                  <br />
                  <Box
                    sx={{
                      backgroundColor: "#F2F4F6",
                      padding: 2,
                      borderRadius: 2,
                      mt: 1,
                      ml: "-12px",
                      width: { xs: "100%", sm: "fit-content" },
                      overflowWrap: "break-word",
                    }}
                  >
                    77176128363-v1m3kia8q8gng7ikn7fujd9h08903ttf.apps.googleusercontent.com
                  </Box>
                </li>
                <li style={{ marginTop: "25px" }}>
                  Select and authorize B2brocket.ai for access to your Google
                  Workspace
                </li>
              </ol>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTop: `1px solid ${alpha("#000000", 0.05)}`,
              gap: 3,
            }}
          >
            <Button
              sx={{ color: "#0071F6", width: "50%" }}
              variant="outlined"
              onClick={() => {
                setActiveStepGAcc(1);
              }}
            >
              <ArrowBackOutlined
                fontSize="small"
                sx={{ color: "#0071F6", mr: 1 }}
              />
              Back
            </Button>{" "}
            <Button
              sx={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #0071F6",
                width: { xs: "100%", sm: "50%" },
                "&:hover": {
                  boxShadow: 15,
                },
              }}
              onClick={handleConnectGoogleAccount}
              variant="contained"
            >
              Sign In
              <ArrowForwardOutlined
                fontSize="small"
                sx={{ color: "#ffffff", ml: 1 }}
              />
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

const ConnectNewAccount = ({
  handleConnectGoogleAccount,
  handleConnectMicrosoftAccount,
  showInstructions
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepGAcc, setActiveStepGAcc] = useState(0);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
          // pb: 12,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // py: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              flexDirection: "column",
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 1
            }}
          >
            {activeStep !== 0 && (
              <>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    color: "#0071F6",
                    cursor: "pointer",
                    mb: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (activeStepGAcc !== 0) {
                      setActiveStepGAcc(0);
                    }

                    setActiveStep(0);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: 1,
                    }}
                  >
                    <ArrowBackOutlined
                      fontSize="small"
                      sx={{ color: "#0071F6", mr: 1 }}
                    />
                  </Box>
                  Choose a Different Email Provider
                </Typography>
              </>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {activeStep === 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Stack
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        width: { xs: "100px", sm: "auto" },
                        height: { xs: "100px", sm: "auto" },
                      }}
                      onClick={() => showInstructions ? setActiveStep(1) : handleConnectGoogleAccount()}
                    >
                      <GmailIcon />
                    </Button>

                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      Google Workspace
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        width: { xs: "100px", sm: "auto" },
                        height: { xs: "100px", sm: "auto" },
                        // height: "144px",
                        // width:'144px'
                      }}
                      onClick={handleConnectMicrosoftAccount}
                    >
                      <MicrosoftIcon />
                    </Button>

                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      Microsoft
                    </Typography>
                  </Stack>
                </Box>
              </>
            ) : activeStep === 1 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                    boxShadow: "0px 12px 15px 0px #4B71970D",
                    backgroundColor: "white",
                    borderRadius: "12px",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mb: 3,
                    }}
                  >
                    <GmailIcon size="50px" />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-around",
                        ml: 1.5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "18px",
                          color: "#28287B",
                        }}
                      >
                        Connect your Google Account
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          color: "#8181B0",
                          mt: 1,
                        }}
                      >
                        Gmail / G-Suite
                      </Typography>
                    </Box>
                  </Box>
                  <ConnectGoogleAccount
                    activeStepGAcc={activeStepGAcc}
                    setActiveStepGAcc={setActiveStepGAcc}
                    handleConnectGoogleAccount={handleConnectGoogleAccount}
                  />
                </Box>
              </>
            ) : activeStep === 2 ? (
              <></>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ConnectNewAccount;
