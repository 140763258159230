import React, { useEffect, useState } from 'react'
import { API_SERVICE_BACKEND_2 } from "src/config"
import { useAuth } from 'src/contexts/auth/firebase-context';
import OAuthIntegration from "./auth-integration"
import ApiTokenIntegration from "./api-token-integration"

const PipedriveIntegrationSettings = ({
  updateIntegrationAutoSyncFlag = (email = '', type = '', isAuto = false) => {},
  openSnackbar
}) => {

  return (
    <>
      <ApiTokenIntegration openSnackbar={openSnackbar} updateIntegrationAutoSyncFlag={updateIntegrationAutoSyncFlag} />

      {/* <OAuthIntegration updateIntegrationAutoSyncFlag={updateIntegrationAutoSyncFlag} /> */}
    </>
  )
}

export default PipedriveIntegrationSettings