import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { padding } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
// import CloseOutlineIcon from "@mui/icons-material/CloseOutlined";

const {
  Accordion,
  alpha,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Badge,
  Chip,
  renderTags,
  view,
  Box,
  Button,
  CloseOutlineIcon,
  ExpandLessIcon,
  Stack,
} = require("@mui/material");

const Wrapper = (props) => {
  // const [expanded, setExpanded] = useState(false);
  const expanded = props.expand == props.label;
  const handleChange = () => {
    // setExpanded();
    if (expanded) {
      props.setExpand("");
    } else {
      props.setExpand(props.label);
    }
  };

  // console.log({ filters: props.filters });

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        borderRadius: props.variant === "h1" ? "8px" : "0",
        ...props.style,
        "& .MuiAccordion-root:last-of-type": {
          m: 1,
          my: props.variant === "h1" ? 1 : 0,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        },
        "&.Mui-expanded": {
          border:
            props.variant === "h2"
              ? `1px solid ${alpha("#5862ff", 0.1)}`
              : "auto",
          margin: props.variant === "h1" ? "8px 0" : 0,
        },
        "&:before": {
          display: "none",
        },
        my: props.variant === "h1" ? 1 : 0,
        boxShadow:
          props.variant === "h1"
            ? `0px 0px 1px 1px ${alpha("#6C737F", 0.2)}`
            : "none",
        "& .MuiSvgIcon-root": {
          color: expanded ? "#5761FE" : "#5761FE",
        },
        "& .MuiAccordionDetails-root": {
          pt: 0,
        },
        // "&.Mui-!expanded": {
        //   color: "#5761FE",
        // },
      }}
    >
      <Box>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            "&.Mui-expanded": { minHeight: "48px" },
            "& .MuiAccordionSummary-content.Mui-expanded": { my: "12px" },
            backgroundColor: props.variant === "h2" ? "#F5F2FF" : "auto",
            border: props.variant === "h2" ? "1px solid #ECE9F5" : "auto",
            mb: props.variant === "h2" && expanded ? 2 : "auto",
            ...props.style,
            "& .MuiSvgIcon-root": {
              color: expanded ? "#5761FE" : "#5761FE",
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                fontSize: "14px",
                color: "#111927",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  color: "#111927",
                  display: "flex",
                  fontWeight: "700",
                  alignItems: "center",
                  gap: 2,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {props.label}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                  color: "#5761FE",
                }}
              >
                {!!props.count && (
                  <Chip
                    label={props.count}
                    size="small"
                    deleteIcon={
                      <CloseIcon
                        sx={{ fontSize: "10px", mr: "3px", color: "#5761FE" }}
                      />
                    }
                    onDelete={() => {
                      props.clearFilter();
                    }}
                    sx={{
                      ml: 1,
                      fontWeight: "bold",
                      color: "#5761FE",
                      backgroundColor: "white",
                      border: "1px solid #5761FE",
                      borderRadius: "5px",
                      fontSize: "12px",
                      px: "5px",
                      height: "23px",
                      "& .MuiChip-label": {
                        padding: "0 5px",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}

                {props.isError && !expanded && (
                  <CloseIcon sx={{ color: "#5761FE", fontSize: "10px" }} />
                )}
              </Box>
            </Box>

            {!expanded && (
              <Stack spacing={1} sx={{ mt: !!props.count ? 1 : 0 }}>
                {props.filters &&
                  props.filters?.map((filter, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: filter?.value?.length > 0 ? "flex" : "none",
                        gap: 0.5,
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "13px", p: 0.5, whiteSpace: "nowrap" }}
                      >
                        {filter?.label}:
                      </Typography>

                      {filter?.value &&
                        filter?.value.map((item, index) => (
                          <Chip
                            size="small"
                            color="primary"
                            label={item}
                            onDelete={() => {
                              filter?.setValue(item);
                            }}
                            sx={{
                              fontSize: "12px",
                              borderRadius: "5px",
                              height: "25px",
                            }}
                          />
                        ))}
                    </Box>
                  ))}
              </Stack>
            )}
          </Box>
        </AccordionSummary>
      </Box>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default Wrapper;
