import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";

function CouponBox({ disBtn, agreeFunction, priceid, setDisBtn, setAlertModal,conponError,loading}) {
  const [open, setOpen] = React.useState(disBtn);
  const [coupon, setCoupon] = React.useState("")
  // const [loading, setLoading] = React.useState(false);
  const handleChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setDisBtn(false)
    setAlertModal(false)
  };
  const handleWithoutCopon= () => {
    agreeFunction(priceid, "")
  };
  const handleAgry = () => {
    // setLoading(true)
    agreeFunction(priceid, coupon)
    // setLoading(false)
    // setOpen(false);
  }

  return (
    <React.Fragment>
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Alert"} */}
          Do you have a coupon code?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Changes made will be immediately reflected in your account. Are you sure you want to change your plan?, {conponError && (<h2>{conponError}</h2>)} */}
            <TextField
          autoFocus
          margin="dense"
          id="couponCode"
          label="Enter coupon code"
          type="text"
          fullWidth
          value={coupon}
              onChange={handleChange}
              error={!!conponError}
              helperText={conponError ?conponError : ""} 
        />
          </DialogContentText>
          
        </DialogContent>
        
              <DialogActions>
                  {/* <Button onClick={handleClose}>Cancel</Button> */}
                  {loading && <CircularProgress size={24} />}
                  <Button  onClick={handleWithoutCopon} disabled={coupon?true:false}>
                   Without Coupon
                  </Button>
                  
                  <Button onClick={handleAgry} disabled={coupon?false:true} autoFocus>
           Apply Coupon
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default CouponBox
