import React from 'react'
import { Seo } from "src/components/seo";
import {
  Box,
  Container,
  Stack,
  Typography,
  Divider,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import { Link } from 'react-router-dom';

const ZapierInstructions = () => {

  return (
    <>
      <Seo title="B2B Rocket Dashboard" />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.50',
          pb: '120px',
          pt: '184px'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3} sx={{ mb: 3 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ color: "#050C46", fontSize: "32px", fontWeight: "700" }}
              >
                {'Zapier Instructions'}
              </Typography>
            </div>

            <Divider />
          </Stack>

          <Box sx={{margin: "16px 0"}}>
            <ListItem sx={{marginBottom: 1}}>
              <ListItemIcon>
                {1}
              </ListItemIcon>
              <Typography>
              <p>
                {'To access your'} <BoldText>{'B2B Rocket account'}</BoldText> {', please click on the'}&nbsp;
                <Link to={'/auth-main/login/v2'}>
                  <BoldText>{'Sign In'}</BoldText>
                </Link>&nbsp;
                {'button. This will direct you to the login page where you can enter your credentials to securely access your account and manage your business operations efficiently.'}</p>
              </Typography>
            </ListItem>
          </Box>

          <Box sx={{margin: "16px 0"}}>
            <ListItem sx={{marginBottom: 1}}>
              <ListItemIcon>
                {2}
              </ListItemIcon>
              <Typography>
                <p>{'Please select the'} <BoldText>{'Settings'}</BoldText> {'button located at the bottom left of the sidebar.'}</p>
              </Typography>
            </ListItem>

            <Box
              alt="setttings"
              component="img"
              src="/assets/zapier-instructions/zapier-instructions-setttings.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: "100%",
                border: "1px solid #f1f1f1",
                borderRadius: "20px"
              }}
            />
          </Box>

          <Box sx={{margin: "16px 0"}}>
            <ListItem sx={{marginBottom: 1}}>
              <ListItemIcon>
                {3}
              </ListItemIcon>
              <Typography>
                <p>{'In the Settings menu, please navigate to the'} <BoldText>{'Integrations'}</BoldText> {'section by selecting the corresponding tab.'}</p>
              </Typography>
            </ListItem>
            
            <Box
              alt="integration"
              component="img"
              src="/assets/zapier-instructions/zapier-instructions-integration.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: "100%",
                border: "1px solid #f1f1f1",
                borderRadius: "20px"
              }}
            />
          </Box>

          <Box sx={{margin: "16px 0"}}>
            <ListItem sx={{marginBottom: 1}}>
              <ListItemIcon>
                {4}
              </ListItemIcon>
              <Typography>
                <p>{'Next to the zapier logo, Click on the '} <BoldText>{'Switch Button'}</BoldText> {'to turn it ON. The appearance of the switch should change to indicate that it is now in the ON position.'}</p>
              </Typography>
            </ListItem>
            
            <Box
              alt="switch"
              component="img"
              src="/assets/zapier-instructions/zapier-instructions-switch.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: "100%",
                border: "1px solid #f1f1f1",
                borderRadius: "20px"
              }}
            />
          </Box>

          <Box sx={{margin: "16px 0"}}>
            <ListItem sx={{marginBottom: 1}}>
              <ListItemIcon>
                {5}
              </ListItemIcon>
              <p><BoldText>{'The Zapier API Key'}</BoldText>&nbsp;{'can be found below the Zapier logo. Please copy the Zapier API Key for use in the subsequent steps.'}</p>
            </ListItem>
            
            <Box
              alt="token"
              component="img"
              src="/assets/zapier-instructions/zapier-instructions-token.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: "100%",
                border: "1px solid #f1f1f1",
                borderRadius: "20px"
              }}
            />
          </Box>

          <Box sx={{margin: "8px 0"}}>
            <ListItem>
              <ListItemIcon>
                {6}
              </ListItemIcon>
              <BoldText>{'Access Zapier Account'}</BoldText>
            </ListItem>
            <ul style={{marginLeft: "24px"}}>
              <li><p>{'Login to'}&nbsp;<BoldText>{'Zapier'}</BoldText>&nbsp;{'account.'}</p></li>
              <li><p>Once logged in, click on the <BoldText>{'Zap'}</BoldText> button on the left sidebar of the dashboard.</p></li>
              <li><p>Cilck on <BoldText>{'Create'}</BoldText> button to create a New Zap.</p></li>
              <li>
                <p>{'Assign a descriptive name to your Zap, such as "B2B Rocket Lead Access Integration," to help you easily identify it later. In the '}<BoldText>Choose trigger</BoldText>{' section, type "B2B Rocket" in the search bar and select the B2B Rocket app from the list of available apps.'}</p>
                <Box
                  alt="zapier choose trigger"
                  component="img"
                  src="/assets/zapier-instructions/zapier-instructions-choose-trigger.png"
                  sx={{
                    height: 'auto',
                    maxWidth: '600px',
                    width: "100%",
                    margin: "auto",
                    border: "1px solid #f1f1f1",
                    borderRadius: "20px"
                  }}
                />
              </li>

              <li>
                <p>{'Select Triggered app '}<BoldText>B2B Rocket AI</BoldText>{' and choose event from '}<BoldText>App & event</BoldText>{' Tab. Click on Continue and move to the next step.'}</p>

                <Box
                  alt="zapier choose app event"
                  component="img"
                  src="/assets/zapier-instructions/zapier-instructions-choose-app-event.png"
                  sx={{
                    height: 'auto',
                    maxWidth: '600px',
                    width: "100%",
                    margin: "auto",
                    border: "1px solid #f1f1f1",
                    borderRadius: "20px"
                  }}
                />
              </li>

              <li>
                <p>{'In '}<BoldText>{'Account'}</BoldText>{' Tab, If this is your first time using the B2B Rocket app with Zapier, you will need to connect B2B Rocket AI account. Click on the '}<BoldText>{"Sign in"}</BoldText>{' button. A new window will appear, prompting you to enter your B2B Rocket API key and Email Address. Retrieve this key from the API settings or integration section within your B2B Rocket account.'}</p>

                <Box
                  alt="zapier popup prompt"
                  component="img"
                  src="/assets/zapier-instructions/zapier-popup-prompt.png"
                  sx={{
                    height: 'auto',
                    maxWidth: '600px',
                    width: "100%",
                    margin: "auto",
                    border: "1px solid #f1f1f1",
                    borderRadius: "20px"
                  }}
                />
              </li>
              <li><p>{'Enter the '} <BoldText>{'Zapier API key'}</BoldText> {' in the provided field and authorize Zapier to access your B2B Rocket account by clicking '} <BoldText>{'Yes, Continue'}</BoldText></p></li>
            </ul>
          </Box>

        </Container>
      </Box>
    </>
  )
}

export default ZapierInstructions

const BoldText = ({children}) => {
  return <span style={{ color: "#050C46", fontSize: "16px", fontWeight: "700" }}>{children}</span>
}