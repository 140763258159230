import { styled } from '@mui/material/styles';
import { Switch
  } from "@mui/material";

export const CustomSwitch  = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 40,
      height: 26,
      padding: 1,
      margin: "10px 10px 10px 10px",
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 42
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)"
    }
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#00AA38"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 22,
    height:22,
    borderRadius: '50%',
    transition: theme.transitions.create(["width"], {
      duration: 200
    })
  },
  "& .MuiSwitch-track": {
    borderRadius: 40 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "red" : "rgba(0,0,0,.25)",
    boxSizing: "border-box"
  }
  }));  