import React from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { DocusealForm } from "@docuseal/react";
import { API_SERVICE_BACKEND, selfURL } from "src/config";
import { useParams } from "react-router-dom";

const handleChange = async (data) => {
  // console.log(key, value)
  if (data.status == "completed") {
    const res = await axios.patch(`${API_SERVICE_BACKEND}/patchUser`, {
      email: data.email,
      payload: {
        signature: {
          status: data.status,
          signatureId: data.submission_id,
          signatureUrl: data.submission_url,
        },
      },
    });

    if (res.data.status) {
      //   window.location.href = `${selfURL}/dashboard/account`;
    } else {
      toast.error(
        "Something went wrong with signature, please try again later"
      );
    }
  } else {
    toast.error("Something went wrong with signature, please try again later");
  }
};

const SignaturePage = () => {
  //   return <DocusealForm src="https://docuseal.co/d/YcmMPUwczxmDP5" />;

  let { email } = useParams();
  console.log("email: ", email);

  return (
    <div className="app">
      <DocusealForm
        src="https://docuseal.co/d/YcmMPUwczxmDP5"
        completedButton={{
          title: "Click me to finish",
          url: `${selfURL}/dashboard/account`,
        }}
        email={email}
        onComplete={(data) => handleChange(data)}
      />
    </div>
  );
};

export default SignaturePage;
