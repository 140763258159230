import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { API_SERVICE_BACKEND } from "src/config";
import { useEffect, useState } from "react";
import axios from "axios";


const ViewEmailReplies = (props) => {
    const {
        viewEmail,
        setViewEmail,
        description,
        email,
        emailBody,
        leadId
    } = props
    const handleClose = () => {
        setViewEmail(false)
    }

    const [loading, setLoading] = useState(false)
    const [leadResponseData, setLeadResponseData] = useState(null)

    const getLeadResponse = async (leadId) => {
        try {
            setLoading(true);
            const res = await axios.get(
                `${API_SERVICE_BACKEND}/getLeadResponse/${leadId}`
            );
            const conversationBody = res?.data?.conversations?.length ? res?.data?.conversations?.map((conv) => conv.body).reverse().join("<hr /> ") : res?.data?.body
            setLeadResponseData(conversationBody?.split("\n")
                .map((line) =>
                    line.startsWith(">") ? line.substring(1) : line
                )
                .join("\n"))
        } catch (error) {
            console.log(error.message)
            return;
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        leadId && getLeadResponse(leadId)
    }, [leadId])

    return (
        <Dialog
            open={viewEmail}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    fontSize: "22px",
                    fontWeight: "700",
                    color: "#050C46",
                }}
            >
                Email Replies
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{
                        position: "absolute",
                        right: 8,
                        top: 4,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Stack rowGap={1}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#050C46",
                            }}
                        >
                            {description}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#050C46",
                            }}
                        >
                            <span style={{ color: "#55597A", marginRight: "5px" }}>
                                From:
                            </span>
                            {email}
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                mt: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                flexDirection: "column",
                            }}
                        >
                            {loading ?
                                <center>
                                    <CircularProgress />
                                </center>
                                : <div
                                    style={{
                                        whiteSpace: "pre-wrap",
                                        fontSize: "14px",
                                        textAlign: "left",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: leadResponseData ?? emailBody,
                                    }}
                                />}
                        </Box>
                    </Stack>
                </DialogContentText>
            </DialogContent>

        </Dialog>
    );
};

export default ViewEmailReplies;
