import PropTypes from "prop-types";

import { Box, Stack, Typography } from "@mui/material";

export const TenantSwitch = (props) => {
  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box sx={{ flexGrow: 1 }}>
          {/* <Typography
            color="inherit"
            variant="h6"
          >
            {companyName}
          </Typography> */}
          <Typography color="inherit" variant="h5">
            B2B Rocket
          </Typography>
        </Box>
        {/* <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon sx={{ fontSize: 16 }}>
            <ChevronDownIcon />
          </SvgIcon>
        </IconButton> */}
      </Stack>
      {/* <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={tenants}
      /> */}
    </>
  );
};

TenantSwitch.propTypes = {
  sx: PropTypes.object,
};
