import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { RouterLink } from "src/components/router-link";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import {  API_SERVICE_BACKEND_2 } from "src/config";
import UnsubscribeTwoToneIcon from '@mui/icons-material/UnsubscribeTwoTone';
import { Seo } from "src/components/seo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  paper: {
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    backgroundImage: "linear-gradient(to right, #A756F5, #22C45F, #3A82F4)",
    minWidth: "min-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    maxWidth: "612px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  innerPaper: {
    padding: "16px",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "100%",
    height: "50px",
    objectFit: "contain",
    marginBottom: theme.spacing(2),
  },
  checkIcon: {
    fontSize: "6rem",
    color: "#22C55E",
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    background: `linear-gradient(to right, #417FF6, #A557F7)`,
    WebkitBackgroundClip: "text",
    color: "transparent",
  },
  content: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0),
    backgroundColor: "#5762FD",
    color: "#fff",
    borderRadius: "999px",
    "&:hover": {
      backgroundColor: "#5762FD",
    },
  },
  buttonIcon: {
    fontSize: "1.5rem",
  },
}));

function Unsubscribe() {
  const { uid } = useParams();
  const classes = useStyles();
  const navigate = useNavigate(); 

  useEffect(() => {
    const faviconIco = document.createElement('link');
    const favIcon1=document.getElementById("icon1")
    const favIcon2=document.getElementById("icon2")
    const favIcon3=document.getElementById("icon3")

    document.head.removeChild(favIcon1)
    document.head.removeChild(favIcon2)
    document.head.removeChild(favIcon3)

    faviconIco.rel = 'icon';
    faviconIco.href = "/internet.png";
    document.head.appendChild(faviconIco);
      (async () => {
        try {        
          await axios.post(`${API_SERVICE_BACKEND_2}/unsubscribe/${uid}`);
          await axios.post(`${API_SERVICE_BACKEND_2}/optout/${uid}`);

        } catch (error) {
          console.error("Error making the unsubscribe request:", error);
        } finally {
          setTimeout(() => {
            navigate(-1);
          }, 2500);
      };

      })()
      return () => {       
        document.head.removeChild(faviconIco);
        document.head.appendChild(favIcon1)
        document.head.appendChild(favIcon2)
        document.head.appendChild(favIcon3)

  }  }, []);

  return (
    <div className={classes.root}>
      <Seo title="Unsubscribed" />
      <Container>
        <Paper elevation={0} className={classes.paper}>
          <Paper elevation={0} className={classes.innerPaper}>
            {/* <img
              src="https://assets.website-files.com/64a83d4353b353ae789d62da/64a842e615596fc6585c90db_b2b%20rocket%20logo%20blue%20cropped.png"
              alt=""
              className={classes.logo}
            /> */}
            <AiOutlineCheckCircle className={classes.checkIcon} />
            <Typography variant="h1" className={classes.title}>
              Successfully Unsubscribed
            </Typography>
            {/* <Typography variant="body1" className={classes.content}>
              Thank you for your interest! Check your email for a link to the
              guide.
            </Typography>
            <Button
              component={RouterLink}
              href={"/auth-main/login/v2"}
              variant="contained"
              className={classes.button}
              startIcon={<FaLongArrowAltLeft className={classes.buttonIcon} />}
            >
              Login
            </Button> */}
          </Paper>
        </Paper>
      </Container>
    </div>
  );
}

export default Unsubscribe;
