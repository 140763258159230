// export const option = [
//   "Etc/GMT+12 (GMT -12)",
//   "Etc/GMT+11 (GMT -11)",
//   "Pacific/Midway (GMT -11)",
//   "Pacific/Niue (GMT -11)",
//   "Pacific/Pago (GMT -11)",
//   "Pacific/Samoa (GMT -11)",
//   "US/Samoa (GMT -11)",
//   "Etc/GMT+10 (GMT -10)",
//   "HST (GMT -10)",
//   "Pacific/Honolulu (GMT -10)",
//   "Pacific/Johnston (GMT -10)",
//   "Pacific/Rarotonga (GMT -10)",
//   "Pacific/Tahiti (GMT -10)",
//   "US/Hawaii (GMT -10)",
//   "Pacific/Marquesas (GMT -9:30)",
//   "America/Adak (GMT -9)",
//   "America/Atka (GMT -9)",
//   "Etc/GMT+9 (GMT -9)",
//   "Pacific/Gambier (GMT -9)",
//   "US/Aleutian (GMT -9)",
//   "America/Anchorage (GMT -8)",
//   "America/Juneau (GMT -8)",
//   "America/Metlakatla (GMT -8)",
//   "America/Nome (GMT -8)",
//   "America/Sitka (GMT -8)",
//   "America/Yakutat (GMT -8)",
//   "Etc/GMT+8 (GMT -8)",
//   "Pacific/Pitcairn (GMT -8)",
//   "US/Alaska (GMT -8)",
//   "America/Creston (GMT -7)",
//   "America/Dawson (GMT -7)",
//   "America/DawsonCreek (GMT -7)",
//   "America/Ensenada (GMT -7)",
//   "America/FortNelson (GMT -7)",
//   "America/Hermosillo (GMT -7)",
//   "America/LosAngeles (GMT -7)",
//   "America/Phoenix (GMT -7)",
//   "America/SantaIsabel (GMT -7)",
//   "America/Tijuana (GMT -7)",
//   "America/Vancouver (GMT -7)",
//   "America/Whitehorse (GMT -7)",
//   "Canada/Pacific (GMT -7)",
//   "Canada/Yukon (GMT -7)",
//   "Etc/GMT+7 (GMT -7)",
//   "MST (GMT -7)",
//   "Mexico/BajaNorte (GMT -7)",
//   "PST8PDT (GMT -7)",
//   "US/Arizona (GMT -7)",
//   "US/Pacific (GMT -7)",
//   "America/Belize (GMT -6)",
//   "America/Boise (GMT -6)",
//   "America/CambridgeBay (GMT -6)",
//   "America/Chihuahua (GMT -6)",
//   "America/CostaRica (GMT -6)",
//   "America/Denver (GMT -6)",
//   "America/Edmonton (GMT -6)",
//   "America/ElSalvador (GMT -6)",
//   "America/Guatemala (GMT -6)",
//   "America/Inuvik (GMT -6)",
//   "America/Managua (GMT -6)",
//   "America/Mazatlan (GMT -6)",
//   "America/Ojinaga (GMT -6)",
//   "America/Regina (GMT -6)",
//   "America/Shiprock (GMT -6)",
//   "America/SwiftCurrent (GMT -6)",
//   "America/Tegucigalpa (GMT -6)",
//   "America/Yellowknife (GMT -6)",
//   "Canada/Mountain (GMT -6)",
//   "Canada/Saskatchewan (GMT -6)",
//   "Etc/GMT+6 (GMT -6)",
//   "MST7MDT (GMT -6)",
//   "Mexico/BajaSur (GMT -6)",
//   "Navajo (GMT -6)",
//   "Pacific/Galapagos (GMT -6)",
//   "US/Mountain (GMT -6)",
//   "America/Atikokan (GMT -5)",
//   "America/BahiaBanderas (GMT -5)",
//   "America/Bogota (GMT -5)",
//   "America/Cancun (GMT -5)",
//   "America/Cayman (GMT -5)",
//   "America/Chicago (GMT -5)",
//   "America/CoralHarbour (GMT -5)",
//   "America/Eirunepe (GMT -5)",
//   "America/Guayaquil (GMT -5)",
//   "America/Indiana/Knox (GMT -5)",
//   "America/Indiana/TellCity (GMT -5)",
//   "America/Jamaica (GMT -5)",
//   "America/KnoxIN (GMT -5)",
//   "America/Lima (GMT -5)",
//   "America/Matamoros (GMT -5)",
//   "America/Menominee (GMT -5)",
//   "America/Merida (GMT -5)",
//   "America/MexicoCity (GMT -5)",
//   "America/Monterrey (GMT -5)",
//   "America/NorthDakota/Beulah (GMT -5)",
//   "America/NorthDakota/Center (GMT -5)",
//   "America/NorthDakota/NewSalem (GMT -5)",
//   "America/Panama (GMT -5)",
//   "America/PortoAcre (GMT -5)",
//   "America/RainyRiver (GMT -5)",
//   "America/RankinInlet (GMT -5)",
//   "America/Resolute (GMT -5)",
//   "America/RioBranco (GMT -5)",
//   "America/Winnipeg (GMT -5)",
//   "Brazil/Acre (GMT -5)",
//   "CST6CDT (GMT -5)",
//   "Canada/Central (GMT -5)",
//   "Chile/EasterIsland (GMT -5)",
//   "EST (GMT -5)",
//   "Etc/GMT+5 (GMT -5)",
//   "Jamaica (GMT -5)",
//   "Mexico/General (GMT -5)",
//   "Pacific/Easter (GMT -5)",
//   "US/Central (GMT -5)",
//   "US/Indiana-Starke (GMT -5)",
//   "America/Anguilla (GMT -4)",
//   "America/Antigua (GMT -4)",
//   "America/Aruba (GMT -4)",
//   "America/Asuncion (GMT -4)",
//   "America/Barbados (GMT -4)",
//   "America/Blanc-Sablon (GMT -4)",
//   "America/BoaVista (GMT -4)",
//   "America/CampoGrande (GMT -4)",
//   "America/Caracas (GMT -4)",
//   "America/Cuiaba (GMT -4)",
//   "America/Curacao (GMT -4)",
//   "America/Detroit (GMT -4)",
//   "America/Dominica (GMT -4)",
//   "America/FortWayne (GMT -4)",
//   "America/GrandTurk (GMT -4)",
//   "America/Grenada (GMT -4)",
//   "America/Guadeloupe (GMT -4)",
//   "America/Guyana (GMT -4)",
//   "America/Havana (GMT -4)",
//   "America/Indiana/Indianapolis (GMT -4)",
//   "America/Indiana/Marengo (GMT -4)",
//   "America/Indiana/Petersburg (GMT -4)",
//   "America/Indiana/Vevay (GMT -4)",
//   "America/Indiana/Vincennes (GMT -4)",
//   "America/Indiana/Winamac (GMT -4)",
//   "America/Indianapolis (GMT -4)",
//   "America/Iqaluit (GMT -4)",
//   "America/Kentucky/Louisville (GMT -4)",
//   "America/Kentucky/Monticello (GMT -4)",
//   "America/Kralendijk (GMT -4)",
//   "America/LaPaz (GMT -4)",
//   "America/Louisville (GMT -4)",
//   "America/LowerPrinces (GMT -4)",
//   "America/Manaus (GMT -4)",
//   "America/Marigot (GMT -4)",
//   "America/Martinique (GMT -4)",
//   "America/Montreal (GMT -4)",
//   "America/Montserrat (GMT -4)",
//   "America/Nassau (GMT -4)",
//   "America/NewYork (GMT -4)",
//   "America/Nipigon (GMT -4)",
//   "America/Pangnirtung (GMT -4)",
//   "America/Port-au-Prince (GMT -4)",
//   "America/PortofSpain (GMT -4)",
//   "America/PortoVelho (GMT -4)",
//   "America/PuertoRico (GMT -4)",
//   "America/SantoDomingo (GMT -4)",
//   "America/StBarthelemy (GMT -4)",
//   "America/StKitts (GMT -4)",
//   "America/StLucia (GMT -4)",
//   "America/StThomas (GMT -4)",
//   "America/StVincent (GMT -4)",
//   "America/ThunderBay (GMT -4)",
//   "America/Toronto (GMT -4)",
//   "America/Tortola (GMT -4)",
//   "America/Virgin (GMT -4)",
//   "Brazil/West (GMT -4)",
//   "Canada/Eastern (GMT -4)",
//   "Cuba (GMT -4)",
//   "EST5EDT (GMT -4)",
//   "Etc/GMT+4 (GMT -4)",
//   "US/East-Indiana (GMT -4)",
//   "US/Eastern (GMT -4)",
//   "US/Michigan (GMT -4)",
//   "America/Araguaina (GMT -3)",
//   "America/Argentina/BuenosAires (GMT -3)",
//   "America/Argentina/Catamarca (GMT -3)",
//   "America/Argentina/ComodRivadavia (GMT -3)",
//   "America/Argentina/Cordoba (GMT -3)",
//   "America/Argentina/Jujuy (GMT -3)",
//   "America/Argentina/LaRioja (GMT -3)",
//   "America/Argentina/Mendoza (GMT -3)",
//   "America/Argentina/RioGallegos (GMT -3)",
//   "America/Argentina/Salta (GMT -3)",
//   "America/Argentina/SanJuan (GMT -3)",
//   "America/Argentina/SanLuis (GMT -3)",
//   "America/Argentina/Tucuman (GMT -3)",
//   "America/Argentina/Ushuaia (GMT -3)",
//   "America/Bahia (GMT -3)",
//   "America/Belem (GMT -3)",
//   "America/BuenosAires (GMT -3)",
//   "America/Catamarca (GMT -3)",
//   "America/Cayenne (GMT -3)",
//   "America/Cordoba (GMT -3)",
//   "America/Fortaleza (GMT -3)",
//   "America/GlaceBay (GMT -3)",
//   "America/GooseBay (GMT -3)",
//   "America/Halifax (GMT -3)",
//   "America/Jujuy (GMT -3)",
//   "America/Maceio (GMT -3)",
//   "America/Mendoza (GMT -3)",
//   "America/Moncton (GMT -3)",
//   "America/Montevideo (GMT -3)",
//   "America/Paramaribo (GMT -3)",
//   "America/PuntaArenas (GMT -3)",
//   "America/Recife (GMT -3)",
//   "America/Rosario (GMT -3)",
//   "America/Santarem (GMT -3)",
//   "America/Santiago (GMT -3)",
//   "America/SaoPaulo (GMT -3)",
//   "America/Thule (GMT -3)",
//   "Antarctica/Palmer (GMT -3)",
//   "Antarctica/Rothera (GMT -3)",
//   "Atlantic/Bermuda (GMT -3)",
//   "Atlantic/Stanley (GMT -3)",
//   "Brazil/East (GMT -3)",
//   "Canada/Atlantic (GMT -3)",
//   "Chile/Continental (GMT -3)",
//   "Etc/GMT+3 (GMT -3)",
//   "America/StJohns (GMT -2:30)",
//   "Canada/Newfoundland (GMT -2:30)",
//   "America/Godthab (GMT -2)",
//   "America/Miquelon (GMT -2)",
//   "America/Noronha (GMT -2)",
//   "Atlantic/SouthGeorgia (GMT -2)",
//   "Brazil/DeNoronha (GMT -2)",
//   "Etc/GMT+2 (GMT -2)",
//   "Atlantic/CapeVerde (GMT -1)",
//   "Etc/GMT+1 (GMT -1)",
//   "Africa/Abidjan (GMT +0)",
//   "Africa/Accra (GMT +0)",
//   "Africa/Bamako (GMT +0)",
//   "Africa/Banjul (GMT +0)",
//   "Africa/Bissau (GMT +0)",
//   "Africa/Conakry (GMT +0)",
//   "Africa/Dakar (GMT +0)",
//   "Africa/Freetown (GMT +0)",
//   "Africa/Lome (GMT +0)",
//   "Africa/Monrovia (GMT +0)",
//   "Africa/Nouakchott (GMT +0)",
//   "Africa/Ouagadougou (GMT +0)",
//   "Africa/Timbuktu (GMT +0)",
//   "America/Danmarkshavn (GMT +0)",
//   "America/Scoresbysund (GMT +0)",
//   "Atlantic/Azores (GMT +0)",
//   "Atlantic/Reykjavik (GMT +0)",
//   "Atlantic/StHelena (GMT +0)",
//   "Etc/GMT (GMT +0)",
//   "Etc/GMT+0 (GMT +0)",
//   "Etc/GMT-0 (GMT +0)",
//   "Etc/GMT0 (GMT +0)",
//   "Etc/Greenwich (GMT +0)",
//   "Etc/UCT (GMT +0)",
//   "Etc/UTC (GMT +0)",
//   "Etc/Universal (GMT +0)",
//   "Etc/Zulu (GMT +0)",
//   "GMT (GMT +0)",
//   "GMT+0 (GMT +0)",
//   "GMT-0 (GMT +0)",
//   "GMT0 (GMT +0)",
//   "Greenwich (GMT +0)",
//   "Iceland (GMT +0)",
//   "UCT (GMT +0)",
//   "UTC (GMT +0)",
//   "Universal (GMT +0)",
//   "Zulu (GMT +0)",
//   "Africa/Algiers (GMT +1)",
//   "Africa/Bangui (GMT +1)",
//   "Africa/Brazzaville (GMT +1)",
//   "Africa/Casablanca (GMT +1)",
//   "Africa/Douala (GMT +1)",
//   "Africa/ElAaiun (GMT +1)",
//   "Africa/Kinshasa (GMT +1)",
//   "Africa/Lagos (GMT +1)",
//   "Africa/Libreville (GMT +1)",
//   "Africa/Luanda (GMT +1)",
//   "Africa/Malabo (GMT +1)",
//   "Africa/Ndjamena (GMT +1)",
//   "Africa/Niamey (GMT +1)",
//   "Africa/Porto-Novo (GMT +1)",
//   "Africa/SaoTome (GMT +1)",
//   "Africa/Tunis (GMT +1)",
//   "Atlantic/Canary (GMT +1)",
//   "Atlantic/Faeroe (GMT +1)",
//   "Atlantic/Faroe (GMT +1)",
//   "Atlantic/Madeira (GMT +1)",
//   "Eire (GMT +1)",
//   "Etc/GMT-1 (GMT +1)",
//   "Europe/Belfast (GMT +1)",
//   "Europe/Dublin (GMT +1)",
//   "Europe/Guernsey (GMT +1)",
//   "Europe/IsleofMan (GMT +1)",
//   "Europe/Jersey (GMT +1)",
//   "Europe/Lisbon (GMT +1)",
//   "Europe/London (GMT +1)",
//   "GB (GMT +1)",
//   "GB-Eire (GMT +1)",
//   "Portugal (GMT +1)",
//   "WET (GMT +1)",
//   "Africa/Blantyre (GMT +2)",
//   "Africa/Bujumbura (GMT +2)",
//   "Africa/Cairo (GMT +2)",
//   "Africa/Ceuta (GMT +2)",
//   "Africa/Gaborone (GMT +2)",
//   "Africa/Harare (GMT +2)",
//   "Africa/Johannesburg (GMT +2)",
//   "Africa/Khartoum (GMT +2)",
//   "Africa/Kigali (GMT +2)",
//   "Africa/Lubumbashi (GMT +2)",
//   "Africa/Lusaka (GMT +2)",
//   "Africa/Maputo (GMT +2)",
//   "Africa/Maseru (GMT +2)",
//   "Africa/Mbabane (GMT +2)",
//   "Africa/Tripoli (GMT +2)",
//   "Africa/Windhoek (GMT +2)",
//   "Antarctica/Troll (GMT +2)",
//   "Arctic/Longyearbyen (GMT +2)",
//   "Atlantic/JanMayen (GMT +2)",
//   "CET (GMT +2)",
//   "Egypt (GMT +2)",
//   "Etc/GMT-2 (GMT +2)",
//   "Europe/Amsterdam (GMT +2)",
//   "Europe/Andorra (GMT +2)",
//   "Europe/Belgrade (GMT +2)",
//   "Europe/Berlin (GMT +2)",
//   "Europe/Bratislava (GMT +2)",
//   "Europe/Brussels (GMT +2)",
//   "Europe/Budapest (GMT +2)",
//   "Europe/Busingen (GMT +2)",
//   "Europe/Copenhagen (GMT +2)",
//   "Europe/Gibraltar (GMT +2)",
//   "Europe/Kaliningrad (GMT +2)",
//   "Europe/Ljubljana (GMT +2)",
//   "Europe/Luxembourg (GMT +2)",
//   "Europe/Madrid (GMT +2)",
//   "Europe/Malta (GMT +2)",
//   "Europe/Monaco (GMT +2)",
//   "Europe/Oslo (GMT +2)",
//   "Europe/Paris (GMT +2)",
//   "Europe/Podgorica (GMT +2)",
//   "Europe/Prague (GMT +2)",
//   "Europe/Rome (GMT +2)",
//   "Europe/SanMarino (GMT +2)",
//   "Europe/Sarajevo (GMT +2)",
//   "Europe/Skopje (GMT +2)",
//   "Europe/Stockholm (GMT +2)",
//   "Europe/Tirane (GMT +2)",
//   "Europe/Vaduz (GMT +2)",
//   "Europe/Vatican (GMT +2)",
//   "Europe/Vienna (GMT +2)",
//   "Europe/Warsaw (GMT +2)",
//   "Europe/Zagreb (GMT +2)",
//   "Europe/Zurich (GMT +2)",
//   "Libya (GMT +2)",
//   "MET (GMT +2)",
//   "Poland (GMT +2)",
//   "Africa/AddisAbaba (GMT +3)",
//   "Africa/Asmara (GMT +3)",
//   "Africa/Asmera (GMT +3)",
//   "Africa/DaresSalaam (GMT +3)",
//   "Africa/Djibouti (GMT +3)",
//   "Africa/Juba (GMT +3)",
//   "Africa/Kampala (GMT +3)",
//   "Africa/Mogadishu (GMT +3)",
//   "Africa/Nairobi (GMT +3)",
//   "Antarctica/Syowa (GMT +3)",
//   "Asia/Aden (GMT +3)",
//   "Asia/Amman (GMT +3)",
//   "Asia/Baghdad (GMT +3)",
//   "Asia/Bahrain (GMT +3)",
//   "Asia/Beirut (GMT +3)",
//   "Asia/Damascus (GMT +3)",
//   "Asia/Famagusta (GMT +3)",
//   "Asia/Gaza (GMT +3)",
//   "Asia/Hebron (GMT +3)",
//   "Asia/Istanbul (GMT +3)",
//   "Asia/Jerusalem (GMT +3)",
//   "Asia/Kuwait (GMT +3)",
//   "Asia/Nicosia (GMT +3)",
//   "Asia/Qatar (GMT +3)",
//   "Asia/Riyadh (GMT +3)",
//   "Asia/TelAviv (GMT +3)",
//   "EET (GMT +3)",
//   "Etc/GMT-3 (GMT +3)",
//   "Europe/Athens (GMT +3)",
//   "Europe/Bucharest (GMT +3)",
//   "Europe/Chisinau (GMT +3)",
//   "Europe/Helsinki (GMT +3)",
//   "Europe/Istanbul (GMT +3)",
//   "Europe/Kiev (GMT +3)",
//   "Europe/Kirov (GMT +3)",
//   "Europe/Mariehamn (GMT +3)",
//   "Europe/Minsk (GMT +3)",
//   "Europe/Moscow (GMT +3)",
//   "Europe/Nicosia (GMT +3)",
//   "Europe/Riga (GMT +3)",
//   "Europe/Simferopol (GMT +3)",
//   "Europe/Sofia (GMT +3)",
//   "Europe/Tallinn (GMT +3)",
//   "Europe/Tiraspol (GMT +3)",
//   "Europe/Uzhgorod (GMT +3)",
//   "Europe/Vilnius (GMT +3)",
//   "Europe/Volgograd (GMT +3)",
//   "Europe/Zaporozhye (GMT +3)",
//   "Indian/Antananarivo (GMT +3)",
//   "Indian/Comoro (GMT +3)",
//   "Indian/Mayotte (GMT +3)",
//   "Israel (GMT +3)",
//   "Turkey (GMT +3)",
//   "W-SU (GMT +3)",
//   "Asia/Baku (GMT +4)",
//   "Asia/Dubai (GMT +4)",
//   "Asia/Muscat (GMT +4)",
//   "Asia/Tbilisi (GMT +4)",
//   "Asia/Yerevan (GMT +4)",
//   "Etc/GMT-4 (GMT +4)",
//   "Europe/Astrakhan (GMT +4)",
//   "Europe/Samara (GMT +4)",
//   "Europe/Saratov (GMT +4)",
//   "Europe/Ulyanovsk (GMT +4)",
//   "Indian/Mahe (GMT +4)",
//   "Indian/Mauritius (GMT +4)",
//   "Indian/Reunion (GMT +4)",
//   "Asia/Kabul (GMT +4:30)",
//   "Asia/Tehran (GMT +4:30)",
//   "Iran (GMT +4:30)",
//   "Antarctica/Mawson (GMT +5)",
//   "Asia/Aqtau (GMT +5)",
//   "Asia/Aqtobe (GMT +5)",
//   "Asia/Ashgabat (GMT +5)",
//   "Asia/Ashkhabad (GMT +5)",
//   "Asia/Atyrau (GMT +5)",
//   "Asia/Dushanbe (GMT +5)",
//   "Asia/Karachi (GMT +5)",
//   "Asia/Oral (GMT +5)",
//   "Asia/Samarkand (GMT +5)",
//   "Asia/Tashkent (GMT +5)",
//   "Asia/Yekaterinburg (GMT +5)",
//   "Etc/GMT-5 (GMT +5)",
//   "Indian/Kerguelen (GMT +5)",
//   "Indian/Maldives (GMT +5)",
//   "Asia/Calcutta (GMT +5:30)",
//   "Asia/Colombo (GMT +5:30)",
//   "Asia/Kolkata (GMT +5:30)",
//   "Asia/Kathmandu (GMT +5:45)",
//   "Asia/Katmandu (GMT +5:45)",
//   "Antarctica/Vostok (GMT +6)",
//   "Asia/Almaty (GMT +6)",
//   "Asia/Bishkek (GMT +6)",
//   "Asia/Dacca (GMT +6)",
//   "Asia/Dhaka (GMT +6)",
//   "Asia/Kashgar (GMT +6)",
//   "Asia/Omsk (GMT +6)",
//   "Asia/Qyzylorda (GMT +6)",
//   "Asia/Thimbu (GMT +6)",
//   "Asia/Thimphu (GMT +6)",
//   "Asia/Urumqi (GMT +6)",
//   "Etc/GMT-6 (GMT +6)",
//   "Indian/Chagos (GMT +6)",
//   "Asia/Rangoon (GMT +6:30)",
//   "Asia/Yangon (GMT +6:30)",
//   "Indian/Cocos (GMT +6:30)",
//   "Antarctica/Davis (GMT +7)",
//   "Asia/Bangkok (GMT +7)",
//   "Asia/Barnaul (GMT +7)",
//   "Asia/HoChiMinh (GMT +7)",
//   "Asia/Hovd (GMT +7)",
//   "Asia/Jakarta (GMT +7)",
//   "Asia/Krasnoyarsk (GMT +7)",
//   "Asia/Novokuznetsk (GMT +7)",
//   "Asia/Novosibirsk (GMT +7)",
//   "Asia/PhnomPenh (GMT +7)",
//   "Asia/Pontianak (GMT +7)",
//   "Asia/Saigon (GMT +7)",
//   "Asia/Tomsk (GMT +7)",
//   "Asia/Vientiane (GMT +7)",
//   "Etc/GMT-7 (GMT +7)",
//   "Indian/Christmas (GMT +7)",
//   "Antarctica/Casey (GMT +8)",
//   "Asia/Brunei (GMT +8)",
//   "Asia/Choibalsan (GMT +8)",
//   "Asia/Chongqing (GMT +8)",
//   "Asia/Chungking (GMT +8)",
//   "Asia/Harbin (GMT +8)",
//   "Asia/HongKong (GMT +8)",
//   "Asia/Irkutsk (GMT +8)",
//   "Asia/KualaLumpur (GMT +8)",
//   "Asia/Kuching (GMT +8)",
//   "Asia/Macao (GMT +8)",
//   "Asia/Macau (GMT +8)",
//   "Asia/Makassar (GMT +8)",
//   "Asia/Manila (GMT +8)",
//   "Asia/Shanghai (GMT +8)",
//   "Asia/Singapore (GMT +8)",
//   "Asia/Taipei (GMT +8)",
//   "Asia/UjungPandang (GMT +8)",
//   "Asia/Ulaanbaatar (GMT +8)",
//   "Asia/UlanBator (GMT +8)",
//   "Australia/Perth (GMT +8)",
//   "Australia/West (GMT +8)",
//   "Etc/GMT-8 (GMT +8)",
//   "Hongkong (GMT +8)",
//   "PRC (GMT +8)",
//   "ROC (GMT +8)",
//   "Singapore (GMT +8)",
//   "Australia/Eucla (GMT +8:45)",
//   "Asia/Chita (GMT +9)",
//   "Asia/Dili (GMT +9)",
//   "Asia/Jayapura (GMT +9)",
//   "Asia/Khandyga (GMT +9)",
//   "Asia/Pyongyang (GMT +9)",
//   "Asia/Seoul (GMT +9)",
//   "Asia/Tokyo (GMT +9)",
//   "Asia/Yakutsk (GMT +9)",
//   "Etc/GMT-9 (GMT +9)",
//   "Japan (GMT +9)",
//   "Pacific/Palau (GMT +9)",
//   "ROK (GMT +9)",
//   "Australia/Adelaide (GMT +9:30)",
//   "Australia/BrokenHill (GMT +9:30)",
//   "Australia/Darwin (GMT +9:30)",
//   "Australia/North (GMT +9:30)",
//   "Australia/South (GMT +9:30)",
//   "Australia/Yancowinna (GMT +9:30)",
//   "Antarctica/DumontDUrville (GMT +10)",
//   "Asia/Ust-Nera (GMT +10)",
//   "Asia/Vladivostok (GMT +10)",
//   "Australia/ACT (GMT +10)",
//   "Australia/Brisbane (GMT +10)",
//   "Australia/Canberra (GMT +10)",
//   "Australia/Currie (GMT +10)",
//   "Australia/Hobart (GMT +10)",
//   "Australia/Lindeman (GMT +10)",
//   "Australia/Melbourne (GMT +10)",
//   "Australia/NSW (GMT +10)",
//   "Australia/Queensland (GMT +10)",
//   "Australia/Sydney (GMT +10)",
//   "Australia/Tasmania (GMT +10)",
//   "Australia/Victoria (GMT +10)",
//   "Etc/GMT-10 (GMT +10)",
//   "Pacific/Chuuk (GMT +10)",
//   "Pacific/Guam (GMT +10)",
//   "Pacific/PortMoresby (GMT +10)",
//   "Pacific/Saipan (GMT +10)",
//   "Pacific/Truk (GMT +10)",
//   "Pacific/Yap (GMT +10)",
//   "Australia/LHI (GMT +10:30)",
//   "Australia/LordHowe (GMT +10:30)",
//   "Antarctica/Macquarie (GMT +11)",
//   "Asia/Magadan (GMT +11)",
//   "Asia/Sakhalin (GMT +11)",
//   "Asia/Srednekolymsk (GMT +11)",
//   "Etc/GMT-11 (GMT +11)",
//   "Pacific/Bougainville (GMT +11)",
//   "Pacific/Efate (GMT +11)",
//   "Pacific/Guadalcanal (GMT +11)",
//   "Pacific/Kosrae (GMT +11)",
//   "Pacific/Norfolk (GMT +11)",
//   "Pacific/Noumea (GMT +11)",
//   "Pacific/Pohnpei (GMT +11)",
//   "Pacific/Ponape (GMT +11)",
//   "Antarctica/McMurdo (GMT +12)",
//   "Antarctica/SouthPole (GMT +12)",
//   "Asia/Anadyr (GMT +12)",
//   "Asia/Kamchatka (GMT +12)",
//   "Etc/GMT-12 (GMT +12)",
//   "Kwajalein (GMT +12)",
//   "NZ (GMT +12)",
//   "Pacific/Auckland (GMT +12)",
//   "Pacific/Fiji (GMT +12)",
//   "Pacific/Funafuti (GMT +12)",
//   "Pacific/Kwajalein (GMT +12)",
//   "Pacific/Majuro (GMT +12)",
//   "Pacific/Nauru (GMT +12)",
//   "Pacific/Tarawa (GMT +12)",
//   "Pacific/Wake (GMT +12)",
//   "Pacific/Wallis (GMT +12)",
//   "NZ-CHAT (GMT +12:45)",
//   "Pacific/Chatham (GMT +12:45)",
//   "Etc/GMT-13 (GMT +13)",
//   "Pacific/Apia (GMT +13)",
//   "Pacific/Enderbury (GMT +13)",
//   "Pacific/Fakaofo (GMT +13)",
//   "Pacific/Tongatapu (GMT +13)",
//   "Etc/GMT-14 (GMT +14)",
//   "Pacific/Kiritimati (GMT +14)",
// ];

export const option = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  " America/Knox_IN",
  " America/Kralendijk",
  " America/La_Paz",
  " America/Lima",
  " America/Los_Angeles",
  " America/Louisville",
  " America/Lower_Princes",
  " America/Maceio",
  " America/Managua",
  " America/Manaus",
  " America/Marigot",
  " America/Martinique",
  " America/Matamoros",
  " America/Mazatlan",
  " America/Mendoza",
  " America/Menominee",
  " America/Merida",
  " America/Metlakatla",
  " America/Mexico_City",
  " America/Miquelon",
  " America/Moncton",
  " America/Monterrey",
  " America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];
