import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { ADMIN_SERVER, API_SERVICE_BACKEND, selfURL } from "src/config";

const loginPaths = {
  [Issuer.Amplify]: paths.auth.amplify.login,
  [Issuer.Auth0]: paths.auth.auth0.login,
  [Issuer.Firebase]: paths.auth.firebase.login,
  [Issuer.JWT]: paths.auth.jwt.login,
};

export const AuthGuard = (props) => {
  const { children } = props;
  const router = useRouter();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { isAuthenticated, issuer, user } = useAuth();
  const [checked, setChecked] = useState(false);
  let freeSubscription = user?.dbRef?.freeSubscription;
  let paymentWall = user?.dbRef?.isPaymentWall || false;
  let userPlanObj =
    user?.dbRef?.userSubscription &&
    Object.keys(user.dbRef.userSubscription).length > 0;

  let userSubscriptionId =
    user?.dbRef?.userSubscription &&
    Object.keys(user.dbRef.userSubscription).length > 0 &&
    user?.dbRef?.userSubscription?.SubscriptionId;

  console.log(
    "userPlanObj====>",
    user,
    paymentWall,
    freeSubscription,
    userSubscriptionId
  );

  const isPlan = {
    userPlan: userPlanObj,
    freeSub: freeSubscription,
    SubscriptionId: userSubscriptionId == "free" ? true : false,
  };

  const check = useCallback(async () => {
    if (!isAuthenticated && !user) {
      return navigate("/auth-main/login/v2", { prev: pathname });
    }

    // const res = await axios.get(
    //   `${API_SERVICE_BACKEND}/getuserdetail/${user?.email}`
    // );

    const userData = user?.dbRef;

    if (paymentWall) {
      navigate("/auth-main/pricingPage", { prev: pathname });
    } else {
      if (isAuthenticated && !isPlan.userPlan && !isPlan.freeSub) {
        navigate("/auth-main/pricingPage", { prev: pathname });
      } else if (
        isAuthenticated &&
        (isPlan.userPlan || isPlan.freeSub) &&
        !userData?.signature
      ) {
        window.location.href = `${selfURL}/signaturePage/${encodeURIComponent(
          userData.email
        )}`;
      } else {
        if (!isPlan.freeSub && userSubscriptionId == "free") {
          navigate("/auth-main/pricingPage", { prev: pathname });
        } else {
          setChecked(true);
        }
      }
    }
  }, [isAuthenticated, issuer, router]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
