import React from 'react'
import DifferentOwnerCampaign from "./different-owner-campaign"

const DifferentOwners = ({ userCampaigns, hubspotUsers, props, openSnackbar }) => {

  return (
    userCampaigns?.map((campaign, index) => (
      <DifferentOwnerCampaign key={index} campaign={campaign} hubspotUsers={hubspotUsers} props={props} openSnackbar={openSnackbar} />
    ))
  )
}

export default DifferentOwners