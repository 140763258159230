export function setLocalStorage(key, value, stringify = false) {
  if (stringify) {
    window.localStorage.setItem(key, JSON.stringify(value));
  } else {
    window.localStorage.setItem(key, value);
  }
}

export function getLocalStorage(key, jsonDecode = false) {
  if (jsonDecode) {
    return JSON.parse(window.localStorage.getItem(key));
  } else {
    return window.localStorage.getItem(key);
  }
}

export function clearLocalStorage() {
  window.localStorage.clear();
}

export function deleteLocalStorage(key) {
  window.localStorage.removeItem(key);
}

export function setSessionStorage(key, value, stringify = false) {
  if (stringify) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    window.sessionStorage.setItem(key, value);
  }
}

export function getSessionStorage(key, jsonDecode = false) {
  if (jsonDecode) {
    return JSON.parse(window.sessionStorage.getItem(key));
  } else {
    return window.sessionStorage.getItem(key);
  }
}

export function clearSessionStorage() {
  window.sessionStorage.clear();
}

export function deleteSessionStorage(key) {
  window.sessionStorage.removeItem(key);
}
