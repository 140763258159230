import { BlobServiceClient } from "@azure/storage-blob";
import { storageConfigs } from "src/storageConfig";

export const uploadFileToAzure = async (file, name, storageType) => {
  try {
    const config = storageConfigs[storageType];

    if (!config) {
      throw new Error(`Invalid storage type: ${storageType}`);
    }

    const { storageAccountUrl, sasToken, containerName } = config;

    const blobServiceClient = new BlobServiceClient(
      `${storageAccountUrl}?${sasToken}`
    );
    const client = blobServiceClient.getContainerClient(containerName);
    const blobClient = client.getBlockBlobClient(name);

    await blobClient.uploadData(file);
    return blobClient.url;
  } catch (error) {
    console.error("Upload failed:", error);
    throw error;
  }
};
