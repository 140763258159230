import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LayerFilter from "./layer-filter";
import MultiSelect from "./multi-select";
import {
  CHANGED_JOBS_WITHIN,
  DEPARTMENT,
  INDUSTRY_OPTIONS,
  MANAGEMENT_LEVELS,
  NAICS_CODE,
  SIC_CODES,
  YearsOfExperience,
  degree,
  educationMajor,
  newIndustryTypes,
  FUNDING,
  DEPARTMENTSOPTIONS,
  MANAGEMENT_OPTIONS,
} from "src/data/campaign";
import MultiInput from "./multi-input";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AdditionalFilters = ({
  additionalFilters,
  sicCode,
  naicsCode,
  targets,
  industry,
  addFilters,
  experience,
}) => {
  let emplyCount;
  if (Array.isArray(addFilters?.compSize) && addFilters?.compSize?.length > 0) {
    let compSizeString = addFilters.compSize;
    let formattedCompSizes = [];
    compSizeString.forEach((compSizeString) => {
      let [lowerBound, upperBound] = compSizeString.split(",");
      let formattedCompSize = `${lowerBound}-${upperBound}`;
      formattedCompSizes.push(formattedCompSize);
    });
    emplyCount = formattedCompSizes;
  } else {
    emplyCount = addFilters?.compSize;
  }
  const mappedIndustries =
    industry?.length > 0
      ? newIndustryTypes
        .filter((ind) => industry.includes(ind.id))
        .map((ind) => ind.cleaned_name)
      : [];
  const mappedFunding =
    addFilters?.funding?.length > 0
      ? FUNDING.filter((fund) => addFilters?.funding.includes(fund.value)).map(
        (fund) => fund.title
      )
      : [];

  const mappedDepartments = additionalFilters?.departments?.length > 0 ?
    DEPARTMENTSOPTIONS.filter((dept) => additionalFilters?.departments?.includes(dept.key)).map(
      (dept) => dept.title
    ) : []

  const mappedManagements = additionalFilters?.managementLevels.length > 0 ?
    MANAGEMENT_OPTIONS.filter((manage) => additionalFilters?.managementLevels.includes(manage.key)).map(
      (manage) => manage.title
    ) : []

  return (
    <>
      {targets?.length > 0 ||
        additionalFilters?.departments?.length > 0 ||
        additionalFilters?.managementLevels?.length ||
        additionalFilters?.changedJob ||
        additionalFilters?.skills?.length ? (
        <Typography
          sx={{
            color: "#050C46",
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "15px",
          }}
        >
          Occupation
        </Typography>
      ) : (
        ""
      )}
      {targets?.length > 0 && (
        <>
          <Typography
            sx={{
              color: "#050C46",
              fontSize: "14px",
              fontWeight: "400",
              mt: "10px",
            }}
          >
            Titles/Roles
          </Typography>
          <TextField
            disabled
            name="titles"
            multiline
            fullWidth
            variant="outlined"
            value={targets?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      )}

      {additionalFilters !== undefined &&
        additionalFilters?.departments?.length > 0 && (
          <div id="select-department" style={{ marginTop: "0px" }}>
            {/* <div id="select-department" style={{ marginTop: "15px" }}> */}
            <Typography
              sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
            >
              Departments
            </Typography>
            <TextField
              disabled
              name="department"
              multiline
              fullWidth
              variant="outlined"
              value={mappedDepartments?.join(", ")}
              sx={{
                my: "10px",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#454861",
                },
              }}
            />
          </div>
        )}
      {additionalFilters !== undefined &&
        additionalFilters?.managementLevels?.length ? (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Management Levels
          </Typography>
          <TextField
            disabled
            name="management"
            multiline
            fullWidth
            variant="outlined"
            value={mappedManagements?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
      {additionalFilters !== undefined && additionalFilters?.changedJob && (
        <div id="select-job-change" style={{ marginTop: "0px" }}>
          {/* <div id="select-job-change" style={{ marginTop: "16px" }}> */}
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Changed Jobs Within
          </Typography>
          <TextField
            disabled
            multiline
            name="changedjob"
            fullWidth
            variant="outlined"
            value={additionalFilters?.changedJob}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </div>
      )}
      {additionalFilters !== undefined &&
        additionalFilters?.skills?.length > 0 ? (
        <div>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Skills
          </Typography>
          <TextField
            disabled
            multiline
            name="skills"
            fullWidth
            variant="outlined"
            value={additionalFilters?.skills?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </div>
      ) : (
        ""
      )}

      {experience && (
        <div id="select-years-of-experience" style={{ marginTop: "0px" }}>
          {/* <div id="select-years-of-experience" style={{ marginTop: "16px", }}> */}
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Year of experience
          </Typography>
          <TextField
            disabled
            multiline
            name="experience"
            fullWidth
            variant="outlined"
            value={experience}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </div>
      )}

      {additionalFilters?.companies?.length > 0 ||
        addFilters?.compSize?.length > 0 ||
        addFilters?.revenue?.length > 0 ||
        addFilters?.funding?.length > 0 ||
        industry?.length > 0 ||
        sicCode?.length > 0 ||
        naicsCode?.length ? (
        <Typography
          sx={{
            color: "#050C46",
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          Employer
        </Typography>
      ) : (
        ""
      )}
      {additionalFilters !== undefined &&
        additionalFilters?.companies?.length > 0 && (
          <div style={{ marginTop: "0px" }}>
            {/* <div style={{ marginTop: "16px" }}> */}
            <Typography
              sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
            >
              Comapny Name
            </Typography>
            <TextField
              disabled
              multiline
              name="companyName"
              fullWidth
              variant="outlined"
              value={additionalFilters?.companies?.join(", ")}
              sx={{
                my: "10px",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#454861",
                },
              }}
            />
          </div>
        )}

      {addFilters?.compSize?.length > 0 && (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Number of employees
          </Typography>
          <TextField
            disabled
            multiline
            name="employee"
            fullWidth
            variant="outlined"
            value={emplyCount}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      )}

      {addFilters?.revenue?.length > 0 && (
        <>
          <Typography
            sx={{
              color: "#050C46",
              fontSize: "14px",
              fontWeight: "400",
              // marginBottom: "10px",
              // marginTop: '10px'
              // mt:1
            }}
          >
            Revenue
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={1}
            sx={{ mt: 1 }}
          >
            <TextField
              variant="outlined"
              name="minrevenue"
              value={addFilters.revenue[0]}
              disabled
              placeholder="From"
              size="small"
              fullWidth
              inputProps={{
                min: 0,
                max: 1000000000,
                type: "number",
              }}
              sx={{
                "&.MuiTextField-root .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#454861",
                },
              }}
            />
            <TextField
              variant="outlined"
              name="maxrevenue"
              value={addFilters.revenue[1]}
              disabled
              placeholder="To"
              size="small"
              fullWidth
              inputProps={{
                min: 0,
                max: 1000000000,
                type: "number",
              }}
              sx={{
                "&.MuiTextField-root .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#454861",
                },
              }}
            />
          </Stack>
        </>
      )}

      {addFilters?.funding?.length > 0 && (
        <>
          <Typography
            sx={{
              color: "#050C46",
              fontSize: "14px",
              fontWeight: "400",
              // marginBottom: "10px",
              // marginTop: '10px'
              mt: 1,
            }}
          >
            Funding
          </Typography>
          <TextField
            disabled
            multiline
            name="funding"
            fullWidth
            variant="outlined"
            value={mappedFunding?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
          {/* <Stack direction="row" justifyContent="space-between" gap={1}>
          <TextField
            variant="outlined"
            name="minfunding"
            disabled
            fullWidth
            value={addFilters.funding[0]}
            placeholder="From"
            size="small"
            sx={{
              "&.MuiTextField-root .MuiOutlinedInput-root": {
                backgroundColor: "white",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              }
            }}
            inputProps={{
              min: 0,
              max: 1000000000,
              type: "number",
            }}
          />

          <TextField
            variant="outlined"
            value={addFilters.funding[1]}
            name="maxfunding"
            placeholder="To"
            disabled
            fullWidth
            size="small"
            sx={{
              "&.MuiTextField-root .MuiOutlinedInput-root": {
                backgroundColor: "white",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              }
            }}
            inputProps={{
              min: 0,
              max: 1000000000,
              type: "number",
            }}
          />
        </Stack> */}
        </>
      )}

      {industry?.length > 0 && (
        <>
          <Typography
            sx={{
              color: "#050C46",
              fontSize: "14px",
              fontWeight: "400",
              mt: "0px",
            }}
          >
            Industries
          </Typography>
          <TextField
            disabled
            multiline
            name="industrynames"
            fullWidth
            variant="outlined"
            value={mappedIndustries?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      )}

      {sicCode?.length > 0 ? (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            SIC Code
          </Typography>
          <TextField
            disabled
            multiline
            fullWidth
            name="siccodes"
            variant="outlined"
            value={sicCode?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
      {naicsCode?.length ? (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            NAICS Code
          </Typography>
          <TextField
            disabled
            multiline
            name="naiccodes"
            fullWidth
            variant="outlined"
            value={naicsCode?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      ) : (
        ""
      )}

      {additionalFilters?.majors?.length ||
        additionalFilters?.schools?.length ||
        additionalFilters?.degree?.length ? (
        <Typography
          sx={{
            color: "#050C46",
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "15px",
          }}
        >
          Education
        </Typography>
      ) : (
        ""
      )}
      {additionalFilters !== undefined &&
        additionalFilters?.majors?.length > 0 ? (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Major
          </Typography>
          <TextField
            disabled
            multiline
            name="major"
            fullWidth
            variant="outlined"
            value={additionalFilters?.majors?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
      {additionalFilters !== undefined &&
        additionalFilters?.schools?.length > 0 ? (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            School
          </Typography>
          <TextField
            disabled
            multiline
            name="school"
            fullWidth
            variant="outlined"
            value={additionalFilters?.schools?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
      {additionalFilters !== undefined &&
        additionalFilters?.degree?.length > 0 ? (
        <>
          <Typography
            sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}
          >
            Degree
          </Typography>
          <TextField
            disabled
            multiline
            fullWidth
            name="degree"
            variant="outlined"
            value={additionalFilters?.degree?.join(", ")}
            sx={{
              my: "10px",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#454861",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AdditionalFilters;
