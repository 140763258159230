export const CheckCircleIcon = ({ color = "white", size = "24" }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#00AA38" />
        <path
          d="M16 9L10.5 14.5L8 12"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
