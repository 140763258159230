import Section from "src/components/logos/Section.png";
import RegSection from "src/components/logos/regSection.png";
import ForgotSection from "src/components/logos/forgotSection.png";
import Step1 from "src/assets/step1.mp4";
import Step2 from "src/assets/step2.mp4";
import Step3 from "src/assets/step3.mp4";
import Step4 from "src/assets/step4.mp4";
import { useState } from "react";

export const RegistrationImage = ({ setRegImage, imageSource, currentUrl }) => {
  const [currentTndex, setcurrentIndex] = useState(1);
  // const images = [Section, RegSection, ForgotSection];
  const images = [Step1, Step2, Step3, Step4];

  const changeImage = (index) => {
    setcurrentIndex(index);
    const newImage = images[index];
    setRegImage(newImage);
  };

  const handleChangeImage = (index) => {
    if (index === 0) {
      setcurrentIndex(3);
      setRegImage(images[3]);
    } else {
      setcurrentIndex(index - 1);
      const newImage = images[index - 1];
      setRegImage(newImage);
    }
  };

  const handleImage = (index) => {
    if (index === 3) {
      setcurrentIndex(0);
      setRegImage(images[0]);
    } else {
      setcurrentIndex(index + 1);
      const newImage = images[index + 1];
      setRegImage(newImage);
    }
  };

  return (
    <div style={{ position: "relative", height: "100%", overflow: "hidden" }}>
      {currentUrl.includes("login-old") ? (
        <img
          src={imageSource}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        >
          {images.map((image, index) => (
            <video
              playsInline
              loop
              autoPlay
              mute
              controlsList="nodownload"
              src={image}
              style={{
                display: index === currentTndex ? "block" : "none",
                opacity: index === currentTndex ? 1 : 0,
                height: "100%",
                width: "100%",
                objectFit: "cover",
                transition: "all 0.5s",
              }}
            ></video>
          ))}
        </div>
      )}

     
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <button
            onClick={() => handleChangeImage(currentTndex)}
            style={{
              // position: "absolute",
              // zIndex: 1,
              // bottom: "230px",
              // left: "200px",
              background: "none",
              border: "none",
              color: "white",
            }}
          >
            &lt;
          </button>
          {images.map((image, index) => (
            <button
              key={index}
              style={{
                // position: "absolute",
                padding: "5px",
                backgroundColor: index === currentTndex ? "white" : "#A2A5CD",
                borderRadius: "16px",
                border: "none",
                // bottom: "230px",
                // left: `${280 + 40 * index}px`,
                // zIndex: 1,
              }}
              onClick={() => changeImage(index)}
            >
              <div
                style={{
                  width: "2px",
                  height: "2px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></div>
            </button>
          ))}
          <button
            onClick={() => handleImage(currentTndex)}
            style={{
              // position: "absolute",
              // zIndex: 1,
              // bottom: "230px",
              // right: "200px",
              background: "none",
              border: "none",
              color: "white",
            }}
          >
            &gt;
          </button>
        </div>
      
    </div>
  );
};
