import { Box } from "@mui/material";
import Section from "src/components/logos/Section.png";
import RegSection from "src/components/logos/regSection.png";
import ForgotSection from "src/components/logos/forgotSection.png";
import { useState } from "react";
import { RegistrationImage } from "../RegistrationImage";

export const ImageLayout = ({ currentUrl }) => {
  const [regImage, setRegImage] = useState(RegSection);

  let imageSource;
  switch (true) {
    case currentUrl.includes("login"):
      imageSource = Section;
      break;
    case currentUrl.includes("register"):
      imageSource = regImage;
      break;
    default:
      imageSource = ForgotSection;
      break;
  }

  return (
    <Box maxWidth="md" sx={{ height: "100%", overflow: "hidden" }}>
      <RegistrationImage
        setRegImage={setRegImage}
        imageSource={imageSource}
        currentUrl={currentUrl}
      />
    </Box>
  );
};
