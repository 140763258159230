import React, { useState } from "react";
import { Popover, Box, Button, Typography } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";

const timelineButtons = [
  {
    name: "Today",
    value: {
      // start: new Date().setHours(0, 0, 0, 0),
      // end: new Date().setHours(23, 59, 59, 999),
      start: Date.now(),
      end: Date.now(),
    },
  },
  {
    name: "Yesterday",
    value: {
      // start: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0),
      // end: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999),
      start: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      end: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    },
  },
  {
    name: "Past 7 days",
    value: {
      start: new Date().setDate(new Date().getDate() - 7),
      end: Date.now(),
    },
  },
  {
    name: "Month to date",
    value: { start: new Date().setDate(1), end: Date.now() },
  },
  {
    name: "Past 4 weeks",
    value: {
      start: new Date().setDate(new Date().getDate() - 28),
      end: Date.now(),
    },
  },
  {
    name: "Past 3 months",
    value: {
      start: new Date().setMonth(new Date().getMonth() - 3),
      end: Date.now(),
    },
  },
  {
    name: "Past 6 months",
    value: {
      start: new Date().setMonth(new Date().getMonth() - 6),
      end: Date.now(),
    },
  },
  {
    name: "Past 12 months",
    value: {
      start: new Date().setMonth(new Date().getMonth() - 12),
      end: Date.now(),
    },
  },
  {
    name: "Custom",
    value: {
      start: "",
      end: "",
    },
  },
];

const TimelineDropdown = ({
  setDateRange,
  height = "40px",
  handleCustomRange,
  displayAll,
  loader,
  selectedTimeline,
  setSelectedTimeline,
}) => {
  const emptyValue = {
    name: "Date Range",
    value: {
      start: "",
      end: "",
    },
  };
  const [timeline, setTimeline] = useState(
    displayAll
      ? selectedTimeline
        ? selectedTimeline
        : emptyValue
      : timelineButtons[2]
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const spanRef = React.useRef();

  React.useEffect(() => {
    if (selectedTimeline) {
      setTimeline(selectedTimeline);
    }
  }, [selectedTimeline]);

  return (
    <Box>
      <Button
        ref={spanRef}
        size="small"
        disabled={loader}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "16.38px",
          color: "#111927",
          backgroundColor: "#fff",
          px: 1.5,
          mr: 1.3,
          "&:hover": {
            backgroundColor: "#fff",
          },
          border: "1px solid #E4E4E5",
          height,
        }}
        onClick={handleClick}
        startIcon={<CalendarTodayRoundedIcon />}
      >
        {timeline?.name}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 1,
          }}
        >
          <ExpandMoreOutlined />
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 1.1,
            width: "200px",
          }}
        >
          {timelineButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#111927",
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor:
                    timeline?.name === item.name && "rgb(33, 111, 237, 0.1)",
                }}
                onClick={() => {
                  setTimeline(item);
                  if (setSelectedTimeline) {
                    setSelectedTimeline(item);
                  }
                  if (item.name === "Custom") {
                    handleCustomRange(spanRef);
                  } else {
                    setDateRange({
                      from: new Date(item.value.start),
                      to: new Date(item.value.end),
                    });
                  }
                  setAnchorEl(null);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {item.name}
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </Box>
  );
};

export default TimelineDropdown;
