import React, { useState } from "react";
import "../style.css";
import StepOneIcon from "src/components/logos/StepOneIcon.png";
import {
  Typography,
  Stack,
  Button,
  Divider,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Check from "src/components/logos/Check.png";
import CheckGrey from "src/components/logos/check-grey.png";

const StepItem = ({ stepTitle, stepText, stepContent, completed }) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Accordion
        expanded={expanded}
        sx={{
          "&.MuiAccordion-root": {
            backgroundColor: "transparent",
            boxShadow: "none",
            my: 0,
          },
          "&.MuiAccordion-root:before": { display: "none" },
        }}
      >
        <AccordionSummary
          sx={{
            px: 0,
            pointerEvents: "none",
            "&.MuiAccordionSummary-root .MuiAccordionSummary-content": {
              display: "flex",
              flexDirection: "column",
              gap: 3,
              m: 0,
            },
          }}
        >
          <Stack direction="row" spacing={1}>
            <img src={StepOneIcon} style={{ height: "fit-content" }} />
            <Typography
              variant="h6"
              sx={{ color: "#050C46", fontSize: "16px", fontWeight: "700" }}
            >
              {stepTitle}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
            <IconButton
              sx={{ p: 0, height: "fit-content", pointerEvents: "auto" }}
              onClick={handleClick}
              disabled={!completed}
            >
              <img src={completed ? Check : CheckGrey} height="20px" />
            </IconButton>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#050C46" }}
            >
              {stepText}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{stepContent}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default StepItem;
