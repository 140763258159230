import React from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
const GodaddyInstructions = (service) => {
  const portfolioLink =
    "https://sso.godaddy.com/?app=dcc&path=%2Fcontrol%2Fportfolio%3Fplid%3D";
  const generate = (service) => {
    switch (service) {
      case "google":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your Google Workspace Admin Console and navigate to
                the{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href="https://accounts.google.com/v3/signin/challenge/pwd?TL=ADg0xR1lW1yD3I2zkSUVBkah97wpUByNmaxob0SvQqsaRODDURqgpf84nmzFGDvo&cid=1&continue=https://admin.google.com/ac/apps/gmail/authenticateemail?pli%3D1&flowName=GlifWebSignIn&ifkv=ATuJsjzJUGQVN0wKP9zWFdlqCdc0q59Ex8qvM4853rOxZtk9tdGLu3Gf10BY_xvfomcmJ8hpvddkTA&osid=1&rart=ANgoxcfo0VEtTQlGCGZcPwrDATNLIEsL5_-AknkhVJbn5QgbqQs7e-TUjry_TXU5r5jIBcK21Zbqn3OUCsTCyfU_zGizHA6JUP7_RIf_3sQt_SMLm0Ro8Lk&rpbg=1&sarp=1&theme=mn&hl=en_US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Authentication Page
                </a>
                .
              </ListItemText>
            </ListItem>

            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select your domain from the drop-down list and click{" "}
                <b>Generate New Record</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                A page with DKIM information will be displayed. You will see a
                DKIM public key that needs to be added as a DNS TXT record. Copy
                the entire key.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to GoDaddy and navigate to your{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href={portfolioLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Domain portfolio
                </a>
                . Click on the domain you wish to configure, <b>then DNS</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Add New Record</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Under <b>Type</b>, select <b>"TXT"</b>.
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the Host field, enter a unique selector like "google" (or
                    any other label you prefer).
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the Value field, paste the DKIM public key you copied
                    from the Google Workspace Admin Console. It should look
                    something like this:
                  </ListItemText>
                  <ListItemText>
                    <em>v=DKIM1; k=rsa; p=YOUR_DKIM_PUBLIC_KEY</em>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Go back to the Google Workspace authentication page and click{" "}
                <b>Start Authentication</b>. Allow some time for the changes to
                propagate across the internet (usually up to 48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      case "microsoft":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your Microsoft 365 account and navigate to the{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=80ccca67-54bd-44ab-8625-4b79c4dc7775&response_type=code%20id_token&scope=openid%20profile&state=OpenIdConnect.AuthenticationProperties%3DDNhO6jdY2jMP55HBGfbFdUEcE7c4_RTwLXYA8GNJXRkj0u_oEW170RO5hrQi70WvmLyhv6l041oGIg5gZpadeDvl9scRdAJ3aT0a6Z4z7NjAfEf7Hsh1Kn_waoWK49xhUFILMTgpxAv2IyJvCzQAXA&response_mode=form_post&nonce=638452165416693035.Nzk2NWE1MGEtOGQxYS00OTAwLTg3NzAtM2YwZmQyMWU5ODBhNTI2NjE5MjctN2U2Yi00YjQ2LWE3MzctOWM2NjkzM2U0MGFk&client-request-id=813a883e-65f4-4cee-9810-c8e1f3bf5e9b&redirect_uri=https%3A%2F%2Fsecurity.microsoft.com%2F&x-client-SKU=ID_NET461&x-client-ver=6.22.1.0&sso_reload=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  DKIM page
                </a>
                .
              </ListItemText>
            </ListItem>

            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click on the domain you wish to configure and a popup will
                appear. Click <b>Create DKIM Keys</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Leave this window open so you can copy both CNAME records into
                your GoDaddy DNS.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to GoDaddy and navigate to your{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href={portfolioLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Domain portfolio
                </a>
                . Click on the domain you wish to configure, then <b>DNS</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Add New Record</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>Under Type, select “CNAME”.</ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Under <b>Name</b>, plug in the Host Name provided by
                    Microsoft 365 in step 3. It should look something like:
                    selector1._domainkey.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Under Value, plug in the CNAME record value provided by
                    Microsoft 365 in step 3. It should look something like:
                  </ListItemText>
                  <ListItemText>
                    <em style={{ overflowWrap: "break-word" }}>
                      selector1-yourdomain-com._domainkey.NETORGFT14914290.onmicrosoft.com
                    </em>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Repeat steps 5 & 6 for the second CNAME record provided by
                Microsoft 365 in step #3.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Return to the Microsoft 365 page and under “Sign messages for
                this domain with DKIM signatures”, switch to <b>Enable</b>.
              </ListItemText>
            </ListItem>
          </List>
        );
      case "other":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Consult your email provider's documentation for instructions on
                how to set up DKIM (usually available with a quick google
                search). Your email provider should provide a name and value for
                you to paste into your GoDaddy DNS record in step 4.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to GoDaddy and navigate to your{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href={portfolioLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Domain portfolio
                </a>
                . Click on the domain you wish to configure, then <b>DNS</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Add New Record</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Add either a CNAME or TXT record, based on the instructions from
                your email provider. Input the recommended name and value.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                If your email provider has a verification process, return to
                your email provider and complete that process. Allow some time
                for the changes to propagate across the internet (usually up to
                48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );

      default:
        return;
    }
  };
  return <>{generate(service)}</>;
};
const NamecheapInstructions = (service) => {
  const generate = (service) => {
    switch (service) {
      case "google":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your Google Workspace Admin Console and navigate to
                the{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href="https://accounts.google.com/v3/signin/challenge/pwd?TL=ADg0xR1lW1yD3I2zkSUVBkah97wpUByNmaxob0SvQqsaRODDURqgpf84nmzFGDvo&cid=1&continue=https://admin.google.com/ac/apps/gmail/authenticateemail?pli%3D1&flowName=GlifWebSignIn&ifkv=ATuJsjzJUGQVN0wKP9zWFdlqCdc0q59Ex8qvM4853rOxZtk9tdGLu3Gf10BY_xvfomcmJ8hpvddkTA&osid=1&rart=ANgoxcfo0VEtTQlGCGZcPwrDATNLIEsL5_-AknkhVJbn5QgbqQs7e-TUjry_TXU5r5jIBcK21Zbqn3OUCsTCyfU_zGizHA6JUP7_RIf_3sQt_SMLm0Ro8Lk&rpbg=1&sarp=1&theme=mn&hl=en_US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Authentication Page
                </a>
                .
              </ListItemText>
            </ListItem>

            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select your domain from the drop-down list and click{" "}
                <b>Generate New Record</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                A page with DKIM information will be displayed. You will see a
                DKIM public key that needs to be added as a DNS TXT record. Copy
                the entire key.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to NameCheap and click <b>Domain List</b>, then Manage
                next to the domain you'd like to manage.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Advanced DNS</b>, then the red <b>Add New Record</b>{" "}
                button.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select <b>TXT Record</b>
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the Host field, enter a unique selector like "google" (or
                    any other label you prefer).
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the Value field, paste the DKIM public key you copied
                    from the Google Workspace Admin Console. It should look
                    something like this:
                  </ListItemText>
                  <ListItemText>
                    <em>v=DKIM1; k=rsa; p=YOUR_DKIM_PUBLIC_KEY</em>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Go back to the Google Workspace authentication page and click{" "}
                <b>Start Authentication</b>. Allow some time for the changes to
                propagate across the internet (usually up to 48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      case "microsoft":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your Microsoft 365 account and navigate to the{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=80ccca67-54bd-44ab-8625-4b79c4dc7775&response_type=code%20id_token&scope=openid%20profile&state=OpenIdConnect.AuthenticationProperties%3DDNhO6jdY2jMP55HBGfbFdUEcE7c4_RTwLXYA8GNJXRkj0u_oEW170RO5hrQi70WvmLyhv6l041oGIg5gZpadeDvl9scRdAJ3aT0a6Z4z7NjAfEf7Hsh1Kn_waoWK49xhUFILMTgpxAv2IyJvCzQAXA&response_mode=form_post&nonce=638452165416693035.Nzk2NWE1MGEtOGQxYS00OTAwLTg3NzAtM2YwZmQyMWU5ODBhNTI2NjE5MjctN2U2Yi00YjQ2LWE3MzctOWM2NjkzM2U0MGFk&client-request-id=813a883e-65f4-4cee-9810-c8e1f3bf5e9b&redirect_uri=https%3A%2F%2Fsecurity.microsoft.com%2F&x-client-SKU=ID_NET461&x-client-ver=6.22.1.0&sso_reload=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  DKIM page
                </a>
                .
              </ListItemText>
            </ListItem>

            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click on the domain you wish to configure and a popup will
                appear. Click <b>Create DKIM Keys</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Leave this window open so you can copy both CNAME records into
                your NameCheap DNS.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to NameCheap and click <b>Domain List</b>, then Manage
                next to the domain you'd like to manage.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Advanced DNS</b>, then the red <b>Add New Record</b>{" "}
                button.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select <b>CNAME Record</b>
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Under Host, plug in the Host Name provided by Microsoft 365
                    in step 3. It should look something like:
                    selector1._domainkey.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Under Value, plug in the CNAME record value provided by
                    Microsoft 365 in step 3. It should look something like:
                  </ListItemText>
                  <ListItemText>
                    <em style={{ overflowWrap: "break-word" }}>
                      selector1-yourdomain-com._domainkey.NETORGFT14914290.onmicrosoft.com
                    </em>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Repeat steps 5 & 6 for the second CNAME record provided by
                Microsoft 365 in step #3.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Return to the Microsoft 365 page and under “Sign messages for
                this domain with DKIM signatures”, switch to <b>Enable</b>.
              </ListItemText>
            </ListItem>
          </List>
        );
      case "other":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Consult your email provider's documentation for instructions on
                how to set up DKIM (usually available with a quick google
                search). Your email provider should provide a name and value for
                you to paste into your Namecheap DNS record in step 4.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to NameCheap and click <b>Domain List</b>, then Manage
                next to the domain you'd like to manage.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Advanced DNS</b>, then the red <b>Add New Record</b>{" "}
                button.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Add either a CNAME or TXT record, based on the instructions from
                your email provider. Input the recommended name and value.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                If your email provider has a verification process, return to
                your email provider and complete that process. Allow some time
                for the changes to propagate across the internet (usually up to
                48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      default:
        return;
    }
  };
  return <>{generate(service)}</>;
};
const OthersInstructions = (service) => {
  const generate = (service) => {
    switch (service) {
      case "google":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your Google Workspace Admin Console and navigate to
                the{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href="https://accounts.google.com/v3/signin/challenge/pwd?TL=ADg0xR1lW1yD3I2zkSUVBkah97wpUByNmaxob0SvQqsaRODDURqgpf84nmzFGDvo&cid=1&continue=https://admin.google.com/ac/apps/gmail/authenticateemail?pli%3D1&flowName=GlifWebSignIn&ifkv=ATuJsjzJUGQVN0wKP9zWFdlqCdc0q59Ex8qvM4853rOxZtk9tdGLu3Gf10BY_xvfomcmJ8hpvddkTA&osid=1&rart=ANgoxcfo0VEtTQlGCGZcPwrDATNLIEsL5_-AknkhVJbn5QgbqQs7e-TUjry_TXU5r5jIBcK21Zbqn3OUCsTCyfU_zGizHA6JUP7_RIf_3sQt_SMLm0Ro8Lk&rpbg=1&sarp=1&theme=mn&hl=en_US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Authentication Page
                </a>
                .
              </ListItemText>
            </ListItem>

            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Select your domain from the drop-down list and click{" "}
                <b>Generate New Record</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                A page with DKIM information will be displayed. You will see a
                DKIM public key that needs to be added as a DNS TXT record. Copy
                the entire key.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your domain host and navigate to your domain's DNS
                settings.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Look for the <b>Add New Record</b> button and click on it.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Under <b>Type</b>, select <b>"TXT"</b>.
              </ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the Host field, enter a unique selector like "google" (or
                    any other label you prefer).
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    In the Value field, paste the DKIM public key you copied
                    from the Google Workspace Admin Console. It should look
                    something like this:
                  </ListItemText>
                  <ListItemText>
                    <em>v=DKIM1; k=rsa; p=YOUR_DKIM_PUBLIC_KEY</em>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Go back to the Google Workspace authentication page and click{" "}
                <b>Start Authentication</b>. Allow some time for the changes to
                propagate across the internet (usually up to 48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      case "microsoft":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your Microsoft 365 account and navigate to the{" "}
                <a
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#5761FE",
                  }}
                  href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=80ccca67-54bd-44ab-8625-4b79c4dc7775&response_type=code%20id_token&scope=openid%20profile&state=OpenIdConnect.AuthenticationProperties%3DDNhO6jdY2jMP55HBGfbFdUEcE7c4_RTwLXYA8GNJXRkj0u_oEW170RO5hrQi70WvmLyhv6l041oGIg5gZpadeDvl9scRdAJ3aT0a6Z4z7NjAfEf7Hsh1Kn_waoWK49xhUFILMTgpxAv2IyJvCzQAXA&response_mode=form_post&nonce=638452165416693035.Nzk2NWE1MGEtOGQxYS00OTAwLTg3NzAtM2YwZmQyMWU5ODBhNTI2NjE5MjctN2U2Yi00YjQ2LWE3MzctOWM2NjkzM2U0MGFk&client-request-id=813a883e-65f4-4cee-9810-c8e1f3bf5e9b&redirect_uri=https%3A%2F%2Fsecurity.microsoft.com%2F&x-client-SKU=ID_NET461&x-client-ver=6.22.1.0&sso_reload=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  DKIM page
                </a>
                .
              </ListItemText>
            </ListItem>

            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click on the domain you wish to configure and a popup will
                appear. Click <b>Create DKIM Keys</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Leave this window open so you can copy both CNAME records into
                your domain host's DNS.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your domain host and navigate to your domain's DNS
                settings.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Look for the <b>Add New Record</b> button and click on it.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>Select CNAME Record</ListItemText>
              <List
                sx={{
                  listStyle: "decimal",
                  pl: 4,
                  "& .MuiListItem-root": { p: 0 },
                  "& .MuiListItemText-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#050C46",
                  },
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Under Host, plug in the Host Name provided by Microsoft 365
                    in step 3. It should look something like:
                    selector1._domainkey.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Under Value, plug in the CNAME record value provided by
                    Microsoft 365 in step 3. It should look something like:
                  </ListItemText>
                  <ListItemText>
                    <em style={{ overflowWrap: "break-word" }}>
                      selector1-yourdomain-com._domainkey.NETORGFT14914290.onmicrosoft.com
                    </em>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Repeat steps 5 & 6 for the second CNAME record provided by
                Microsoft 365 in step #3.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Return to the Microsoft 365 page and under “Sign messages for
                this domain with DKIM signatures”, switch to <b>Enable</b>.
              </ListItemText>
            </ListItem>
          </List>
        );
      case "other":
        return (
          <List
            sx={{
              height: { xs: "calc(100% - 100px)", sm: "auto" },
              overflow: "auto",
              listStyle: "decimal",
              pl: 4,
              "& .MuiListItem-root": { p: 0, fontSize: "14px" },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontWeight: "400",
                fontSize: "14px",
                color: "#050C46",
              },
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Consult your email provider's documentation for instructions on
                how to set up DKIM (usually available with a quick google
                search). Your email provider should provide a name and value for
                you to paste into your domain host's DNS record in step 4.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Log in to your domain host and navigate to your domain's DNS
                settings.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Look for the <b>Add New Record</b> button and click on it.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Add either a CNAME or TXT record, based on the instructions from
                your email provider. Input the recommended name and value.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Click <b>Save Changes</b>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                If your email provider has a verification process, return to
                your email provider and complete that process. Allow some time
                for the changes to propagate across the internet (usually up to
                48 hours).
              </ListItemText>
            </ListItem>
          </List>
        );
      default:
        return;
    }
  };
  return <>{generate(service)}</>;
};
const Dkim = ({ domain, service }) => {
  const getInstructions = (domain, service) => {
    switch (domain) {
      case "godaddy":
        return GodaddyInstructions(service);

      case "namecheap":
        return NamecheapInstructions(service);
      case "other":
        return OthersInstructions(service);
      default:
        return;
    }
  };
  return (
    <Box sx={{ height: { xs: "100%", sm: "auto" } }}>
      {getInstructions(domain, service)}
    </Box>
  );
};

export default Dkim;
