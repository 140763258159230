import { textFromHTML } from "src/utils/parser";
import { FILTERS, INDUSTRY_OPTIONS } from "./campaign";

export const prompts = {
  report: {
    recommendation: ({ campaign }) => {
      // console.log(textFromHTML(campaign.campaignDesc));

      return `We operate as a marketing firm specializing in executing email marketing campaigns for our clients. I will provide you with a template encompassing all conceivable choices, along with the responses provided by the client in relation to our Ideal Customer Profile (ICP) form. Your task involves scrutinizing this data and formulating suggestions for each section of options. These suggestions should encompass either endorsing the selections or proposing alternatives, with the intention of guiding clients towards potentially overlooked options or advising against unsuitable ones. The resultant insights will serve the purpose of refining a contact database. Your recommendations and proposed options should be tailored to suit those who could derive advantages from the described services. It's crucial to factor in the choices already made by the client. Your output should exclusively comprise HTML-formatted text with appropriate CSS-styling appliied to it and enclosed within a 'div' HTML tag, containing the generated recommendations. Ensure the generated content refrains from duplicating client choices, introducing supplementary guiding text, quotes, titles, or headings, adhering solely to the stipulated task.


        TEMPLATE/CONCEIVABLE CHOICES FOR EACH SECTION

        Company Size Options: ${FILTERS.find(({ id }) => id === "compSize")
          ?.options?.map((range) => range.join("-"))
          .join(",")}
        
        Location Options: {{any countries, locations, regions or counties}}
        
        Title Options: {{user can include any titles they want}}
        
        Industry Options: ${INDUSTRY_OPTIONS.join(",")}

        Company Description: {{user can fill this out however they want}}
        
      

        CLIENT RESPONSE/SELECTIONS

        Company Size Options: ${campaign.addFilters?.compSize
          ?.map((range) => range.split(",").join("-"))
          .join(", ")}
        
        Location Options: ${campaign.addFilters?.location?.join(",")}
        
        Title Options: ${campaign.targets.join(",")}
        
        Industry Options: ${campaign.industry?.join(",")}
        
        Company Description: ${textFromHTML(campaign.campaignDesc)}
        
        
        ${
          /*`Here's an example of the expected format for the results:
        
        Company Size
        {{AI generated recommendations for 'Company Size'}}

        Location
        {{AI generated recommendations for 'Location'}}

        Title
        {{AI generated recommendations for 'Title'}}

        Industry
        {{AI generated recommendations for 'Industry'}}

        Company Description
        {{AI generated recommendations for 'Company Description'}}`*/ " "
        }`;
    },
  },
};
