import { format, subDays } from "date-fns";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Chart } from "src/components/chart";
import "./style.css";

const now = new Date();

const createCategories = () => {
  let categories = [];

  for (let i = 30; i >= 0; i--) {
    categories.push(format(subDays(now, i), "dd MMM"));
  }
  // console.log(categories);
  return categories;
};

const useChartOptions = ({ customCategories }) => {
  const theme = useTheme();
  const categories = customCategories ? customCategories : createCategories();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#5761FE", "#FF5C1F", "#00AA38"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      horizontalAlign: "right",
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: "top",
      show: true,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: "circle",
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: "smooth",
      // dashArray: [0, 3],
      lineCap: "butt",
      width: 3,
    },
    tooltip: {
      theme: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    ],
  };
};

export const EcommerceSalesRevenue = (props) => {
  const { chartSeries, categories, title } = props;
  const chartOptions = useChartOptions({ customCategories: categories });

  // console.log("Chart Options: ", chartOptions);
  // console.log("Chart Series: ", chartSeries);
  // console.log("Categories: ", categories);
  // console.log("createCategories: ", createCategories());
  // console.log(
  //   "chartOptions.xaxis?.categories?.length ",
  //   chartOptions.xaxis?.categories?.length
  // );

  return (
    <Card>
      <CardHeader title={title || ""} />
      <CardContent sx={{ pt: 0 }}>
        {categories?.length > 0 ? (
          <Chart
            height={375}
            options={chartOptions}
            series={chartSeries}
            type="line"
          />
        ) : (
          <h1 style={{ textAlign: "center" }}>No Chart data found</h1>
        )}
      </CardContent>
    </Card>
  );
};
