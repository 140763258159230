import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import CouponBox from "./CouponBox"
function AlertBox({ disBtn, agreeFunction, priceid, setDisBtn, setAlertModal,conponError,loading }) {
  const [open, setOpen] = React.useState(disBtn);
  const [alertModal, setAlerModal] = React.useState(false)
  const handleClose = () => {
    setOpen(false);
    setDisBtn(false)
    setAlertModal(false)
  };
  const handleAgry = () => {
    setAlerModal(true)
    // agreeFunction(priceid)
    setOpen(false);
  }

  return (
    <React.Fragment>
            {alertModal && (
              <CouponBox
                  disBtn={disBtn}
                  agreeFunction={agreeFunction}
                  priceid={priceid}
                  setDisBtn={setDisBtn}
                  setAlertModal={setAlertModal}
                  conponError={conponError}
                  loading={loading}
              />
          )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{fontSize:'14px'}}>
            Changes made will be immediately reflected in your account. Are you sure you want to change your plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{fontSize:'14px'}}>Disagree</Button>
          <Button onClick={handleAgry} autoFocus sx={{fontSize:'14px'}}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default AlertBox