import { createSlice } from "@reduxjs/toolkit";
import { objFromArray } from "src/utils/obj-from-array";

const initialState = {
  contacts: {
    byId: {},
    allIds: [],
  },
  currentThreadId: undefined,
  threads: {
    byId: {},
    allIds: [],
  },
};

const reducers = {
  getContacts(state, action) {
    const contacts = action.payload;

    state.contacts.byId = objFromArray(contacts);
    state.contacts.allIds = Object.keys(state.contacts.byId);
  },
  getThreads(state, action) {
    const threads = action.payload;

    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
  },
  getThread(state, action) {
    const { threadKey, chat, unread } = action.payload;
    const thread = {
      id: threadKey,
      messages: chat,
      unread,
      ...state.threads.byId[threadKey],
    };

    if (thread) {
      state.threads.byId[thread.id] = thread;

      if (!state.threads.allIds.includes(thread.id)) {
        state.threads.allIds.unshift(thread.id);
      }
    }
  },
  markThreadAsSeen(state, action) {
    const { threadId, userEmail } = action.payload;
    const thread = state.threads.byId[threadId];
    if (thread) {
      thread.unread[userEmail] = 0;
    }
  },
  setCurrentThread(state, action) {
    state.currentThreadId = action.payload;
  },
  recieveMessage(state, action) {
    const { message, email } = action.payload;
    let temp = {};
    Object.values(state.threads.byId).filter((obj) => {
      if (obj.email === message.from) temp = { ...obj };
      return obj.email === message.from;
    });

    // if(state.currentThreadId === temp.id){
    // }

    if (temp) {
      const ref = state.threads.byId[temp.id];

      if (ref.unread) {
        if (ref.unread[email.split(".")[0]]) {
          console.log("inc 1");
          ref.unread[email.split(".")[0]] += 1;
        } else {
          console.log("set 1");
          ref.unread[email.split(".")[0]] = 1;
        }
      } else {
        ref.unread = { [email.split(".")[0]]: 1 };
      }

      ref.messages?.push(message);
    }
  },
  addMessage(state, action) {
    const { threadId, message } = action.payload;
    const thread = state.threads.byId[threadId];
    if (thread) {
      thread.messages.push(message);
    }
  },
};

export const slice = createSlice({
  name: "chat",
  initialState,
  reducers,
});

export const { reducer } = slice;
