import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fullName: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveFullName(state, action) {
      state.fullName = action.payload;
    }
  }
});

export const { reducer, actions } = userSlice;
export const { saveFullName } = actions;
