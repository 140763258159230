import { withStyles, createStyles } from "@material-ui/core/styles";
import { TableCell } from "@mui/material";

export async function copyToClipboard(text) {
  return await navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard");
      return true;
    })
    .catch((error) => {
      console.error("Error copying text to clipboard:", error);
      return false;
    });
}

export const compObj = (obj1, obj2) => {
  // console.log(obj1);
  // console.log(obj2);
  const _1type = typeof obj1;
  const _2type = typeof obj2;
  // console.log(_1type, _2type);
  if (_1type !== _2type) return false;
  if (_1type === "object" && !Array.isArray(obj1)) {
    const ok = Object.keys;
    let res = true;
    ok(obj1).forEach((key) => {
      res &&= compObj(obj1[key], obj2[key]);
    });
    return res;
  }
  if (obj1?.[Symbol.iterator] && typeof obj1 !== "string") {
    const len = obj1.length;
    if (len !== obj2.length) return false;
    for (let i = 0; i < len; i += 1) {
      if (obj2.findIndex((val) => val === obj1[i]) === -1) return false;
    }
    return true;
  }
  return obj1 === obj2;
};

export const CTableCell = withStyles((theme) =>
  createStyles({
    root: {
      borderRight: "2px solid #f1f4f7",
    },
  })
)(TableCell);
