import React, { useEffect } from "react"
import moment from "moment/moment";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import { useNavigate } from "react-router";
const Demousers=()=>{   
const navigate=useNavigate();
    function checkTimeDifference(isoString) {
        const providedTime = moment(isoString).utc();
        const currentTime = moment();
        const differenceInMinutes = currentTime.diff(providedTime, 'minutes');
        if (differenceInMinutes >= 60) {
            navigate('/auth-main/pricingPage')
        } else {
            const time=60-differenceInMinutes
           setTimeout(() => {
            localStorage.removeItem("demoUserTime");
            navigate('/auth-main/pricingPage')
        },1000 * 60 * time);
        }
    }


    const checkIpAddress=async()=>{
        try {
          const result = await axios.get(
            "https://ipapi.co/json",
          )
          const res = await axios.get(
            `${API_SERVICE_BACKEND}/checkDemoUserExpireToken/${result?.data?.ip}`,
          )
          const {userExpireTime,isRedirect}=res?.data?.data
          console.log(res?.data?.data,"dgsdsgdgssgd")
          if(isRedirect){
            localStorage.removeItem("demoUserTime");
            navigate('/auth-main/pricingPage')
          }
          else{
            localStorage.setItem("demoUserTime",userExpireTime)
            setTimeout(() => {
                localStorage.removeItem("demoUserTime");
                navigate('/auth-main/pricingPage')
            },1000 * 60 * 60);
          }
        } catch (error) {
          console.log(error,"error on check IpAddress")
        }
      }


    useEffect(() => {
        const userTime=localStorage.getItem("demoUserTime");
        if(!userTime){
            checkIpAddress();
        }
        else{
            checkTimeDifference(userTime);
        }
    }, [])
    

  
    
 
    return(
        <div style={{display:'flex', flexGrow:1}}>
            <iframe src="https://sandbox.b2brocket.ai/dashboard/campaigns" width="100%"  title="demo users" style={{border:"none"}}></iframe>
        </div>
    )
}

export default Demousers