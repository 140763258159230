import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { HorizontalTabs } from "./horizontal-tabs";
import { useSections } from "../config";

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled("div")(({ theme, open }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  // backgroundColor:'#ECE9F5',
  padding: "8px",
  [theme.breakpoints.up("lg")]: {
    //paddingLeft: SIDE_NAV_WIDTH + 16,
    paddingLeft: open
      ? SIDE_NAV_WIDTH + 16
      : `calc(${theme.spacing(7)} + 17px)`,
  },
}));

const VerticalLayoutContainer = styled("div")({
  position: "relative",
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "rgba(248, 248, 249, 1)",
  border: "1px solid rgba(224, 224, 229, 1)",
  padding: "16px 24px",
  borderRadius: "16px",
});

function debounce(func, wait) {
  let timeout;

  return function (...args) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

export const VerticalLayout = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const subSections = useSections(true);
  const [open, setOpen] = useState(true);
  const [flagSidebar, setFlagSidebar] = useState(true);

  useEffect(() => {
    debounce(() => {}, 1000);
    setOpen(flagSidebar);
  }, [flagSidebar]);
  const handleDrawerToggle = debounce((e, flag = null) => {
    if (flag === null) {
      setFlagSidebar(!flagSidebar);
    } else if (!flagSidebar && (flag === true || flag === false)) {
      console.log({ flag });
      setOpen(flag);
    }
  }, 100);

  return (
    <>
      {!lgUp && <TopNav onMobileNavOpen={mobileNav.handleOpen} />}
      {/* {lgUp && <SideNav color={navColor} sections={sections} />} */}
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          open={open}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot open={open}>
        <VerticalLayoutContainer>
          <HorizontalTabs sections={subSections} />
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
