import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";

class MailBoxesApi {
  assignMailBoxes = async ({ email, campaignId, limit }) => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/assignMailBoxes`,
        {
          params: {
            email,
            campaignId,
            limit,
          },
        }
      );
      const { data, error } = res.data;
      if (error) {
        throw new Error(error);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  getMailBoxes = async ({ filter, projection, limit=10 }) => {
    try {
      const res = await axios.post(`${API_SERVICE_BACKEND}/mailboxes/read`, {
        payload: {
          filter,
          projection,
          limit,
          // limit: mailBoxes.countPerCampaign,
        },
      });
      const { data, error } = res.data;
      if (error) {
        throw new Error(error);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  updateMailBoxes = async ({ filter, update }) => {
    try {
      const res = await axios.patch(`${API_SERVICE_BACKEND}/mailboxes`, {
        payload: {
          filter,
          update,
        },
      });
      const { data, error } = res.data;
      if (error) {
        throw new Error(error);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };
}

export const mailBoxesApi = new MailBoxesApi();
