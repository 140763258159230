import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { API_SERVICE_BACKEND } from "src/config";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getSessionStorage } from "src/utils/storage";

function CampaignList({
  campCount,
  campList,
  setCampCount,
  priceid,
  selectCampUpdate,
  setDisBtn,
  campaignData,
  count,
}) {
  const [open, setOpen] = useState(campCount);
  const [list, setList] = useState(campList.CampData);
  const [ids, setIds] = useState([]);
  const email = getSessionStorage("userEmail");
  const handleClose = () => {
    setOpen(false);
    setCampCount(false);
    setDisBtn(false);
  };

  useEffect(() => {
    if (campList) {
      setList(campList.CampData);
    }
  }, [list, ids]);

  const handleAgry = (id) => {
    setIds((prevIds) => {
      const idIndex = prevIds.indexOf(id);
      if (idIndex !== -1) {
        // If ID exists, remove it
        const updatedIds = [...prevIds];
        updatedIds.splice(idIndex, 1);
        return updatedIds;
      } else {
        // If ID doesn't exist, add it
        return [...prevIds, id];
      }
    });
  };

  const ApiCall = async () => {
    let apiId = list
      .filter((item) => !ids.includes(item._id))
      .map((filteredItem) => filteredItem._id);
    if (ids.length === 0) {
      return toast.error("Please Select Agents");
    } else if (
      list.filter((item) => ids.includes(item._id)).length >
      campaignData?.metadata?.Agents
    ) {
      return toast.error(
        `Please select upto ${campaignData?.metadata?.Agents} agents`
      );
    }
    try {
      let sendData = {
        email: email,
        ids: apiId,
      };
      const response = await axios.post(
        `${API_SERVICE_BACKEND}/selectCampaign`,
        sendData
      );

      if (response.status === 200) {
        selectCampUpdate();
      }
      setOpen(false);
      setCampCount(false);
    } catch (error) {
      console.log(error);
      setOpen(false);
      setCampCount(false);
      setDisBtn(false);
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`You have already ${count} agents. Plan you are switching to support upto ${
            campaignData && campaignData?.metadata?.Agents
          } agents. Please select agents below which you want to keep in your system`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  {/* <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell>
            </TableRow> */}
                </TableHead>
                <TableBody>
                  {list &&
                    list.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox
                            {...label}
                            onClick={() => handleAgry(row._id)}
                          />
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        {/* <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={ApiCall} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CampaignList;
