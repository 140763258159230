import React from "react";
import PropTypes, { number } from "prop-types";
import CheckVerified01 from "@untitled-ui/icons-react/build/esm/CheckVerified01";
import Users from "src/components/logos/Users.png";
import PlayArrowIcon from "src/components/logos/PlayArrowIcon.png";
import ThreeP from "src/components/logos/ThreeP.png";
import CopyAll from "src/components/logos/CopyAll.png";
import calendar from "src/components/logos/calendar.png";
import LinkIcon from "src/components/logos/linkIcon.png";
import { CustomSwitch } from "src/components/custom-switch";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import FilterIcon from "src/components/logos/filterIcon.png";
import SelectIcon from "src/components/logos/selectGlobeIcon.png";
import AddIcon from "@mui/icons-material/Add";
import PauseIcon from "@mui/icons-material/Pause";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Switch,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  ThemeProvider,
  createTheme,
  List,
  RadioGroup,
  Radio,
  Paper,
  Slide,
  alpha,
  Snackbar,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { paths } from "src/paths";
import { createRef, useCallback, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import {
  API_SERVICE_BACKEND,
  MAILBOXES_PER_CAMPAIGN,
  MIN_LEADS,
  SALES_CHAT,
  SOCKET_URL,
  API_HIPPO_URL,
  cronofy,
  API_SERVICE_BACKEND_3,
  API_SERVICE_BACKEND_2,
  ADMIN_SERVER,
  googleWebAppConfig,
  microsoftWebAppConfig,
} from "src/config";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-hot-toast";
import { CTableCell, compObj, copyToClipboard } from "src/utils/misc";
import {
  INDUSTRY_OPTIONS,
  FILTERS,
  DESIGNATION,
  CREATIVENESS,
  LENGTH,
  TONE,
  OPENING,
  CONNECTING,
  BODY,
  emailDataStructure,
  emailContentData,
} from "../../../data/campaign";
import { useLocation, useNavigate } from "react-router";
import { jobsApi } from "src/api/jobs";
import { QuillEditor } from "src/components/quill-editor";
import {
  CalendarMonth,
  ExitToApp,
  Info,
  ContentCopy,
} from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Filter } from "./job-details-step";
import { COUNTRIES } from "src/data/countries";
import _ from "lodash";
import { mailBoxesApi } from "src/api/mailBoxes";
import { TestimonialView } from "./TestimonialView";
import AdditionalFilters from "./aditionalFilters";
import TrainingData from "./trainingData";
import calenderPlus from "../../../components/logos/calendarPlus.png";
import LinkIcon2 from "@mui/icons-material/Link";
import {
  AvailabilityRulesWrapper,
  CalendarSyncWrapper,
} from "../../../components/cronofy";
import { cronofyApi } from "../../../api/cronofy";
import { getUserInfo } from "../../../pages/dashboard/account";
import { AuthContext } from "../../../contexts/auth/firebase-context";
import CampaignRowCard from "./campaign-row-card";
import { GmailIcon } from "src/components/logos/gmailIcon";
import { MicrosoftIcon } from "src/components/logos/microsoftIcon";
import { EmptyBox } from "src/components/logos/emptyBox";
import {
  deleteSessionStorage,
  getSessionStorage,
  setSessionStorage,
} from "src/utils/storage";

const locFilter = FILTERS.find(({ id }) => id === "location");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const userEmail = getSessionStorage("userEmail");
const userName = getSessionStorage("fullName");

const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};

const initMailBoxList = [
  { title: "test@test.com", agent: "" },
  { title: "123@rb.com", agent: "" },
  { title: "newb@test.com", agent: "" },
  { title: "abc@test.com", agent: "" },
  { title: "xyz@rb.com", agent: "" },
  { title: "test123@test.com", agent: "" },

  { title: "rob@test.com", agent: "Rob" },
  { title: "rob123@rb.com", agent: "Rob" },
  { title: "123rob@test.com", agent: "Rob" },
  { title: "1rob@test.com", agent: "Rob" },
  { title: "rob12@rb.com", agent: "Rob" },
  { title: "13rob@test.com", agent: "Rob" },
  { title: "rob9@test.com", agent: "Rob" },
  { title: "rob163@rb.com", agent: "Rob" },
  { title: "103rob@test.com", agent: "Rob" },
  { title: "3rob@test.com", agent: "Rob" },
  { title: "alice@test.com", agent: "Alice" },
  { title: "alice123@rb.com", agent: "Alice" },
  { title: "123alice@test.com", agent: "Alice" },
  { title: "alice7@test.com", agent: "Alice" },
  { title: "alice23@rb.com", agent: "Alice" },
  { title: "13alice@test.com", agent: "Alice" },
  { title: "alice5@test.com", agent: "Alice" },
  { title: "alice153@rb.com", agent: "Alice" },
  { title: "37alice@test.com", agent: "Alice" },
  { title: "143alice@test.com", agent: "Alice" },
  { title: "rachel@test.com", agent: "Rachel" },
  { title: "rachel123@rb.com", agent: "Rachel" },
  { title: "123rachel@test.com", agent: "Rachel" },
  { title: "rachel5@test.com", agent: "Rachel" },
  { title: "rachel13@rb.com", agent: "Rachel" },
  { title: "1rachel@test.com", agent: "Rachel" },
  { title: "rachel8@test.com", agent: "Rachel" },
  { title: "rachel3@rb.com", agent: "Rachel" },
  { title: "193rachel@test.com", agent: "Rachel" },
  { title: "mary@test.com", agent: "Mary" },
  { title: "mary123@rb.com", agent: "Mary" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MailboxSelect = ({
  selectedAgent,
  setSelectedAgent,
  onClose,
  mailBoxList,
  setMailBoxList,
  isFetching,
  assignedUnAssinedMailbox,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [temp, setTemp] = useState({ title: "", agent: "" });
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [selectMailBox, setSelectMailBox] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e, mailbox) => {
    if (mailbox.agent === "") {
      if (e.target.checked) {
        setSelectedAccounts([...selectedAccounts, mailbox.title]);
        setSelectMailBox((prevAccounts) => [...prevAccounts, mailbox.id]);
      } else {
        const filtered = selectedAccounts.filter(
          (acc) => acc !== mailbox.title
        );
        setSelectMailBox((prevAccounts) =>
          prevAccounts.filter((acc) => acc !== mailbox.id)
        );
        setSelectedAccounts(filtered);
      }
    } else {
      setTemp(mailbox);
      setOpen(true);
    }
  };

  const handleAddAccounts = () => {
    let datamail = {
      campaignId: "add",
      mailboxId: selectMailBox,
    };
    // console.log("datamail====>", datamail);
    assignedUnAssinedMailbox(datamail);
    onClose();
    // if (selectedAccounts.length > 0) {
    //   setLoading(true);
    //   const updatedAgent = {
    //     ...selectedAgent,
    //     "Sending A/Cs": selectedAgent["Sending A/Cs"] + selectedAccounts.length,
    //     "mail box": [...selectedAgent["mail box"], ...selectedAccounts],
    //   };
    //   const updatedMailBoxList = mailBoxList.map((e) =>
    //     selectedAccounts.includes(e.title)
    //       ? { title: e.title, agent: selectedAgent["Agent Name"] }
    //       : e
    //   );
    //   setSnackbarMsg(`Account Successfully Added to ${selectedAgent["Agent Name"]}!`);
    //   setMailBoxList(updatedMailBoxList);
    //   setSelectedAgent(updatedAgent); //setcampaignmailboxes

    //   setTimeout(() => {
    //     setLoading(false);
    //     setSnackbarMsg("");
    //     onClose();
    //   }, 1000);
    // }
  };
  const handleRemoveAccount = () => {
    let datamail = {
      mailboxId: temp.id,
    };
    assignedUnAssinedMailbox(datamail);
    handleClose();
    // if (temp.agent === selectedAgent["Agent Name"]) {
    //   const updatedAgent = {
    //     ...selectedAgent,
    //     "Sending A/Cs": selectedAgent["Sending A/Cs"] - 1,
    //     "mail box": selectedAgent["mail box"].filter((e) => e !== temp.title),
    //   };
    //   setSelectedAgent(updatedAgent); //setcampaignmailboxes
    // }

    // const updatedMailBoxList = mailBoxList.map((e) =>
    //   temp.title === e.title ? { title: e.title, agent: "" } : e
    // );
    // setMailBoxList(updatedMailBoxList);
    // setSnackbarMsg(`Account Successfully Removed from ${temp.agent}!`);
    // handleClose();
    // setTimeout(() => {
    //   setSnackbarMsg("");
    // }, 1000);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={mailBoxList}
          loading={isFetching}
          disableCloseOnSelect
          getOptionLabel={(option) => option.title || option}
          value={selectedAccounts}
          noOptionsText={"No mailboxes"}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  option.agent !== "" || selectedAccounts.includes(option.title)
                }
                onChange={(e) => handleChange(e, option)}
              // checked={selected}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Typography> {option.title || option}</Typography>

                {option.agent !== "" && (
                  <span
                    style={{
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      borderRadius: "16px",
                      color: "#ffffff",
                      fontSize: "14px",
                      // border: "1px solid #5761FE",
                      backgroundColor:
                        selectedAgent === option.agent ? "#7eb945" : "#5761FE",
                    }}
                  >
                    {selectedAgent === option.agent ? "Current" : "Assigned"}
                  </span>
                )}
              </Box>
            </li>
          )}
          style={{ width: "100%" }}
          onChange={(event, value, reason) => {
            if (reason === "clear") {
              setSelectedAccounts([]);
            }
            if (reason === "removeOption") {
              setSelectedAccounts(value);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select" variant="outlined" />
          )}
        />
        <Divider sx={{ width: "100%", borderColor: alpha("#000000", 0.1) }} />
        <Button
          variant="contained"
          fullWidth
          onClick={handleAddAccounts}
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Accounts"}
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Remove account</Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to remove <b>{temp.title}</b> from the AI agent -{" "}
            <b>{temp.agent}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleRemoveAccount}
          >
            Remove
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!snackbarMsg}
        onClose={() => setSnackbarMsg("")}
        message={snackbarMsg}
        key={"topcenter"}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#5761FE" },
        }}
      />
    </>
  );
};

const MailboxRow = ({
  index,
  mailbox,
  userType,
  agentName,
  setOpenMailStats,
  setMailBoxItem,
}) => {
  const [connected, setConnected] = useState(true);
  let lastResetXHrsAgo =
    (Date.now() - new Date(mailbox.lastReset).getTime()) / (1000 * 60 * 60);
  lastResetXHrsAgo = Math.ceil(lastResetXHrsAgo);

  let nextRefresh = new Date(
    new Date(mailbox.lastReset).getTime() + 60 * 60 * 24 * 1000
  );
  const nextRefreshInHrs = Math.ceil(Math.abs(nextRefresh - new Date()) / 36e5);
  // console.log("last refresh", new Date(mailbox.lastReset).toString());
  // console.log("next refresh", nextRefresh.toString());
  // console.log("next refresh in hrs", Math.ceil(nextRefreshInHrs));
  // console.log(`mailbox ${index}`, mailbox);
  const handleClick = () => {
    setConnected(!connected);
  };
  const monthRef = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date();
  const dateString = `${monthRef[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  return (
    <>
      <TableRow sx={{ "& .MuiTableCell-root": { textAlign: "center" } }}>
        <TableCell sx={{ textAlign: "left !important" }}>{agentName}</TableCell>
        <TableCell>1</TableCell>
        <TableCell>
          <span
            style={{
              backgroundColor: mailbox.healthStatus
                ? mailbox.healthStatus >= 85
                  ? "#7eb945"
                  : "red"
                : "grey",
              padding: "8px",
              color: "#ffffff",
              borderRadius: "4px",
            }}
          >
            {mailbox.healthStatus
              ? mailbox.healthStatus >= 85
                ? "Healthy"
                : "Warning"
              : "N/A"}
          </span>
        </TableCell>

        <TableCell sx={{ textAlign: "left !important" }}>
          {mailbox.senderEmail}
        </TableCell>

        <TableCell>
          <Button
            variant="text"
            disableRipple
            sx={{
              whiteSpace: "nowrap",
              px: 0,
              color: "#808080",
              "&:hover": { backgroundColor: "transparent", color: "#696969" },
            }}
            onClick={() => {
              setOpenMailStats(true);
              setMailBoxItem(mailbox);
            }}
          >
            View stats
          </Button>
        </TableCell>
        {userType === "enterprise" && (
          <TableCell>
            <Button
              sx={{
                color: "#414BB2",
                px: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#5a67ed",
                },
              }}
              onClick={handleClick}
              variant="text"
              disableRipple
            >
              {connected ? "Disconnect" : "Connect"}
            </Button>
          </TableCell>
        )}
        <TableCell>Active</TableCell>
        <TableCell sx={{ color: "#414BB2" }}>
          {mailbox.warmupStatus
            ? mailbox.warmupStatus === "Active"
              ? "Yes"
              : "No"
            : "N/A"}
        </TableCell>
        <TableCell sx={{ color: "#414BB2" }}>
          {mailbox.healthStatus && mailbox.healthStatus > 0
            ? `${mailbox.healthStatus}%`
            : "N/A"}
        </TableCell>
        <TableCell sx={{ color: "#414BB2" }}>{mailbox.inWeek}</TableCell>
        <TableCell sx={{ color: "#414BB2" }}>{mailbox.emailsSent}</TableCell>
        <TableCell sx={{ color: "#414BB2" }}>{mailbox.maxAllowed}</TableCell>
        <TableCell sx={{ color: "#414BB2" }}>20</TableCell>
        <TableCell sx={{ color: "#414BB2" }}>
          {nextRefreshInHrs} {nextRefreshInHrs > 1 ? "Hrs" : "Hr"}
        </TableCell>
        <TableCell sx={{ color: "#414BB2" }}>{mailbox._id}</TableCell>
      </TableRow>
    </>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

// status -> 0: failed, 1: inprogress, 2: success

let campaignMaxCallsAllowedToGetAccessToken = 3;
const now = new Date();
async function generateHMAC(message, key) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", cryptoKey, messageData);
  return Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

const hash = await generateHMAC(
  getSessionStorage("userEmail"),
  process.env.REACT_APP_INTERCOM_HASH_KEY
);

export const CampaignRow = (props) => {
  // console.log("props-----", props);
  const {
    campaign: {
      _id,
      title,
      link: linkObj,
      senderName,
      senderTitle,
      maxMailBox,
      companyName,
      companyLocation: compLocStr,
      campaignStatus,
      prospectData,
      companyData,
      emailStructure,
      emailContent,
      responsibility,
      emailSubject,
      companyWebsite,
      productDesc,
      paused,
      addFilters,
      campaignDesc,
      expertise,
      customMessage,
      link,
      linkPlacement,
      testimonials,
      testimonials_v2,
      industry,
      targets,
      status,
      blockedDomains,
      operationStat,
      logo,
      additionalFilters,
      sicCode,
      naicsCode,
      videoUrl,
      extra,
      // not yet available
      conversations,
    },
    socket,
    emailSocket,
    isSaveAsDraft,
    user,
    remaining,
    userDetail,
    ...other
  } = props;
  const userType = user?.dbRef?.userType;

  const companyLocation = COUNTRIES.filter(
    ({ title, value }) => compLocStr === title
  );

  const resetFiltersInit = {
    addFilters: { ..._.cloneDeep(addFilters) },
    industry: [..._.cloneDeep(industry)],
    targets: [..._.cloneDeep(targets)],
  };

  const userOrgName = getSessionStorage("companyName");
  const parts = userOrgName?.split(" ") || [];
  const desiredPart = parts[0] + "..";

  const navigate = useNavigate();

  const linkToLeads = paths.dashboard.contacts + `?campaignId=${_id}`;
  const salesAgentURL = `${SALES_CHAT}/${_id}?leadEmail=anonymous&fname=test&lname=user`;
  const campaginVideoURL = `${API_HIPPO_URL}/campaignVideo/${_id}`;

  const [progress, setProgress] = useState(
    parseInt((props?.campaign?.operationStat?.leadsGen * 100) / MIN_LEADS)
  );
  // const [leadsCount, setLeadsCount] = useState((progress / 100) * MIN_LEADS);
  const [leadsCount, setLeadsCount] = useState(
    props?.campaign?.operationStat?.leadsGen
  );
  const [campMailBoxes, setCampMailBoxes] = useState(null);
  const [tempTargets, setTempTargets] = useState(targets);
  const [tempIndustry, setTempIndustry] = useState(industry);
  const [tempStatus, setTempStatus] = useState(status);
  const [tempPaused, setTempPaused] = useState(paused);
  const [campStatus, setCampStatus] = useState(campaignStatus);
  const [emailStatus, setEmailStatus] = useState(
    props?.campaign?.operationStat?.totalEmailed
      ? props?.campaign?.operationStat?.totalEmailed
      : 0
  );
  const [temp3, setTemp3] = useState({
    senderName,
    senderTitle,
    companyName,
    companyLocation,
    title,
    link: linkObj,
    campaignDesc,
    productDesc,
    expertise,
    testimonials,
    addFilters,
    testimonials_v2,
  });
  const handleChange3 = (field, value) => {
    setTemp3((prev) => ({ ...prev, [field]: value }));
  };

  const [tempLinkObj, setTempLinkObj] = useState({
    url: linkObj?.url || "",
    enabled: linkObj?.enabled || false,
  });
  const onTempLinkObjChange = (field, value) => {
    setTempLinkObj((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    handleChange3("link", tempLinkObj);
  }, [tempLinkObj]);

  const getMailBoxes = useCallback(async () => {
    const mailBoxes = await mailBoxesApi.getMailBoxes({
      filter: {
        "assigned.campaignId": _id,
      },
      projection: {
        _id: 1,
        senderEmail: 1,
        emailsSent: 1,
        maxAllowed: 1,
        inWeek: 1,
        lastReset: 1,
        createdAt: 1,
        healthStatus: 1,
        warmupStatus: 1,
      },
      limit:maxMailBox||10
    });
    // console.log(mailBoxes);
    // const reqMailBoxCount = MAILBOXES_PER_CAMPAIGN - mailBoxes?.length;
    // if ((totalEmailed < leadsGen || !campaignStatus) && reqMailBoxCount > 0) {
    //   console.log(`(${_id}) Assigning required mailboxes: ${reqMailBoxCount}`);
    //   const assignmentRes = await mailBoxesApi.assignMailBoxes({
    //     email: user.email,
    //     campaignId: _id,
    //     limit: reqMailBoxCount,
    //   });
    //   console.log(assignmentRes);
    //   if (assignmentRes?.nModified) {
    //     getMailBoxes();
    //   }
    // }
    setCampMailBoxes(mailBoxes);
  }, []);

  useEffect(() => {
    getMailBoxes();
  }, []);

  const processedLocations = COUNTRIES.filter(({ title, value }) =>
    addFilters.location?.includes(title)
  );
  const processedBlockedLocations = COUNTRIES.filter(({ title, value }) => {
    return addFilters.blockedLocations?.includes(title);
  });
  const [extraFilters, setExtraFilters] = useState({
    ..._.cloneDeep(addFilters),
    location: processedLocations,
    blockedLocations: processedBlockedLocations,
  });
  const onFiltersChange = (field, value) => {
    setExtraFilters((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    // console.log(extraFilters);
    handleChange3("addFilters", extraFilters);
  }, [extraFilters]);

  const [content, setContent] = useState(campaignDesc);
  const handleContentChange = useCallback((value) => {
    setContent(value);
  }, []);

  useEffect(() => {
    handleChange3("campaignDesc", content);
  }, [content]);

  useEffect(() => {
    if (!socket) return;
    socket.on("leads-gen-status", ({ campaignId, status: data }) => {
      // console.log(campaignId, _id, data);
      if (campaignId !== _id) return;
      try {
        setLeadsCount(data);
        setProgress((data / MIN_LEADS) * 100);
        if (data >= MIN_LEADS) {
          setTempStatus(2);
        }
      } catch (err) {
        console.error(err);
      }
    });
    socket.on("leads-gen-haulted", ({ campaignId, status: data }) => {
      // console.log(campaignId, _id, data);
      if (campaignId !== _id) return;
      try {
        setTempStatus(data);
      } catch (err) {
        console.error(err);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (!emailSocket) return;
    emailSocket.on("emails-sent-status", ({ campaignId, status: data }) => {
      // console.log(campaignId, _id, data);
      if (campaignId !== _id) return;
      try {
        setEmailStatus(data);
        setCampStatus(data >= MIN_LEADS);
        // if (data >= 100) {
        //   setTempStatus(2);
        // }
      } catch (err) {
        console.error(err);
      }
    });
  }, [emailSocket]);

  const [dialog, setDialog] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [addType, setAddType] = useState("");
  const [mailboxList, setMailboxList] = useState([]);
  const [openMailStats, setOpenMailStats] = useState(false);
  const [hippoVideoStatus, setHippoVideoStatus] = useState([]);

  const [openCalenderDialog, setOpenCalenderDialog] = useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [isFetching, setIsFetching] = useState(false);

  const baseUrl = API_SERVICE_BACKEND;
  const fetchAllMailBoxes = async (account) => {
    try {
      setIsFetching(true);
      // const res = await fetch(`${API_SERVICE_BACKEND}/getuserAnalytics?page=${page}&limit=${rowsPerPage}&search=${encodeURIComponent(searchText)}`);
      const res = await fetch(`${baseUrl}/usermailbox/${account}`);
      const userdata = await res.json();
      setIsFetching(false);
      setMailboxList(
        userdata.data.map((e) => {
          return {
            title: e.senderEmail,
            agent: e.assigned?.campaignId
              ? e.assigned?.campaignId?.senderName
              : "",
            agentId: e.assigned?.campaignId ? e.assigned?.campaignId?._id : "",
            id: e._id,
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  // console.log("agentData=====>", _id);
  const assignedUnAssinedMailbox = async (datam) => {
    let payload = {};
    if (datam?.campaignId == "add") {
      payload = {
        campaignId: _id,
        email: user?.email,
        mailboxId: datam.mailboxId,
      };
    } else {
      payload = {
        mailboxId: [datam.mailboxId],
      };
    }
    try {
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/addremovecompaign`,
        {
          payload,
        }
      );
      // console.log("check response=====>", res);
      // setLoading(false);
      navigate("/dashboard/campaigns", { replace: true });
      setLoading(false);
      getMailBoxes();
      return res;
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  function getInDays(startDate) {
    const start = new Date(startDate);
    const end = new Date();
    const timeDiff = end - start;
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    return Math.ceil(daysDiff);
  }

  useEffect(() => {
    if (open) {
      axios
        .get(campaginVideoURL)
        .then((res) => res.data)
        .then((resdata) => setHippoVideoStatus(resdata))
        .catch((error) => console.log("campagin video fetch error", error));
    }
  }, [open, campaginVideoURL]);

  const [mailBoxItem, setMailBoxItem] = useState(null);
  const handleOpen = (dialog) => {
    setDialog(dialog);
    setOpen(true);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  const handleClose = () => {
    setDialog(null);
    setOpen(false);
  };
  const handleCalenderDialogClose = () => {
    setOpenCalenderDialog(false);
  };

  const [loading, setLoading] = useState(false);

  const [prevResetFilters, setPrevResetFilters] = useState(resetFiltersInit);
  // useEffect(() => {
  //   console.log(prevResetFilters);
  // }, [prevResetFilters]);

  const updateTargets = async () => {
    setLoading(true);
    const tempAddFilters = { ..._.cloneDeep(temp3.addFilters) };
    const tempCompLoc = temp3.companyLocation.map(({ title, value }) => {
      return title;
    });

    const tempLoc = tempAddFilters.location.map(({ title, value }) => {
      return title;
    });
    const tempBlockLoc = tempAddFilters?.blockedLocations?.map(
      ({ title, value }) => {
        return title;
      }
    );
    tempAddFilters.location = tempLoc;
    tempAddFilters.blockedLocations = tempBlockLoc || [];
    tempAddFilters.blockedIndustries = newBlockedIndustry || [];

    const newPrevFilterStateOnUpdate = {
      targets: tags,
      industry: newIndustry,
      addFilters: { ...tempAddFilters },
    };

    const filterChanged = !compObj(
      prevResetFilters,
      newPrevFilterStateOnUpdate
    );
    // console.log("Triggering changes in filters ?", filterChanged);
    // console.log(prevResetFilters, newPrevFilterStateOnUpdate);

    const payload = { ...temp3, ...newPrevFilterStateOnUpdate };
    payload.companyLocation = tempCompLoc[0];
    let compSize = payload.addFilters.compSize;
    const idx = compSize[0].indexOf("Custom");
    if (idx !== -1) {
      let min = compSize[1][0];
      let max = compSize[1][1];
      if (!min) min = 1;
      if (!max) max = 1000000000;
      compSize[0][idx] = `${min},${max}`;
    }
    payload.addFilters.compSize = compSize[0];
    try {
      const endpoint = Boolean(isSaveAsDraft === "true")
        ? "updateSaveAsDraftCampaign"
        : "updateCampaign";
      const res = await axios.patch(`${API_SERVICE_BACKEND}/${endpoint}`, {
        id: _id,
        payload,
        filterChanged,
      });
      if (res.status === 204) {
        setTempTargets(tags);
        setTempIndustry(newIndustry);
        if (filterChanged) {
          setPrevResetFilters(newPrevFilterStateOnUpdate);
          setTempStatus(1);
        }
        toast.success(`Campaign (${_id}) Updated`);
      } else {
        throw new Error("Updating Campaign failed, Try again !");
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e.message);
    }
    handleClose();
    setLoading(false);
  };

  const [tag, setTag] = useState("");
  // console.log(tag, "tag");
  const [tags, setTags] = useState(targets);
  const [newIndustry, setNewIndustry] = useState(
    typeof industry === "string" ? [industry] : industry
  );
  const [newBlockedIndustry, setNewBlockedIndustry] = useState(
    addFilters.blockedIndustries || []
  );

  const handleIndustryDelete = useCallback((delInd) => {
    setNewIndustry((prev) => {
      return prev.filter((ind) => ind !== delInd);
    });
  }, []);

  const handleTagAdd = useCallback((tag) => {
    setTags((prevState) => {
      const newTags = [];
      const uniqueTags = new Set([...prevState, tag]);
      for (let value of uniqueTags.values()) {
        newTags.push(value);
      }
      return newTags;
    });
  }, []);

  const handleTagDelete = useCallback((tag) => {
    if (tempStatus === 2) {
      toast.error(`Cannot modify Filters once data fetching has completed)`);
      return;
    }
    setTags((prevState) => {
      return prevState.filter((t) => t !== tag);
    });
  }, []);
  const [activeCampaignsCount, setActiveCampaignsCount] = useState(0);
  const fetchActiveCampaigns = () => {
    const userEmail = getSessionStorage("userEmail");
    jobsApi
      .getUserCampaigns(userEmail)
      .then((data) => {
        const countFalsePaused = data?.data?.filter(
          (item) => item.paused === false
        ).length;
        setActiveCampaignsCount(countFalsePaused);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
      });
  };
  const [pauseLoading, setPauseLoading] = useState(false);
  const togglePaused = async (campaignId) => {
    setPauseLoading(true);
    const res = await jobsApi.updateCampaign(campaignId, {
      paused: !tempPaused,
    });
    if (res) {
      setTempPaused(!tempPaused);
      fetchActiveCampaigns();
    } else {
      toast.error(
        tempPaused ? "Failed to pause campaign !" : "Failed to start campaign"
      );
    }
    setPauseLoading(false);
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getusercampagin/${campaignId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (tempPaused) {
        const testimonialPresence = data.testimonials_v2.map((item) =>
          item.testimonial && item.testimonial.trim() !== "" ? true : false
        );
        window.Intercom("trackEvent", "agent_launched", {
          agent_name: data.title,
          agent_title: data.senderTitle,
          company_name: data.companyName,
          company_location: data.companyLocation,
          company_info:
            data.campaignDesc && data.campaignDesc.trim() !== "" ? true : false,
          offer_info:
            data.productDesc && data.productDesc.trim() !== "" ? true : false,
          success_story_info:
            data.testimonials && data.testimonials.trim() !== "" ? true : false,
          testimonial1: testimonialPresence[0],
          testimonial2: testimonialPresence[1],
          testimonial3: testimonialPresence[2],
          target_adudience: true,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
  useEffect(() => {
    fetchActiveCampaigns();
    window.Intercom("update", {
      email: getSessionStorage("userEmail"),
      user_hash: hash,
      AI_agents_active: activeCampaignsCount,
    });
  }, [activeCampaignsCount]);
  window.intercomSettings = {
    app_id: "qaecvjwi",
    name: userName,
    email: user.email,
    user_hash: hash,
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("addButton").click();
    }
  };

  const [
    numberOfFieldsToBeShownTempTargets,
    setNumberOfFieldsToBeShownTempTargets,
  ] = useState(10);
  const incrementBy10TempTargets = () => {
    setNumberOfFieldsToBeShownTempTargets(
      numberOfFieldsToBeShownTempTargets + 10
    );
  };

  const [
    numberOfFieldsToBeShownTempIndustry,
    setNumberOfFieldsToBeShownTempIndustry,
  ] = useState(10);
  const incrementBy10TempIndustry = () => {
    setNumberOfFieldsToBeShownTempIndustry(
      (prevNumberOfFields) => prevNumberOfFields + 10
    );
  };

  const handleCopyCampaign = () => {
    if (userDetail?.freeSubscription === true) {
      return navigate("/dashboard/campaigns/create", {
        state: { campaignId: _id, allowCampCreation: Boolean(remaining), isCopy: "copy" },
      });
    }
    if (userDetail && userDetail.limitExceeded) {
      toast.error(
        "Limit Exceeded Please upgrade plan or add additional agents"
      );
    } else {
      navigate("/dashboard/campaigns/create", {
        state: { campaignId: _id, allowCampCreation: Boolean(remaining), isCopy: "copy" },
      });
    }
  };
  const handleEditCampaign = () => {
    navigate("/dashboard/campaigns/edit?isSaveAsDraft=" + isSaveAsDraft, {
      state: {
        campaignId: _id,
        allowCampCreation: false,
        edit: true,
        isEditAI: true,
      },
    });
  };

  // const getMailBoxes = async () => {
  //   setLoading(true);
  //   const emailsList = await axios.post(`${API_SERVICE_BACKEND_AZURE}/mailboxes/read`, {
  //     payload: {
  //       filter: {},
  //       projection: {},
  //       page,
  //       limit: rowsPerPage,
  //     },
  //   });
  //   // console.log(emailsList.data.data);
  //   setAllEmails({ data: emailsList.data.data, length: emailsList.data.length });
  //   setLoading(false);
  // };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#050C46",
            color: "White",
          },
        },
      },
    },
  });
  const columns = [
    "Agent Name",
    "Sending A/Cs",
    "Issues/Warning",
    "ID / Mailboxes",
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [code, setCode] = useState(searchParams.get("code"));
  const EmailProviders = Object.freeze({
    GOOGLE: "google",
    MICROSOFT: "microsoft",
  });
  const handleConnectGoogleAccount = async (id) => {
    setSessionStorage("emailProviderType", EmailProviders.GOOGLE);
    setSessionStorage("campaignId", id);
    const params = new URLSearchParams({
      redirect_uri: googleWebAppConfig.redirect_uri,
      client_id: googleWebAppConfig.client_id,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: googleWebAppConfig.scopes,
    });
    // console.log(params);
    const url = `${googleWebAppConfig.oauth_url}?${params.toString()}`;
    window.open(url, "_self");
  };
  const handleConnectMicrosoftAccount = async (id) => {
    setSessionStorage("emailProviderType", EmailProviders.MICROSOFT);
    setSessionStorage("campaignId", id);
    const params = new URLSearchParams({
      redirect_uri: microsoftWebAppConfig.redirect_uri,
      client_id: microsoftWebAppConfig.client_id_azure,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: microsoftWebAppConfig.scopes,
      // sso_reload: true
    });
    const url = `${microsoftWebAppConfig.oauth_url}?${params.toString()}`;
    window.open(url, "_self");
  };

  const exchangeCodeForAccessToken = async (code) => {
    const accessTokenUrlsMap = {
      [EmailProviders.GOOGLE]: "/getAccessTokenEnterprice",
      [EmailProviders.MICROSOFT]: "/exchangeCodeForAccessTokenMicrosoft2",
    };
    const emailProviderType = getSessionStorage("emailProviderType");
    // console.log(
    //   "emailProviderType====>",
    //   accessTokenUrlsMap[emailProviderType]
    // );
    const campaignId = getSessionStorage("campaignId");
    const res = await axios.post(
      `${API_SERVICE_BACKEND}/mailbox${accessTokenUrlsMap[emailProviderType]}`,
      {
        code,
        createdBy: user.email,
        campaignId: campaignId,
      }
    );
    // console.log("res======>", res);
    return res.data;
  };

  // useEffect(() => {
  //   const codeFromUrl = searchParams.get("code");
  //   if (codeFromUrl) {
  //     setCode(codeFromUrl);
  //     setLoading(true);
  //     exchangeCodeForAccessToken(codeFromUrl)
  //       .then((res) => {
  //         if (typeof res.resource == "number" && res.resource >= 1) {
  //           toast.error("Mail box already exist");
  //         } else {
  //           toast.success("Mail box added successfully");
  //           console.log(res.resource.error, "=====error");
  //         }

  //         setTimeout(() => {
  //           navigate("/dashboard/campaigns", { replace: true });
  //         }, 100);
  //         setLoading(false);
  //         deleteSessionStorage("campaignId");
  //         getMailBoxes();
  //         // window.location.reload();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   }
  // }, []);

  const ref = createRef(null);

  async function copyToClipboard(text) {
    return await navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        return true;
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        return false;
      });
  }

  const handleCopy = () => {
    const dialogTitle = document.getElementById(
      "alert-viewdialog-title"
    ).innerText;

    const responsibilityContent = document.querySelector(
      '#alert-dialog-description [name="responsibility"]'
    )?.value;
    const fullName = document.querySelector(
      '#alert-dialog-description [name="agentName"]'
    )?.value;
    const agentTitle = document.querySelector(
      '#alert-dialog-description [name="agentTitle"]'
    )?.value;
    const agentLocation = document.querySelector(
      '#alert-dialog-description [name="agentLocation"]'
    )?.value;
    const agentcompanyName = document.querySelector(
      '#alert-dialog-description [name="agentcompanyName"]'
    )?.value;
    const locations = document.querySelector(
      '#alert-dialog-description [name="locations"]'
    )?.value;
    const titles = document.querySelector(
      '#alert-dialog-description [name="titles"]'
    )?.value;
    const department = document.querySelector(
      '#alert-dialog-description [name="department"]'
    )?.value;
    const management = document.querySelector(
      '#alert-dialog-description [name="management"]'
    )?.value;
    const changedjob = document.querySelector(
      '#alert-dialog-description [name="changedjob"]'
    )?.value;
    const skills = document.querySelector(
      '#alert-dialog-description [name="skills"]'
    )?.value;
    const experience = document.querySelector(
      '#alert-dialog-description [name="experience"]'
    )?.value;
    const companyName = document.querySelector(
      '#alert-dialog-description [name="companyName"]'
    )?.value;
    const employee = document.querySelector(
      '#alert-dialog-description [name="employee"]'
    )?.value;
    const industrynames = document.querySelector(
      '#alert-dialog-description [name="industrynames"]'
    )?.value;
    const siccodes = document.querySelector(
      '#alert-dialog-description [name="siccodes"]'
    )?.value;
    const naiccodes = document.querySelector(
      '#alert-dialog-description [name="naiccodes"]'
    )?.value;
    const major = document.querySelector(
      '#alert-dialog-description [name="major"]'
    )?.value;
    const school = document.querySelector(
      '#alert-dialog-description [name="school"]'
    )?.value;
    const degree = document.querySelector(
      '#alert-dialog-description [name="degree"]'
    )?.value;
    const minfunding = document.querySelector(
      '#alert-dialog-description [name="minfunding"]'
    )?.value;
    const maxfunding = document.querySelector(
      '#alert-dialog-description [name="maxfunding"]'
    )?.value;
    const minrevenue = document.querySelector(
      '#alert-dialog-description [name="minrevenue"]'
    )?.value;
    const maxrevenue = document.querySelector(
      '#alert-dialog-description [name="maxrevenue"]'
    )?.value;
    const keywords = document.querySelector(
      '#alert-dialog-description [name="keywords"]'
    )?.value;
    const blockedIndustries = document.querySelector(
      '#alert-dialog-description [name="blockedIndustries"]'
    )?.value;
    const blockedDomains = document.querySelector(
      '#alert-dialog-description [name="blockedDomains"]'
    )?.value;
    const blockedKeywords = document.querySelector(
      '#alert-dialog-description [name="blockedKeywords"]'
    )?.value;
    const blockedLocations = document.querySelector(
      '#alert-dialog-description [name="blockedLocations"]'
    )?.value;
    const describecompany = document.querySelector(
      '#alert-dialog-description [name="describecompany"]'
    )?.value;
    const productDesc = document.querySelector(
      '#alert-dialog-description [name="productDesc"]'
    )?.value;
    const stories = document.querySelector(
      '#alert-dialog-description [name="stories"]'
    )?.value;
    const emailsubject = document.querySelector(
      '#alert-dialog-description [name="emailsubject"]'
    )?.value;
    const companywebsite = document.querySelector(
      '#alert-dialog-description [name="companywebsite"]'
    )?.value;
    const emailSelectContent = emailDataStructure
      .map((option) => {
        return `${option?.title}: ${emailStructure[option?.id]}`;
      })
      .join("\n");

    const emaildataContent = emailContentData
      .map((option) => {
        return `${option?.title}: ${emailContent[option?.id]}`;
      })
      .join("\n");

    const testimonials = testimonials_v2.map((item, index) => {
      return `Testimonial # ${index}: {Testimonial: '${item?.testimonial}', Client Name: '${item?.clientName}', Client Title: '${item?.clientTitle}', clientCompanyName: '${item?.clientCompanyName}'}\n`;
    });

    const CustomMessage = document.querySelector(
      '#alert-dialog-description [name="CustomMessage"]'
    )?.value;
    const redirectLink = document.querySelector(
      '#alert-dialog-description [name="redirectLink"]'
    )?.value;

    const combinedText = `${dialogTitle}\n\n
    ${responsibilityContent ? `Responsibility: ${responsibilityContent}\n` : ""}
    ${fullName ? `Full Name: ${fullName}\n` : ""}
    ${agentTitle ? `Agent Title/Role: ${agentTitle}\n` : ""}
    ${agentLocation ? `Agent Location: ${agentLocation}\n` : ""}
    ${agentcompanyName ? `Company Name: ${agentcompanyName}\n` : ""}
    Target Audience\n
    ${locations ? `Locations: ${locations}\n` : ""}
    ${titles ? `Titles/Roles: ${titles}\n` : ""}
    ${department ? `Departments: ${department}\n` : ""}
    ${management ? `Management Levels: ${management}\n` : ""}
    ${changedjob ? `Changed Jobs Within: ${changedjob}\n` : ""}
    ${skills ? `Skills: ${skills}\n` : ""}
    ${experience ? `Year of experience: ${experience}\n` : ""}
    ${companyName ? `Comapny Name:${companyName}\n` : ""}
    ${employee ? `Number of employees:${employee}\n` : ""}
    ${minrevenue || maxfunding ? `Revenue:${minrevenue} - ${maxrevenue}\n` : ""}
    ${minfunding || maxfunding ? `Funding:${minfunding} - ${maxfunding}\n` : ""}
    ${industrynames ? `Industry :${industrynames}\n` : ""}
    ${siccodes ? `SIC Code:${siccodes}\n` : ""}
    ${naiccodes ? `NAICS Code:${naiccodes}\n` : ""}
    ${major ? `Major:${major}\n` : ""}
    ${school ? `School:${school}\n` : ""}
    ${degree ? `Degree:${degree}\n` : ""}
    ${keywords ? `Keywords:${keywords}\n` : ""}
    ${blockedIndustries ? `Exclude Industries:${blockedIndustries}\n` : ""}
    ${blockedDomains ? `Exclude Domains:${blockedDomains}\n` : ""}
    ${blockedKeywords ? `Exclude Keywords:${blockedKeywords}\n` : ""}
    ${blockedLocations ? `Exclude Locations:${blockedLocations}\n` : ""}
    AI Agent Training Data\n
    ${describecompany ? `Describe your Company:\n${describecompany}\n` : ""}
    ${productDesc ? `Describe Your Product/Service:\n${productDesc}\n` : ""}
    ${stories ? `Showcase Your Success Stories:\n${stories}\n` : ""}
    ${emailsubject ? `Email Subject Line Templates:\n${emailsubject}\n` : ""}
    ${emailSelectContent ? `Email Structure:\n${emailSelectContent}\n` : ""}
    ${emaildataContent ? `Email Content:\n ${emaildataContent}\n` : ""}
    ${companywebsite ? `Company Website:${companywebsite}\n` : ""}    
   ${testimonials
        ? ` Client Testimonials to increase your exposure to prospects\n ${testimonials}\n`
        : ""
      }
    ${CustomMessage
        ? `Traditional / Reply-Based Approach:${CustomMessage}\n`
        : ""
      }
    ${redirectLink ? `Redirect Link: ${redirectLink}\n ` : ""}   
    `;

    if (copyToClipboard(combinedText)) {
      toast.success("Copied to clipboard");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <Dialog
        fullScreen={dialog === "viewCampaignMailBoxes"}
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        //scroll="body"
        PaperProps={{
          sx: {
            minWidth: "700px",
          },
        }}
      >
        {dialog === "editCampaign" ? (
          <>
            <DialogTitle
              id="alert-dialog-title"
              style={{
                color: "#050C46 ",
                fontSize: "22px",
                marginBottom: "10px",
              }}
            >
              Update Agent Information
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ position: "absolute", right: 8, top: 4 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  color: "#FF5C1F",
                  mt: 3,
                  fontSize: "14px",
                  border: "1px solid #FF5C1F",
                  padding: "8px",
                  borderRadius: "12px",
                  backgroundColor: "#FFFCF5",
                }}
              >
                <WarningAmberRoundedIcon
                  style={{ marginLeft: "3px", color: "#FF5C1F" }}
                />
                Any changes will go into effect on the next business day*
              </Typography>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#050C46",
                      fontWeight: "500",
                    }}
                  >
                    Agent name
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter AI Agent name"
                    sx={{ my: 1 }}
                    fullWidth
                    value={temp3.title}
                    onChange={(e) => {
                      handleChange3("title", e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#050C46",
                      fontWeight: "500",
                    }}
                  >
                    Sender Name
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Name"
                    fullWidth
                    value={temp3.senderName}
                    onChange={(e) => {
                      handleChange3("senderName", e.target.value);
                    }}
                  />
                </Stack>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#050C46",
                      fontWeight: "500",
                    }}
                  >
                    Sender Title
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Title"
                    fullWidth
                    value={temp3.senderTitle}
                    onChange={(e) => {
                      handleChange3("senderTitle", e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#050C46",
                      fontWeight: "500",
                    }}
                  >
                    Company Name
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Company Name"
                    fullWidth
                    value={temp3.companyName}
                    onChange={(e) => {
                      handleChange3("companyName", e.target.value);
                    }}
                  />
                </Stack>
              </div>
              <Box
                spacing={2}
                sx={{
                  mt: 2,
                  border: "1px solid #ECE9F5",
                  padding: "10px",
                  backgroundColor: "#F8F7FC",
                  borderRadius: "12px",
                }}
              >
                {locFilter && (
                  <Box>
                    <Stack direction="row" alignItems="center">
                      <img src={SelectIcon} height="18px" width="18px" />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#050C46",
                          fontWeight: "700",
                          marginLeft: "5px",
                        }}
                      >
                        Select a location for your company
                      </Typography>
                    </Stack>
                    <Filter
                      key={locFilter.name}
                      name={locFilter.name}
                      vid={locFilter.id}
                      customVID="companyLocation"
                      type={locFilter.type}
                      state={temp3}
                      setState={setTemp3}
                      onChange={handleChange3}
                      singleSelect={true}
                    // render={getFilters[id]}
                    />
                  </Box>
                )}
                {/* <Stack direction="column" spacing={1} sx={{ mt: "10px" ,mb:'10px'}}>

                  <Typography sx={{ fontSize: "14px", color: "#050C46" }}>

                    Sharable Link
                  </Typography>
                  <Stack direction="row" >
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="link"
                    placeholder="https://www.example.com"
                    value={tempLinkObj.url}
                    onChange={(e) => {
                      onTempLinkObjChange("url", e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={LinkIcon}/>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControlLabel
                    value="top"
                    control={
                      <CustomSwitch
                        checked={
                          Boolean(tempLinkObj.enabled) &&
                          Boolean(tempLinkObj.url)
                        }
                        onChange={(e) =>
                          onTempLinkObjChange("enabled", e.target.checked)
                        }
                      />
                    }
                    label=""
                    labelPlacement="top"
                  />
                  </Stack>
                </Stack> */}
                <Typography
                  sx={{ fontSize: "14px", color: "#050C46", mt: "15px" }}
                >
                  Service/Product Description
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Description"
                  rows={4}
                  multiline
                  sx={{ my: 1, backgroundColor: "white" }}
                  name="jobTitle"
                  value={temp3.productDesc}
                  onChange={(e) => {
                    handleChange3("productDesc", e.target.value);
                  }}
                />
              </Box>
              <fieldset
                style={{
                  border: "1px solid #ECE9F5",
                  borderRadius: "12px",
                  backgroundColor: "#F8F7FC",
                  marginTop: "20px",
                }}
              >
                <Stack direction="row" alignItems="center">
                  <img
                    src={FilterIcon}
                    style={{ height: "16px", width: "16px" }}
                  />
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontSize: "16px",
                      fontWeight: "700",
                      marginLeft: "8px",
                    }}
                  >
                    Filters
                  </Typography>
                </Stack>

                <div id="select-industries">
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={INDUSTRY_OPTIONS}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          iconStyle={{ fill: "red" }}
                          sx={{
                            color: "#5862FF",
                            "&.Mui-checked": {
                              color: "#5862FF",
                            },
                          }}
                        />
                        {option}
                      </li>
                    )}
                    sx={{ width: "100%", mt: 1 }}
                    renderInput={(params) => (
                      <Stack spacing={1}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#050C46",
                            fontWeight: "500",
                          }}
                        >
                          Industries
                        </Typography>
                        <TextField
                          {...params}
                          placeholder="Enter Industries"
                          variant="outlined"
                          sx={{ backgroundColor: "white" }}
                        />
                      </Stack>
                    )}
                    disabled={tempStatus === 2}
                    value={newIndustry}
                    onChange={(event, newValue) => {
                      setNewIndustry([...newValue]);
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "#050C46",
                            color: "white",
                            "& .MuiChip-deleteIcon": {
                              backgroundColor: "transparent !important",
                              color: "white !important",
                              "&:hover": {
                                color: "white",
                              },
                            },
                          }}
                        />
                      ))
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      mb: 2,
                    }}
                  >
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 600,
                        mr: 0.5,
                        // mt: 1,
                        color: "#5761FE",
                        "&:hover": { color: "darkblue" },
                      }}
                      onClick={() => {
                        setNewIndustry(INDUSTRY_OPTIONS);
                      }}
                    >
                      Select all
                    </Button>
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 600,
                        mr: 0.5,
                        // mt: 1,
                        color: "blue",
                        "&:hover": { color: "red" },
                      }}
                      onClick={() => {
                        setNewIndustry([]);
                      }}
                    >
                      Clear all
                    </Button>
                  </Box>
                </div>
                {/* <Typography sx={{
                      fontSize: "14px",
                      color: "#050C46",
                      fontWeight: "500",
                    }}>Designation</Typography> */}
                <div id="blocked-industries">
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={DESIGNATION}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          sx={{
                            color: "#5862FF",
                            "&.Mui-checked": {
                              color: "#5862FF",
                            },
                          }}
                        />
                        {option}
                      </li>
                    )}
                    sx={{ width: "100%", mt: 1 }}
                    renderInput={(params) => (
                      <Stack spacing={1}>
                        <Typography sx={{ fontSize: "14px", color: "#050C46" }}>
                          Designation
                        </Typography>
                        <TextField
                          {...params}
                          placeholder="Designation"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  color="inherit"
                                  sx={{
                                    ml: 2,
                                    borderRadius: 1,
                                    backgroundColor: "#5761FE",
                                    padding: "8px 14px 8px 10px",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    if (!tag) {
                                      return;
                                    }

                                    handleTagAdd(tag);
                                    setTag("");
                                  }}
                                >
                                  <AddIcon />
                                  Add
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    )}
                    disabled={tempStatus === 2}
                    // value={tag}
                    onChange={(event) => setTag(event.target.value.split(","))}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "#050C46",
                            color: "white",
                            "& .MuiChip-deleteIcon": {
                              backgroundColor: "transparent !important",
                              color: "white !important",
                              "&:hover": {
                                color: "white",
                              },
                            },
                          }}
                        />
                      ))
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      mb: 2,
                    }}
                  >
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 600,
                        mr: 0.5,
                        // mt: 1,
                        color: "#5761FE",
                        "&:hover": { color: "darkblue" },
                      }}
                      onClick={() => {
                        setTag(DESIGNATION);
                      }}
                    >
                      Select all
                    </Button>
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 600,
                        mr: 0.5,
                        // mt: 1,
                        color: "blue",
                        "&:hover": { color: "red" },
                      }}
                      onClick={() => {
                        setNewBlockedIndustry([]);
                      }}
                    >
                      Clear all
                    </Button>
                  </Box>
                </div>

                {FILTERS.map(({ name, type, id, options }) => {
                  // if (selectedFilters.includes(id)) {
                  if (!Array.isArray(extraFilters[id])) {
                    onFiltersChange(id, []);
                  }
                  return (
                    <Box sx={{ mt: 3 }}>
                      <Filter
                        key={name}
                        name={name}
                        vid={id}
                        type={type}
                        options={options}
                        state={extraFilters}
                        setState={setExtraFilters}
                        onChange={onFiltersChange}
                      // render={getFilters[id]}
                      />
                    </Box>
                  );
                  // }
                })}

                <div id="blocked-industries">
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={INDUSTRY_OPTIONS}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          sx={{
                            color: "#5862FF",
                            "&.Mui-checked": {
                              color: "#5862FF",
                            },
                          }}
                        />
                        {option}
                      </li>
                    )}
                    sx={{ width: "100%", mt: 1 }}
                    renderInput={(params) => (
                      <Stack spacing={1}>
                        <Typography sx={{ fontSize: "14px", color: "#050C46" }}>
                          Exclude Industries
                        </Typography>
                        <TextField
                          {...params}
                          placeholder="Exclude Industries"
                          variant="outlined"
                        />
                      </Stack>
                    )}
                    disabled={tempStatus === 2}
                    value={newBlockedIndustry}
                    onChange={(event, newValue) => {
                      setNewBlockedIndustry([...newValue]);
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "#050C46",
                            color: "white",
                            "& .MuiChip-deleteIcon": {
                              backgroundColor: "transparent !important",
                              color: "white !important",
                              "&:hover": {
                                color: "white",
                              },
                            },
                          }}
                        />
                      ))
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      mb: 2,
                    }}
                  >
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 600,
                        mr: 0.5,
                        // mt: 1,
                        color: "#5761FE",
                        "&:hover": { color: "darkblue" },
                      }}
                      onClick={() => {
                        setNewBlockedIndustry(INDUSTRY_OPTIONS);
                      }}
                    >
                      Select all
                    </Button>
                    <Button
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 600,
                        mr: 0.5,
                        // mt: 1,
                        color: "blue",
                        "&:hover": { color: "red" },
                      }}
                      onClick={() => {
                        setNewBlockedIndustry([]);
                      }}
                    >
                      Clear all
                    </Button>
                  </Box>
                </div>
              </fieldset>
              <QuillEditor
                onChange={handleContentChange}
                placeholder="Business Description"
                sx={{ height: 400, my: 2 }}
                value={content}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleClose}
                fullWidth
                sx={{ border: "1px solid #ECE9F5", color: "black" }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                onClick={(e) => updateTargets()}
                loading={loading}
                autoFocus
                fullWidth
                sx={{ backgroundColor: "#5761FE", color: "white" }}
              >
                Update
              </LoadingButton>
            </DialogActions>
          </>
        ) : dialog === "viewCampaign" ? (
          <>
            <DialogTitle>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  id="alert-viewdialog-title"
                  sx={{ color: "#050C46", fontSize: "16px", fontWeight: "600" }}
                >
                  Agent Information
                </Typography>
                <Button onClick={handleCopy}>Copy</Button>
              </div>
            </DialogTitle>
            <DialogContent>
              <div ref={ref} id="alert-dialog-description">
                {responsibility && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      AI Agent Responsibility
                    </Typography>
                    <TextField
                      disabled
                      sx={{
                        mt: 1,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                      fullWidth
                      rows={8}
                      variant="outlined"
                      placeholder="AI Agent Responsibility"
                      name="responsibility"
                      value={responsibility}
                    />
                  </>
                )}
                <Typography
                  sx={{ color: "#050C46", fontSize: "14px", fontWeight: "500" }}
                >
                  Agent Full Name
                </Typography>
                <TextField
                  type="text"
                  disabled
                  name="agentName"
                  variant="outlined"
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#454861",
                    },
                  }}
                  fullWidth
                  value={title}
                />
                <Typography
                  sx={{ color: "#050C46", fontSize: "14px", fontWeight: "500" }}
                >
                  Agent Title/Role
                </Typography>
                <TextField
                  type="text"
                  disabled
                  variant="outlined"
                  name="agentTitle"
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#454861",
                    },
                  }}
                  fullWidth
                  value={senderTitle}
                />
                <Typography
                  sx={{ color: "#050C46", fontSize: "14px", fontWeight: "500" }}
                >
                  Agent Location
                </Typography>
                <TextField
                  type="text"
                  disabled
                  name="agentLocation"
                  variant="outlined"
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#454861",
                    },
                  }}
                  fullWidth
                  value={compLocStr}
                />
                <Typography
                  sx={{ color: "#050C46", fontSize: "14px", fontWeight: "500" }}
                >
                  Agent Company Name
                </Typography>
                <TextField
                  type="text"
                  disabled
                  name="agentcompanyName"
                  variant="outlined"
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#454861",
                    },
                  }}
                  fullWidth
                  value={companyName}
                />
                {/* <fieldset
                  style={{ border: "1px solid #9da2a8", borderRadius: "7px" }}
                >
                  <legend>
                    <Typography ></Typography>
                  </legend> */}

                {addFilters.location.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginTop: "15px",
                      }}
                    >
                      Locations
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      name="locations"
                      multiline
                      variant="outlined"
                      value={addFilters.location.join(", ")}
                      className="senderText"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                    />
                  </>
                )}
                <AdditionalFilters
                  additionalFilters={additionalFilters}
                  sicCode={sicCode}
                  naicsCode={naicsCode}
                  targets={targets}
                  industry={industry}
                  addFilters={addFilters}
                />

                {addFilters.keywords.length ||
                  addFilters?.blockedIndustries?.length ||
                  addFilters.blockedDomains.length ||
                  addFilters.blockedKeywords.length ||
                  addFilters.blockedLocations.length ? (
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontSize: "14px",
                      fontWeight: "500",
                      marginTop: "15px",
                    }}
                  >
                    Other
                  </Typography>
                ) : (
                  ""
                )}

                {addFilters.keywords.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Keywords
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      name="keywords"
                      multiline
                      variant="outlined"
                      value={addFilters.keywords.join(", ")}
                      sx={{
                        my: "10px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                    />
                  </>
                )}

                {Boolean(addFilters?.blockedIndustries?.length) && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Exclude Industries
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      name="blockedIndustries"
                      multiline
                      variant="outlined"
                      value={addFilters.blockedIndustries.join(", ")}
                      sx={{
                        my: "10px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                    />
                  </>
                )}

                {addFilters.blockedDomains.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Exclude Domains
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      name="blockedDomains"
                      multiline
                      variant="outlined"
                      value={addFilters.blockedDomains.join(", ")}
                      sx={{
                        my: "10px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                    />
                  </>
                )}

                {addFilters.blockedKeywords.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Exclude Keywords
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      name="blockedKeywords"
                      multiline
                      variant="outlined"
                      value={addFilters.blockedKeywords.join(", ")}
                      sx={{
                        my: "10px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                    />
                  </>
                )}

                {addFilters.blockedLocations.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Exclude Locations
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      name="blockedLocations"
                      multiline
                      variant="outlined"
                      value={addFilters.blockedLocations.join(", ")}
                      sx={{
                        my: "10px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                    />
                  </>
                )}

                {/* </fieldset> */}

                <fieldset
                  style={{
                    border: "1px solid #9da2a8",
                    borderRadius: "7px",
                    marginTop: "10px",
                  }}
                >
                  <legend>
                    <Typography>AI Agent Training Data</Typography>
                  </legend>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Describe your Company
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled
                      multiline
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                      name="describecompany"
                      placeholder="Company Description"
                      value={campaignDesc}
                    />
                  </Box>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Describe Your Product/Service
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      // name="blockedIndustries"
                      disabled
                      multiline
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                      name="productDesc"
                      placeholder="Details about your product or service you are campaigning for"
                      value={productDesc}
                    />
                  </Box>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Showcase Your Success Stories
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      // name="blockedIndustries"
                      multiline
                      disabled
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#454861",
                        },
                      }}
                      name="stories"
                      placeholder=""
                      value={testimonials}
                    />
                  </Box>

                  <TrainingData
                    prospectData={prospectData}
                    emailStructure={emailStructure}
                    companyData={companyData}
                    emailContent={emailContent}
                    emailSubject={emailSubject}
                  />
                  {companyWebsite && (
                    <>
                      <Typography
                        sx={{
                          color: "#050C46",
                          fontSize: "14px",
                          fontWeight: "400",
                          my: "10px",
                        }}
                      >
                        Company Website
                      </Typography>
                      <TextField
                        fullWidth
                        disabled
                        name="companywebsite"
                        variant="outlined"
                        sx={{
                          borderRadius: "8px",
                          mt: 1,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#454861",
                          },
                        }}
                        value={companyWebsite}
                      />
                    </>
                  )}
                  <div style={{ marginTop: "15px" }}>
                    {/* <Typography sx={{ fontWeight: "700" }}>Client Testimonials to increase your exposure to prospects</Typography> */}
                    {testimonials_v2 &&
                      testimonials_v2.length > 0 &&
                      testimonials_v2.map((item, index) => {
                        return (
                          <div key={index}>
                            {item.testimonial && (
                              <Typography
                                sx={{
                                  color: "#050C46",
                                  fontSize: "18px",
                                  fontWeight: "550",
                                  margin: "18px 0px 10px 0px ",
                                }}
                              >
                                Testimonial #{index + 1}
                              </Typography>
                            )}
                            <Stack flexDirection="column" spacing={2}>
                              {item.testimonial && (
                                <>
                                  <Typography
                                    sx={{
                                      color: "#050C46",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Testimonial
                                  </Typography>
                                  <TextField
                                    type="text"
                                    sx={{
                                      borderRadius: "8px",
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#454861",
                                      },
                                    }}
                                    fullWidth
                                    disabled
                                    variant="outlined"
                                    className="textFieldStyle"
                                    multiline
                                    name="testimonial"
                                    value={item.testimonial}
                                  />
                                </>
                              )}

                              {item.clientName && (
                                <>
                                  <Typography
                                    sx={{
                                      color: "#050C46",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Client Name
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    disabled
                                    variant="outlined"
                                    className="textFieldStyle"
                                    name="clientName"
                                    sx={{
                                      borderRadius: "8px",
                                      my: 1,
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#454861",
                                      },
                                    }}
                                    value={item.clientName}
                                  />
                                </>
                              )}
                              {item.clientTitle && (
                                <>
                                  <Typography
                                    sx={{
                                      color: "#050C46",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Client Title
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                    className="textFieldStyle"
                                    sx={{
                                      borderRadius: "8px",
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#454861",
                                      },
                                    }}
                                    value={item.clientTitle}
                                    name="clientTitle"
                                  />
                                </>
                              )}
                              {item.clientCompanyName && (
                                <>
                                  <Typography
                                    sx={{
                                      color: "#050C46",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Client Company name
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    className="textFieldStyle"
                                    disabled
                                    value={item.clientCompanyName}
                                    name="clientCompanyName"
                                    sx={{
                                      borderRadius: "8px",
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#454861",
                                      },
                                    }}
                                  />
                                </>
                              )}
                            </Stack>
                          </div>
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset
                  style={{ border: "1px solid #9da2a8", borderRadius: "7px" }}
                >
                  <legend>
                    <Typography variant="button">Video</Typography>
                  </legend>
                  <Typography>
                    {hippoVideoStatus.length
                      ? "Uploaded"
                      : videoUrl
                        ? "Uploaded"
                        : "Not Uploaded"}{" "}
                  </Typography>

                  <div
                    className="iframe-video"
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {videoUrl && (
                      <iframe
                        className="videoFrame"
                        src={videoUrl}
                        width={"362"}
                        height={"205"}
                        allowFullScreen
                        style={{ borderRadius: "10px", marginTop: "2px" }}
                      ></iframe>
                    )}
                  </div>
                </fieldset>
                {customMessage && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Traditional / Reply-Based Approach
                    </Typography>

                    <Stack spacing={2} {...other}>
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#454861",
                            },
                          }}
                          disabled
                          fullWidth
                          variant="outlined"
                          // className="textFieldStyle"
                          placeholder="This text will appear exactly as written in your emails"
                          rows={6}
                          multiline
                          name="CustomMessage"
                          value={customMessage}
                        />
                      </Box>
                    </Stack>
                  </>
                )}
                {link?.url && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Redirect Link
                    </Typography>
                    <Stack spacing={2} {...other}>
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          fullWidth
                          disabled
                          variant="outlined"
                          name="redirectLink"
                          // placeholder="Insert link here.."
                          value={link?.url}
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#ffffff",
                              p: 0.5,
                            },
                          }}
                        />
                      </Box>
                    </Stack>
                  </>
                )}
                {linkPlacement?.selectedText && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Link at the Beginning of the CTA
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        value={linkPlacement?.selectedText}
                        disabled
                        size="small"
                        displayEmpty
                        renderValue={(value) =>
                          value === ""
                            ? "Select"
                            : linkPlacement === "start"
                              ? `${value} _________`
                              : `_________ ${value}`
                        }
                        sx={{ backgroundColor: "#ffffff" }}
                      ></Select>
                    </FormControl>

                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      disabled
                      variant="outlined"
                      className="textFieldStyle"
                      placeholder="Enter Description"
                      rows={6}
                      multiline
                      name="CustomText"
                      value={linkPlacement.customText}
                    />
                  </>
                )}
                {/* filters */}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>

              {/* <LoadingButton
                onClick={(e) => updateTargets()}
                loading={loading}
                autoFocus
              >
                Update
              </LoadingButton> */}
            </DialogActions>
          </>
        ) : dialog === "viewCampaignMailBoxes" ? (
          <>
            {/* <DialogTitle id="alert-dialog-title">
              Campaign Mail Boxes Test
              <Typography
                Mail
                Boxeshy
                variant="body1"
                sx={{ display: "block", color: "red", mt: 1, fontSize: "19px" }}
              >
                <b>{_id}</b>
              </Typography>
            </DialogTitle> */}
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              {userType === "enterprise" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAddType("new");
                      handleClickOpen1();
                    }}
                  >
                    Add a New Account
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAddType("existing");
                      fetchAllMailBoxes(user?.email);
                      handleClickOpen1();
                    }}
                  >
                    Add An Existing Account
                  </Button>
                </Box>
              )}
            </DialogTitle>

            <DialogContent>
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  height: "100%",
                  ...scrollBarStyle,
                  border: `1px solid ${alpha("#000000", 0.1)}`,
                }}
              >
                {campMailBoxes?.length > 0 ? (
                  <Table
                    size="small"
                    aria-label="purchases"
                    stickyHeader
                    sx={{
                      //border: `1px solid ${alpha("#000000", 0.1)}`,
                      //backgroundColor: alpha("#5761FE", 0.1),
                      backgroundColor: "#F4F8FF",
                      borderRadius: 1,
                      borderCollapse: "separate",
                      "&::-webkit-scrollbar": {
                        width: "14px",
                      },
                      "&::-webkit-scrollbar-track": {
                        borderRadius: "60px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        //backgroundColor: "#E4E4E5",
                        backgroundColor: "#F4F8FF",
                        borderRadius: "10px",
                        border: "4px solid rgba(0, 0, 0, 0)",
                        backgroundClip: "padding-box",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#d5d5d5",
                      },
                    }}
                  >
                    <TableHead sx={{ borderRadius: 1 }}>
                      <TableRow sx={{ borderRadius: 1, whiteSpace: "nowrap" }}>
                        {columns.map((col) => (
                          <TableCell sx={{ borderRadius: 1 }}>{col}</TableCell>
                        ))}
                        <TableCell sx={{ color: "#808080 !important" }}>
                          View stats
                        </TableCell>
                        {userType === "enterprise" && (
                          <TableCell sx={{ color: "#414BB2 !important" }}>
                            Connect
                          </TableCell>
                        )}
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Status
                        </TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Warmup
                        </TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Health
                        </TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Week
                        </TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Email Sent
                        </TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Current Allowed
                        </TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Max Allowed
                        </TableCell>
                        <TableCell>Next Refresh</TableCell>
                        <TableCell sx={{ color: "#414BB2 !important" }}>
                          Ref.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campMailBoxes.map((mailbox, index) => (
                        <MailboxRow
                          index={index}
                          mailbox={mailbox}
                          userType={userType}
                          agentName={temp3.title}
                          setOpenMailStats={setOpenMailStats}
                          setMailBoxItem={setMailBoxItem}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "200px", height: "200px" }}>
                      <EmptyBox size="200px" />
                    </Box>
                    <Typography sx={{ fontSize: "30px", fontWeight: "600" }}>
                      You have no mailboxes
                    </Typography>
                  </Box>
                )}
              </TableContainer>

              {/* {campMailBoxes.map((item, index) => {
                // console.warn("item =====", item);
                let lastResetXHrsAgo =
                  (Date.now() - new Date(item.lastReset).getTime()) /
                  (1000 * 60 * 60);
                lastResetXHrsAgo = Math.ceil(lastResetXHrsAgo);
                return (
                  <Grid container columnSpacing={1} sx={{ width: "100%" }}>
                    <Tooltip title={"Emails sent!"} placement="top">
                      <Grid
                        item
                        xs={1}
                        style={{
                          marginTop: "8px",
                          marginBottom: "12px",
                          backgroundColor: "#5963ff",
                          borderRadius: "50%",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            display: "block",
                            color: "white",
                            mt: "12px",
                            mr: 1,
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenMailStats(true);
                            setMailBoxItem(item);
                          }}
                        >
                          {item.emailsSent}
                       
                        </Typography>
                      </Grid>
                    </Tooltip>

                    <Grid item xs={11}>
                      <TextField
                        // helperText={`Last reset: ${lastResetXHrsAgo} hours ago`}
                        type="text"
                        label={"SenderEmail" + (index + 1)}
                        sx={{ my: 1 }}
                        fullWidth
                        value={item.senderEmail}
                        onChange={(e) => {
                          handleChange3("title", e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })} */}
            </DialogContent>

            <Dialog
              open={openMailStats}
              onClose={() => {
                setOpenMailStats(false);
                setMailBoxItem(null);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title" >
                Agent Mail Box Stats
                <Typography
                  Mail
                  Boxeshy
                  variant="body1"
                  sx={{
                    display: "block",
                    color: "red",
                    mt: 1,
                    fontSize: "19px",
                  }}
                >
               
                </Typography>
              </DialogTitle> */}
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Typography variant="h6">Agent Mail Box Stats </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  sx={{ p: 0 }}
                  onClick={() => {
                    setOpenMailStats(false);
                    setMailBoxItem(null);
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ p: 2 }}>
                {mailBoxItem && (
                  <Stack
                    sx={{
                      backgroundColor: "#F4F8FF",
                      borderRadius: 1,
                      p: 1,
                      border: `1px solid ${alpha("#000000", 0.1)}`,
                    }}
                    rowGap={1}
                  >
                    {/* <Typography>
                      Sender Email: <b>{mailBoxItem.senderEmail}</b>
                    </Typography>
                    <Typography>
                      Week: <b>{mailBoxItem.inWeek}</b>
                    </Typography>
                    <Typography>
                      Emails Sent: <b>{mailBoxItem.emailsSent}</b>
                    </Typography>
                    <Typography>
                      Max Allowed: <b>{mailBoxItem.maxAllowed}</b>
                    </Typography>
                    <Typography>
                      Last Reset: <b>{formatDate(mailBoxItem.lastReset)}</b>
                    </Typography> */}
                    {[
                      { label: "Sender Email", value: mailBoxItem.senderEmail },
                      { label: "Week", value: mailBoxItem.inWeek },
                      { label: "Emails Sent", value: mailBoxItem.emailsSent },
                      { label: "Max Allowed", value: mailBoxItem.maxAllowed },
                      {
                        label: "Last Reset",
                        value: formatDate(mailBoxItem.lastReset),
                      },
                    ].map((item, index) => (
                      <Stack
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            width: "105px",
                            minWidth: "105px",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {item.label}
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>:</Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {item.value}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
              </DialogContent>
            </Dialog>
            <Dialog open={open1} onClose={handleClose1} maxWidth="xs" fullWidth>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Connect Your Mailbox </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose1}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {addType === "new" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Stack
                      sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Button
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                          width: { xs: "100px", sm: "auto" },
                          height: { xs: "100px", sm: "auto" },
                        }}
                        onClick={() => {
                          handleConnectGoogleAccount(_id);
                        }}
                      >
                        <GmailIcon />
                      </Button>

                      <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Google Workspace
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Button
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                          width: { xs: "100px", sm: "auto" },
                          height: { xs: "100px", sm: "auto" },
                          // height: "144px",
                          // width:'144px'
                        }}
                        onClick={() => {
                          handleConnectMicrosoftAccount(_id);
                        }}
                      >
                        <MicrosoftIcon />
                      </Button>

                      <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Microsoft
                      </Typography>
                    </Stack>
                  </Box>
                ) : addType === "existing" ? (
                  <>
                    <MailboxSelect
                      selectedAgent={senderName}
                      // setSelectedAgent={setSelectedAgent}
                      onClose={handleClose1}
                      mailBoxList={mailboxList}
                      setMailBoxList={setMailboxList}
                      assignedUnAssinedMailbox={assignedUnAssinedMailbox}
                      isFetching={isFetching}
                    />
                  </>
                ) : (
                  <></>
                )}
              </DialogContent>
            </Dialog>
          </>
        ) : null}
      </Dialog>

      <Dialog
        open={openCalenderDialog}
        onClose={handleCalenderDialogClose}
        fullWidth
        maxWidth="md"
      >
        {/*{*/}
        {/*  openCalenderDialog && <CalenderView*/}
        {/*    user={user}*/}
        {/*    id={_id}*/}
        {/*    extra={extra}*/}
        {/*    title={title}*/}
        {/*  />*/}
        {/*}*/}
      </Dialog>

      <TableRow
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <CTableCell
          align="center"
          sx={{
            borderRight: "2px solid #f1f4f7",
            position: "relative",
          }}
        >
          {/* <Typography variant="button">{user.organisation}</Typography> */}
          {isSaveAsDraft && <Chip label="Draft" color="error" />}
          <Typography
            variant="button"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "14px",
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {/* {desiredPart} */}
            {companyName}
            <ThemeProvider theme={theme}>
              <Tooltip title={userOrgName} placement="top">
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                    color: "#7E82A5",
                    "&:hover": {
                      color: "#5761FE",
                    },
                  }}
                  onClick={async () => {
                    if (await copyToClipboard(_id)) {
                      toast.success("Copied to clipboard");
                    } else {
                      toast.error("Something went wrong");
                    }
                  }}
                />
              </Tooltip>
            </ThemeProvider>
          </Typography>

          {/* <br /> */}
          {/* <Tooltip title={`Copy Agent ID`}>
            <Typography
              variant="caption"
              noWrap
              sx={{ color: "blue", cursor: "pointer" }}
              onClick={async () => {
                if (await copyToClipboard(_id)) {
                  toast.success("Copied to clipboard");
                } else {
                  toast.error("Something went wrong");
                }
              }}
            >
              {_id}
            </Typography>
          </Tooltip> */}
        </CTableCell>

        <CTableCell align="center">
          {/* <Tooltip
            title={
              <>
                <Stack>
                  {campMailBoxes?.map(({ senderEmail: email, emailsSent }) => (
                    <Typography sx={{ p: 0.5 }} variant="caption">
                      {emailsSent} - {email}
                    </Typography>
                  ))}
                </Stack>
              </>
            }
            // ${enterDelay}="1200"
            arrow
            placement="top"
          > */}
          <IconButton onClick={() => handleOpen("viewCampaignMailBoxes")}>
            <Typography variant="body1">
              {campMailBoxes ? campMailBoxes.length : "#"}
            </Typography>
          </IconButton>
          {/* </Tooltip> */}
        </CTableCell>

        <CTableCell align="center">
          <Typography
            variant="button"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "14px",
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {temp3.title.length <= 5
              ? temp3.title
              : temp3.title.slice(0, 5) + "..." || "-"}

            {temp3.title.length > 5 && (
              <ThemeProvider theme={theme}>
                <Tooltip title={temp3.title} placement="top">
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{
                      cursor: "pointer",
                      color: "#7E82A5",
                      "&:hover": {
                        color: "#5761FE",
                      },
                    }}
                    onClick={async () => {
                      if (await copyToClipboard(_id)) {
                        toast.success("Copied to clipboard");
                      } else {
                        toast.error("Something went wrong");
                      }
                    }}
                  />
                </Tooltip>
              </ThemeProvider>
            )}
          </Typography>
        </CTableCell>

        <CTableCell align="center" sx={{ display: "none" }}>
          {/* {conversations ? "Lorem Ipsum" : "NA"} */}
          {/* <Typography
            variant="caption"
            noWrap
            sx={{ color: "blue", cursor: "pointer" }}
            onClick={(e) => {
              navigate(`${paths.dashboard.conversations}?campaignId=${_id}`);
            }}
          >
            Conversations
          </Typography> */}
          <IconButton
            // variant="caption"
            // noWrap
            // variant="outlined"
            sx={{ color: "#9E77ED" }}
            onClick={(e) => {
              navigate(`${paths.dashboard.conversations}?campaignId=${_id}`);
            }}
          >
            <ExitToApp fontSize="small" sx={{ ml: 1 }} />
          </IconButton>
        </CTableCell>

        <CTableCell align="center">
          <Stack
            sx={{
              // m: 1,
              p: 2,
              bgcolor: "#f9f9f9",
              borderRadius: 1,
              // width: "225px"
            }}
            alignItems="center"
            spacing={1}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                bgcolor: "#F8F7FC",
              }}
            >
              <Typography variant="subtitle2">{responsibility}</Typography>
              <Typography
                variant="caption"
                sx={{ borderBottom: "1px solid gray", width: "100%", py: 1 }}
              >
                {/* {tempTargets.join(", ")} */}
                {tempTargets
                  .slice(0, numberOfFieldsToBeShownTempTargets)
                  .join(", ")}
                {tempTargets.length > 10 &&
                  numberOfFieldsToBeShownTempTargets < tempTargets.length && (
                    <>
                      {" "}
                      and{" "}
                      <Tooltip placement="bottom" title="Show more" arrow>
                        <span
                          onClick={incrementBy10TempTargets}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            // "&:hover": {
                            //   color: "blue",
                            // },
                          }}
                        >
                          {tempTargets.length -
                            numberOfFieldsToBeShownTempTargets}{" "}
                          more...
                        </span>
                      </Tooltip>
                    </>
                  )}
              </Typography>
              <Typography variant="caption" sx={{ py: 1 }}>
                {/* {tempIndustry.join(", ")} */}
                {tempIndustry
                  .slice(0, numberOfFieldsToBeShownTempIndustry)
                  .join(", ")}
                {tempIndustry.length > 10 &&
                  numberOfFieldsToBeShownTempIndustry < tempIndustry.length && (
                    <>
                      {" "}
                      and{" "}
                      <Tooltip placement="bottom" title="Show more" arrow>
                        <span
                          onClick={incrementBy10TempIndustry}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            // "&:hover": {
                            //   color: "blue",
                            // },
                          }}
                        >
                          {tempIndustry.length -
                            numberOfFieldsToBeShownTempIndustry}{" "}
                          more...
                        </span>
                      </Tooltip>
                    </>
                  )}
              </Typography>
            </Box>
            {/* <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Button variant="outlined" color="success" size="small" sx={{}}>
                View
              </Button>
              <Button
                variant="outlined"
                color="info"
                size="small"
                onClick={handleOpen}
              >
                Edit
              </Button>
            </Stack> */}
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                {" "}
                <Button
                  // disabled={true} // comment it
                  fullWidth
                  variant="outlined"
                  size="small"
                  onClick={() => handleEditCampaign()}
                  // onClick={() => handleOpen("editCampaign")}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #E6E7ED ",
                    color: "#050C46",
                    "&:hover": {
                      border: "1px solid #050C46",
                    },
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={6}>
                {" "}
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  // onClick={() => handleEditCampaign()}
                  onClick={() => handleOpen("viewCampaign")}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "#5761FE  ",
                    "&:hover": {
                      backgroundColor: "#353FEA",
                    },
                  }}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </CTableCell>

        <CTableCell align="center">
          {/*<IconButton disabled={true}>*/}
          <IconButton
            onClick={() => {
              navigate(
                `${paths.dashboard.account}?campaign_id=${_id}&title=${title}`,
                { state: { extra } }
              );
              // setOpenCalenderDialog(true)
            }}
          >
            {" "}
            <img src={calendar} />
            {/* <CalendarMonth sx={{ color: "" }} /> */}
          </IconButton>
        </CTableCell>

        <CTableCell align="center">
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Stack
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Tooltip
                title="View leads"
                // enterDelay="1200"
                arrow
                placement="top"
              >
                <IconButton
                  size="small"
                  color="success"
                  // sx={{ bgcolor: "#ecebec" }}
                  onClick={() => {
                    navigate(linkToLeads);
                  }}
                  sx={{
                    bgcolor: "#ecebec",
                    borderRadius: "6px",
                    margin: "10px",
                  }}
                >
                  <img src={Users} style={{ margin: "5px" }} />
                </IconButton>
              </Tooltip> */}
              <Tooltip
                title={tempPaused ? "Start Campaign" : "Stop Campaign"}
                enterDelay={1200}
                arrow
              >
                <IconButton
                  size="small"
                  color={tempPaused ? "warning" : "error"}
                  // sx={{ bgcolor: "#ecebec" }}
                  disabled={
                    isSaveAsDraft || campStatus || pauseLoading
                    // !Boolean(campMailBoxes?.length)
                  }
                  onClick={() => {
                    togglePaused(_id);
                  }}
                >
                  {tempPaused || campStatus ? (
                    <img src={PlayArrowIcon} />
                  ) : (
                    <PauseIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Conversations" arrow>
                <IconButton
                  // variant="caption"
                  // noWrap
                  // variant="outlined"
                  sx={{
                    color: "#9E77ED",
                    pointerEvents: isSaveAsDraft && "none",
                  }}
                  onClick={(e) => {
                    navigate(
                      `${paths.dashboard.conversations}?campaignId=${_id}`
                    );
                  }}
                  disabled={isSaveAsDraft}
                >
                  <img src={ThreeP} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy AI agent" arrow>
                <IconButton onClick={handleCopyCampaign}>
                  <img src={CopyAll} style={{ margin: "5px" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>

          <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => {
              navigate(linkToLeads);
            }}
            sx={{
              mb: "5px",
              px: 1.3,
              fontSize: "12px",
              borderRadius: "8px",
              border: "1px dashed rgba(5, 12, 70, 0.20)",
              color: "#050C46",
              "&:hover": {
                border: "1px dashed #5761FE",
                backgroundColor: "#5761FE",
                color: "white",
              },
            }}
            disabled={isSaveAsDraft}
          >
            <img src={Users} style={{ marginRight: "5px" }} />
            View Stats
          </Button>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={async () => {
              if (await copyToClipboard(salesAgentURL)) {
                toast.success("Copied to clipboard");
              } else {
                toast.error("Something went wrong");
              }
            }}
            sx={{
              px: 1.3,
              fontSize: "12px",
              borderRadius: "8px",
              border: "1px dashed rgba(5, 12, 70, 0.20)",
              color: "#050C46",
              "&:hover": {
                border: "1px dashed #5761FE",
                backgroundColor: "#5761FE",
                color: "white",
              },
            }}
            disabled={isSaveAsDraft}
          >
            <ContentCopy
              fontSize="small"
              sx={{
                ml: 0.5,
                width: "14px",
              }}
            />
            Public Chat Link
          </Button>
        </CTableCell>

        <CTableCell align="center">
          <Stack spacing={1} divider={<Divider />}>
            <Tooltip
              title={`${leadsCount ? leadsCount : 0} leads generated!`}
              placement="top"
              arrow
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="column"
                spacing={0.5}
              >
                {tempStatus === 2 ? (
                  <SvgIcon
                    sx={{
                      color: "background.paper",
                      "& path": {
                        fill: (theme) => theme.palette.success.main,
                        fillOpacity: 1,
                      },
                      height: "36px",
                      width: "auto",
                    }}
                  >
                    <CheckVerified01 />
                  </SvgIcon>
                ) : tempStatus === 1 ? (
                  <CircularProgressWithLabel
                    value={isNaN(progress) ? 0 : progress}
                  />
                ) : null}
                <Stack>
                  <Typography color="success" noWrap variant="overline">
                    {tempStatus === 2
                      ? "Data Ready"
                      : tempStatus === 1
                        ? "Generating Leads"
                        : tempStatus === 0
                          ? "Process Halted"
                          : null}
                  </Typography>
                  <Typography variant="caption">
                    {tempStatus === 2
                      ? null
                      : tempStatus === 1
                        ? null
                        : tempStatus === 0
                          ? "Not enough leads for applied filters"
                          : null}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="caption"
                  >
                    {/* Powered by:{" "} */}
                    {/* <Box
                      sx={{
                        // backgroundColor: "#FACE3F",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: "3px",
                        borderRadius: "3px",
                      }}
                    >
                      <img
                        src="/assets/rocketreach.png"
                        style={{ width: "24px" }}
                      />
                    </Box>{" "} */}
                    {/* RocketReach */}
                  </Typography>
                </Stack>
              </Stack>
            </Tooltip>

            <Tooltip title={`${emailStatus} emails sent !`} arrow>
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={0.5}
                backgroundColor="#F8F7FC"
              >
                {campStatus ? (
                  <SvgIcon
                    sx={{
                      color: "background.paper",
                      "& path": {
                        fill: (theme) => theme.palette.success.main,
                        fillOpacity: 1,
                      },
                      height: "36px",
                      width: "auto",
                    }}
                  >
                    <CheckVerified01 />
                  </SvgIcon>
                ) : (
                  <CircularProgressWithLabel
                    color={tempPaused ? "warning" : "primary"}
                    value={
                      isNaN(emailStatus)
                        ? 0
                        : parseInt((emailStatus * 100) / MIN_LEADS)
                    }
                  />
                )}
                <Typography color="success" noWrap variant="overline">
                  {campStatus
                    ? "Email Send"
                    : tempPaused
                      ? "Emails Paused"
                      : "Sending Emails"}
                </Typography>
              </Stack>
            </Tooltip>
          </Stack>
        </CTableCell>
      </TableRow>
      <CampaignRowCard
        key={props.campaign._id}
        campaignData={props.campaign}
        socket={socket}
        emailSocket={emailSocket}
        user={user}
        handleOpen={handleOpen}
        handleEditCampaig={handleEditCampaign}
        // remaining={remCampCount}
        isSaveAsDraft={false}
        salesAgentURL={salesAgentURL}
        navigate={navigate}
        linkToLeads={linkToLeads}
        togglePaused={togglePaused}
        tempPaused={tempPaused}
        emailStatus={emailStatus}
      />
    </>
  );
};

CampaignRow.propTypes = {
  campaign: PropTypes.object.isRequired,
};
