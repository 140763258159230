import Quill from 'react-quill';
import { styled } from '@mui/material/styles';

// For some unknown reason, styling Quill directly throws an error.

const Editor = (props) => <Quill {...props} />;

export const QuillEditor = styled(Editor)(({ theme }) => ({
  border: 1,
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  '& .quill': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden'
  },
  '& .ql-snow.ql-toolbar': {
    borderColor: theme.palette.divider,
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    color: '#050C46',
    '& .ql-picker-label:hover': {
      color: '#050C46'
    },
    '&.ql-picker-label' :{
      border: '1px solid #ECE9F5'
  },
    '& .ql-picker-label.ql-active': {
      border: '1px solid red',
      color: '#050C46'
    },
    '& .ql-picker-item:hover': {
      color: '#050C46'
    },
    '& .ql-picker-item.ql-selected': {
      color: '#050C46'
    },
    '& button:hover': {
      color: '#050C46',
      '& .ql-stroke': {
        stroke: '#050C46'
      }
    },
    '& button:focus': {
      color: '#050C46',
      '& .ql-stroke': {
        stroke: '#050C46'
      }
    },
    '& button.ql-active': {
      '& .ql-stroke': {
        stroke: '#050C46'
      }
    },
    '& .ql-stroke': {
      stroke: '#050C46'
    },
    '& .ql-picker': {
      color: '#050C46'
    },
    '& .ql-picker-options': {
      backgroundColor: '#FFFFFF',
      border: 'none',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[10],
      padding: theme.spacing(2)
    },
    
  },
  '& .ql-toolbar .ql-snow .ql-picker-label .ql-image'  :{
    border: '1px solid #ECE9F5'
},
  '& .ql-snow.ql-container': {
    borderBottom: 'none',
    borderColor: theme.palette.divider,
    borderLeft: 'none',
    borderRight: 'none',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'auto',
    overflow: 'hidden',
    '& .ql-editor': {
      color: '#050C46',
      backgroundColor:'#FFFFFF',
      flex: 1,
      fontFamily: theme.typography.body1.fontFamily,
      fontSize:'14px',
      height: 'auto',
      overflowY: 'auto',
      padding: theme.spacing(2),
      '&.ql-blank::before': {
        color: '#050C46',
        fontStyle: 'normal',
        left: theme.spacing(2)
      }
    }
  },
 '& .ql-toolbar.ql-snow .ql-picker-label .ql-image': {
    border: '1px solid #ECE9F5',
    borderRadius:'8px',
},
'& .ql-toolbar.ql-snow .ql-formats .ql-image': {
  border: '1px solid #ECE9F5',
  marginRight:'0px',
  // borderRight:'none',
    borderRadius:'8px',
}
}));
