import React from "react";
import { Box } from "@mui/material";

const BouncingDotsLoader = (props) => {
  const keyframesStyle = `
    @keyframes bouncing-loader {
      to {
        opacity: 0.1;
        transform: translateY(-8px);
      }
    }
  `;
  return (
    <Box sx={{ display: "flex", justifyContent: "start" }} id={"bouncing_loader"}>
      <Box
        sx={{
          width: "10px",
          height: "10px",
          m: "2px",
          mt: "6px",
          borderRadius: "50%",
          backgroundColor: "#050C46",
          opacity: 1,
          animation: "bouncing-loader 0.6s infinite alternate",
        }}
      ></Box>
      <Box
        sx={{
          width: "10px",
          height: "10px",
          m: "2px",
          mt: "6px",
          borderRadius: "50%",
          backgroundColor: "#050C46",
          opacity: 1,
          animation: "bouncing-loader 0.6s infinite alternate",
          animationDelay: "0.2s",
        }}
      ></Box>
      <Box
        sx={{
          width: "10px",
          height: "10px",
          m: "2px",
          mt: "6px",
          borderRadius: "50%",
          backgroundColor: "#050C46",
          opacity: 1,
          animation: "bouncing-loader 0.6s infinite alternate",
          animationDelay: "0.4s",
        }}
      ></Box>
      <style>{keyframesStyle}</style>
    </Box>
  );
};

export default BouncingDotsLoader;
