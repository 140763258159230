import { Stack, TextField } from "@mui/material";
import { number } from "prop-types";
import { useEffect, useState } from "react";

export const ApolloCustomCompSize = ({
  handleCustomSize,
  fields,
  setFields,
  customCompSize,
  onChange,
  vid,
}) => {
  const [minSize, setMinSize] = useState(
    customCompSize?.customCompSize?.min === 0
      ? ""
      : customCompSize?.customCompSize?.min
  );
  const [maxSize, setMaxSize] = useState(
    customCompSize?.customCompSize?.max === 0
      ? ""
      : customCompSize?.customCompSize?.max
  );
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");

  const handleEnter = () => {
    // console.log("initialllllll: ", minSize, maxSize);
    const isMinNumber = /^\d+$/.test(minSize);
    const isMaxNumber = /^\d+$/.test(maxSize);
    let newSize = { min: 0, max: 0 };

    if (isMinNumber || isMaxNumber) {
      if (minSize && maxSize) {
        if (Number(minSize) < Number(maxSize)) {
          newSize.min = Number(minSize);
          newSize.max = Number(maxSize);
          setMinError("");
          setMaxError("");
          setFields((pre) => ({
            ...pre,
            customCompSize: newSize,
          }));
          return;
        } else {
          setMaxError("Max cannot be less than Min");
          setMinError("");
        }
      } else {
        if (minSize === "" || minSize) {
          newSize.min = minSize === "" ? "" : Number(minSize);
          // newSize.min = Number(minSize);
          const isLessThanValue = customCompSize.compSize.some((item) => {
            const start = item.split(",")[0];
            return Number(minSize) < Number(start);
          });

          if (isLessThanValue && !maxSize) {
            setMinError("Add Appropriate value ");
          } else {
            setMinError("");
          }
        }
        if (maxSize === "" || maxSize) {
          newSize.max = maxSize === "" ? "" : Number(maxSize);
        }
        // setMinError("");
        // setMaxError("");
        setFields((pre) => ({
          ...pre,
          customCompSize: newSize,
        }));
        return;
      }
    } else {
      if (minSize === "" || minSize) {
        newSize.min = minSize === "" ? "" : Number(minSize);
        // newSize.min = Number(minSize);
      }
      if (maxSize === "" || maxSize) {
        newSize.max = maxSize === "" ? "" : Number(maxSize);
      }
      // console.log("newSize: :: new", newSize);
      setMinError("");
      setMaxError("");
      setFields((pre) => ({
        ...pre,
        customCompSize: newSize,
      }));
      return;
    }
    // else {
    //   if (minSize === "" && maxSize === "") {
    //     setMaxError("Max Value is required");
    //     setMinError("Min Value is required");
    //     return;
    //   } else {
    //     if (maxSize === "") {
    //       setMinError("");
    //       setMaxError("Max Value is required");
    //     }

    //     if (minSize === "") {
    //       setMinError("Min Value is required");
    //       setMaxError("");
    //     }
    //   }

    //   // if (!isMaxNumber && !isMinNumber) {
    //   //   setMinError("Invalid input");
    //   //   setMaxError("Invalid input");
    //   //   return;
    //   // } else {
    //   //   if (!isMaxNumber) {
    //   //     setMinError("");
    //   //     setMaxError("Invalid input");
    //   //   }
    //   //   if (!isMinNumber) {
    //   //     setMinError("Invalid input");
    //   //     setMaxError("");
    //   //   }
    //   // }
    // }
  };

  // useEffect(() => {
  //   setMinSize(
  //     customCompSize?.customCompSize?.min === 0
  //       ? ""
  //       : customCompSize?.customCompSize?.min
  //   );
  //   setMaxSize(
  //     customCompSize?.customCompSize?.max === 0
  //       ? ""
  //       : customCompSize?.customCompSize?.max
  //   );
  // }, [customCompSize]);

  useEffect(() => {
    handleEnter();
    onChange(vid, []);
  }, [minSize, maxSize]);

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        mt: 0.8,
        p: 1,
      }}
    >
      <Stack sx={{ width: "48%" }}>
        <TextField
          className="custom-input"
          type="number"
          variant="outlined"
          value={minSize}
          size="small"
          error={!!minError}
          helperText={minError}
          onChange={(e) => setMinSize(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEnter();
            }
          }}
          placeholder="Min"
          inputProps={
            {
              // step: 10,
              // min: 1,
              // max: 1000000000,
              // type: "number",
            }
          }
          sx={{
            pt: 0.5,
            input: {
              // paddingLeft: "80px",
            },
          }}
        />
      </Stack>
      <Stack sx={{ width: "48%" }}>
        <TextField
          type="number"
          className="custom-input"
          variant="outlined"
          value={maxSize}
          size="small"
          placeholder="Max"
          error={!!maxError}
          helperText={maxError}
          // onChange={(e) => handleCustomSize(e, "max")}
          onChange={(e) => setMaxSize(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // handleCustomSize(e, "max");
              handleEnter();
            }
          }}
          inputProps={
            {
              // step: 10,
              // min: 1,
              // max: 1000000000,
              // type: "number",
            }
          }
          sx={{
            pt: 0.5,
            input: {
              // paddingLeft: "80px",
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
