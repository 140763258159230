import { Autocomplete, Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Radio, Select, Stack, TextField, Typography, alpha, styled } from "@mui/material";
import { CREATIVENESS, LENGTH, TONE, CONNECTING, OPENING, BODY, companyOptionData, prospectOption, LANGUAGE, emailDataStructure, emailContentData } from "src/data/campaign";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TrainingData = ({ prospectData, companyData, emailStructure, emailContent, emailSubject }) => {
    const Icon = styled("span")(({ theme }) => ({
        borderRadius: 4,
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === "dark"
                ? "0 0 0 1px rgb(16 22 26 / 40%)"
                : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
        backgroundImage:
            theme.palette.mode === "dark"
                ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
                : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        ".Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background:
                theme.palette.mode === "dark"
                    ? "rgba(57,75,89,.5)"
                    : "rgba(206,217,224,.5)",
        },
    }));

    const CheckedIcon = styled(Icon)({
        backgroundColor: "#5761FE",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&::before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    });

    const callToActionModels = [
        { label: "Personalized Video + AI Chat Agent", value: "personlizedVideo" },
        { label: "AI Chat Agent", value: "chatAgentOnly" },
        { label: "Traditional / Reply-Based Approach", value: "customNoLink" },
        { label: "Redirect Link", value: "customLinkable" },
    ];

    const EmailSelect = ({ options, data, name }) => {

        return (
            <>
                {options.map((option) => (
                    <Stack rowGap={1} style={{ marginTop: '15px' }}>
                        <Typography sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}>{option.title}</Typography>
                        <TextField
                            disabled
                            variant="outlined"
                            value={data[option.id]}
                            fullWidth
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#454861",
                                }
                            }} />
                    </Stack>
                ))}
            </>
        );
    };

    return (
        <>
            <div style={{ marginTop: '10px' }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>Copywriting Enrichment Data</Typography>
                {prospectData && <div style={{ marginTop: '10px' }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Prospect Data
                    </Typography>

                    <Stack rowGap={1} sx={{ mt: 2 }}>
                        {prospectOption.map((option) => (
                            <>
                                {prospectData[option?.key] === true && <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: 0.5,
                                        px: 1,
                                        py: 0.5,
                                        border: `1px solid ${alpha("#050C46", 0.1)}`,
                                        borderRadius: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{ fontSize: "14px", fontWeight: "400" }}
                                    >
                                        {option.label}
                                    </Typography>
                                </Box>}
                            </>
                        ))}
                    </Stack>
                </div>}
                {companyData && <div style={{ marginTop: '10px' }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#050C46" }}>
                        Company Data
                    </Typography>

                    <Stack rowGap={1} sx={{ mt: 2 }}>
                        {companyOptionData.map((option) => (
                            <>
                                {companyData[option.key] === true &&
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: 0.5,
                                            px: 1,
                                            py: 0.5,
                                            border: `1px solid ${alpha("#050C46", 0.1)}`,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: "14px", fontWeight: "400", color: "#050C46" }}
                                        >
                                            {option.label}
                                        </Typography>
                                    </Box>}
                            </>
                        ))}
                    </Stack >
                </div>}
            </div>
            {emailSubject?.length !== 0 &&
                <div style={{ margin: '15px 0px' }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "700", mb: "15px" }} >Email Subject Instructions</Typography>
                    <>
                        <Typography sx={{ color: "#050C46", fontSize: "14px", fontWeight: "400" }}>Email Subject Line Templates</Typography>
                        <TextField
                            disabled
                            fullWidth
                            name="emailsubject"
                            multiline
                            variant="outlined"
                            value={emailSubject?.join(", ")}
                            sx={{
                                my: '10px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#454861",
                                }
                            }}
                        />
                    </>

                </div>}

            {emailStructure &&
                <div style={{ marginTop: '15px' }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>Email Structure</Typography>
                    <EmailSelect
                        title={"Email Structure"}
                        options={emailDataStructure}
                        data={emailStructure}
                        id="emailStructure"
                        name='emailStructure'
                    />
                </div>}

            {emailContent &&
                <div style={{ marginTop: '15px' }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>Email Content</Typography>
                    <EmailSelect
                        title={"Email Structure"}
                        options={emailContentData}
                        data={emailContent}
                        id="emailStructure"
                        name='emailStructure'
                    />
                </div>}
        </>
    );
};

export default TrainingData;
