import React from 'react'

const PipedriveLogo = ({style={}}) => {
  return (
    <svg height="32" viewBox="0 0 121 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4673 4.66455C21.7516 4.66455 22.7928 3.6241 22.7928 2.34064C22.7928 1.05718 21.7516 0.0167236 20.4673 0.0167236C19.1829 0.0167236 18.1417 1.05718 18.1417 2.34064C18.1417 3.6241 19.1829 4.66455 20.4673 4.66455Z"></path>
        <path d="M22.5555 5.82849H18.3803V20.1432H22.5555V5.82849Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M87.6574 4.66455C88.9418 4.66455 89.983 3.6241 89.983 2.34064C89.983 1.05718 88.9418 0.0167236 87.6574 0.0167236C86.3731 0.0167236 85.3319 1.05718 85.3319 2.34064C85.3319 3.6241 86.3731 4.66455 87.6574 4.66455Z"></path>
        <path d="M89.7456 5.82849H85.5705V20.1432H89.7456V5.82849Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M120.108 12.514C120.108 8.42815 116.848 5.51392 112.931 5.51392C108.528 5.51392 105.325 8.57122 105.325 12.9716C105.325 17.3152 108.328 20.4292 112.931 20.4292C116.848 20.4292 119.45 18.0009 119.936 15.3148H115.904C115.446 16.4865 114.102 17.1438 112.845 17.1438C111.158 17.1154 109.9 16.1152 109.529 13.9434H120.079L120.108 12.514ZM109.614 11.3424C110.072 9.22857 111.644 8.71429 112.93 8.71429C114.246 8.71429 115.79 9.37164 115.989 11.3424H109.614Z"></path>
        <path d="M102.237 5.82849H106.526L100.692 20.1432H96.2902L90.4564 5.82849H94.9462L98.577 15.3136L102.237 5.82849Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path d="M79.2775 8.11376C80.2501 6.59928 81.794 5.62744 83.5662 5.62744C83.8809 5.62744 84.2807 5.6558 84.5387 5.74216V9.65659C84.1956 9.59988 83.8241 9.57152 83.481 9.57152C80.7931 9.57152 79.3923 11.5436 79.3923 14.3714V20.1432H75.2469V9.59988C75.2469 9.37174 75.1605 9.31374 74.9038 9.31374H73.4734V5.82851H77.0758C78.5346 5.82851 79.2775 6.51422 79.2775 7.80055V8.11376Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path d="M72.7291 16.6567C72.5009 16.6567 72.4428 16.5999 72.4428 16.3705V2.08546C72.4428 0.657347 71.6999 0 70.1844 0H66.5535V3.45687H67.8975C68.1542 3.45687 68.2406 3.54194 68.2406 3.77137V7.14317C67.6976 6.42911 66.1821 5.51398 64.1519 5.51398C60.1199 5.51398 57.1753 8.57129 57.1753 12.9716C57.1753 17.4571 60.0064 20.4293 64.0371 20.4293C66.2956 20.4293 67.8975 19.286 68.4973 18.3438C68.554 18.9728 68.9835 20.1445 70.5274 20.1445H74.2163V16.6592H72.7291V16.6567ZM64.8948 16.9995C62.893 16.9995 61.4356 15.4567 61.4356 12.9704C61.4356 10.5988 62.9227 8.99921 64.9232 8.99921C67.1817 8.99921 68.3257 10.9996 68.3257 12.942C68.3257 15.9709 66.4968 16.9995 64.8948 16.9995Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path d="M56.2595 12.514C56.2595 8.42815 53.0002 5.51392 49.083 5.51392C44.6796 5.51392 41.477 8.57122 41.477 12.9716C41.477 17.3152 44.4797 20.4292 49.083 20.4292C53.0002 20.4292 55.6017 18.0009 56.088 15.3148H52.056C51.5982 16.4865 50.2542 17.1438 48.9966 17.1438C47.3095 17.1154 46.052 16.1152 45.6805 13.9434H56.2312L56.2595 12.514ZM45.7669 11.3424C46.2248 9.22857 47.7971 8.71429 49.083 8.71429C50.3986 8.71429 51.9425 9.37164 52.1425 11.3424H45.7669Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path d="M33.8155 5.51392C31.4707 5.51392 29.812 6.74225 29.1838 7.79916C29.069 7.19853 28.6692 5.82712 27.0685 5.82712H23.5809V9.31364H25.01C25.2667 9.31364 25.3247 9.37035 25.3247 9.59978V25.9999H29.4998V19.6572L29.4715 18.6286C30.0712 19.6288 31.6732 20.4292 33.6169 20.4292C37.7056 20.4292 40.5651 17.4003 40.5651 12.9716C40.5626 8.51322 37.8462 5.51392 33.8155 5.51392ZM32.7566 16.9994C30.4981 16.9994 29.4108 14.9707 29.4108 13.0283C29.4108 10.0573 31.2127 8.99914 32.8134 8.99914C34.7571 8.99914 36.301 10.6283 36.301 12.9986C36.3023 15.7995 34.4153 16.9994 32.7566 16.9994Z" fill-rule="evenodd" clip-rule="evenodd"></path>
        <path d="M17.4027 12.9716C17.4027 17.4003 14.5432 20.4293 10.4545 20.4293C8.51071 20.4293 6.90877 19.6289 6.30901 18.6287L6.33738 19.6572V26H2.16355V9.59983C2.16355 9.37169 2.1068 9.31369 1.84884 9.31369H0.418434V5.82847H3.90738C5.50803 5.82847 5.90916 7.19987 6.02267 7.80051C6.6521 6.7436 8.3095 5.51526 10.6544 5.51526C14.6863 5.51397 17.4027 8.51328 17.4027 12.9716ZM13.1424 13C13.1424 10.6284 11.5985 9.00049 9.65349 9.00049C8.05283 9.00049 6.25096 10.0574 6.25096 13.0296C6.25096 14.972 7.33699 17.0008 9.59674 17.0008C11.2554 16.9995 13.1424 15.7995 13.1424 13Z" fill-rule="evenodd" clip-rule="evenodd"></path>
    </svg>
  )
}

export default PipedriveLogo