import React, { useEffect } from "react";
import {
  Typography,
  Autocomplete,
  Checkbox,
  TextField,
  Chip,
  createFilterOptions,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const MultiSelect = ({
  view,
  options,
  label,
  id,
  style,
  placeholder,
  selected,
  setSelected,
}) => {
  // console.log("selected::: ", selected);
  // console.log("options::: ", options);
  useEffect(() => {
    setSelected(selected);
  }, [selected, setSelected]);
  const handleChange = (e, newValue) => {
    // console.log("newValue: ", newValue);
    if (label === "NAICS Code" || label === "SIC Code") {
      if (newValue.length > 15) {
        return;
      }
    }
    const updatedValue = newValue.map((val) =>
      isNaN(val) ? val : parseInt(val)
    );

    setSelected(updatedValue);
  };
  const disabled = view === true;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  return (
    <div id={id} style={{ ...style }}>
      {!view && (
        <Typography
          sx={{ fontSize: "14px", fontWeight: "500" }}
        >
          {label === "NAICS Code" || label === "SIC Code"
            ? `${label} (Beta)`
            : label}
        </Typography>
      )}
      <Autocomplete
        multiple
        freeSolo={label === "SIC Code" ? true : false}
        disabled={disabled}
        id="checkboxes-tags-demo"
        options={options}
        value={selected}
        onChange={handleChange}
        // filterOptions={filterOptions}
        getOptionLabel={(option) => option.toString()}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            id="outlined-basic"
            fullWidth
            variant="outlined"
            // label={label}
            sx={{ mt: 1, backgroundColor: "white", borderRadius: 1 }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: "#111927",
                color: "white",
                "& .MuiChip-deleteIcon": {
                  backgroundColor: "transparent !important",
                  color: "white !important",
                  display: disabled ? "none" : "",
                  "&:hover": {
                    color: "white",
                  },
                },
              }}
            />
          ))
        }
      />
    </div>
  );
};

export default MultiSelect;
