import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  InputAdornment,
  Autocomplete,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Chip,
  FormGroup,
  Alert,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  ListItem,
  Tabs,
  Tab,
  alpha,
  List,
  ListItemText,
  CircularProgress,
  Pagination,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CsvImport from "./csvImport";
//import ManualImport from "./manualImport";
import {
  CloseOutlined,
  ArrowRight,
  ArrowLeft,
  UploadFileOutlined,
  EmailOutlined,
  SearchOutlined,
  ReportProblemOutlined,
  DeleteOutlineOutlined,
  DownloadOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";
import { toast } from "react-hot-toast";
import { CheckCircleIcon } from "src/assets/general/CheckCircleIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";

const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 1,
            height: "55vh",
            overflow: "auto",
            ...scrollBarStyle,
            my: 1,
          }}
        >
          <Box sx={{ height: "100%" }}>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ControlledInput = ({ label, search, setSearch, setPage, value }) => {
  const [state, setState] = useState(search);
  const debouncedSearchTerm = useDebouncedValue(state, 500);
  useEffect(() => {
    setSearch(debouncedSearchTerm);
    setPage(1);
  }, [debouncedSearchTerm]);
  useEffect(() => {
    setState(search);
  }, [value]);
  return (
    <TextField
      placeholder={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined sx={{ ml: "5px" }} />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      sx={{
        width: { xs: "80%", md: 300 },
        height: 40,
        backgroundColor: "white",
        "& div": { pl: 0.3 },
        "& div fieldset": {
          borderRadius: "8px",
          border: "1px solid #E4E4E5",
        },
        "& div input": {
          py: 1.3,
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0em",
          "&::placeholder": {
            color: "rgba(40, 40, 123, 0.5)",
          },
        },
      }}
      value={state}
      onChange={(e) => {
        setState(e.target.value);
      }}
    />
  );
};

const BlackListPanel = ({
  data,
  isLoading,
  value,
  selected,
  setSelected,
  isSelectAllEnabled,
  setIsSelectAllEnabled,
}) => {
  const handleSelect = (e, selected, setSelected) => {
    if (e.target.checked) {
      setSelected([...selected, e.target.value]);
    } else {
      const filtered = selected.filter((item) => item !== e.target.value);
      setSelected(filtered);
    }
  };
  const handleSelectAll = (e) => {
    setIsSelectAllEnabled(e.target.checked);
    setSelected([]);
  };
  return (
    <Box sx={{ height: "100%" }}>
      {isLoading ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : data && data?.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={"all"}
              checked={isSelectAllEnabled}
              onChange={handleSelectAll}
            />
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Select All
            </Typography>
          </Box>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {data?.map((item) => {
              const labelId = `checkbox-list-label-${item}`;

              return (
                <ListItem key={item} disablePadding>
                  <Checkbox
                    value={item?.value}
                    checked={
                      selected?.includes(item?.value) || isSelectAllEnabled
                    }
                    disabled={isSelectAllEnabled}
                    onChange={(e) => handleSelect(e, selected, setSelected)}
                  />
                  <ListItemText
                    id={labelId}
                    sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}
                  >
                    {item?.value}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            No {value === 1 ? "Domains" : "Emails"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const BlackListFilesPanel = ({
  isLoading,
  data,
  value,
  setSelectedFileViewName,
}) => {
  function parseFileInfo(url) {
    // Extract the file name from the URL
    const decodedFileName = decodeURIComponent(
      url?.split("/")?.pop()?.split("?")?.[0] || ''
    );
  
    // Parse the file name
    const parts = decodedFileName.split("_");
    const parsedFileName = parts.slice(2).join("_");
    const date = parts[1] || new Date().toISOString();
  
    // Format the date
    const parsedDate = moment(date).format("MMMM DD YYYY, h:mm a");
  
    // Get the timezone
    const getTimeZone = () => {
      const intl = Intl.DateTimeFormat().resolvedOptions().timeZone;
      moment.tz(intl).zoneName(); // This line doesn't do anything, kept for consistency with original code
      return moment.tz(intl).zoneAbbr();
    };
  
    // Combine date and timezone
    const formattedDate = `${parsedDate} ${getTimeZone()}`;
  
    return {
      fileName: parsedFileName,
      uploadDate: formattedDate,
    };
  }

  function extractFileInfo(url) {
    // Create a URL object
    const urlObj = new URL(url);
  
    // Extract the path
    const path = urlObj.pathname;
  
    // Use regex to extract the file name with timestamp
    const fileNameMatch = path.match(/\/([^\/]+)$/);
    const fullFileName = fileNameMatch ? decodeURIComponent(fileNameMatch[1]) : null;
  
    // Extract the timestamp and file name
    const parts = fullFileName ? fullFileName.split('_') : [];
    const timestamp = parts[0];
    const fileName = parts.slice(1).join('_'); // In case the file name itself contains underscores
  
    // Format the date
    const formattedDate = formatDate(timestamp);
  
    return {
      fileName: fileName || null,
      uploadDate: formattedDate
    };
  }
  
  function formatDate(timestamp) {
    if (!timestamp) return null;
  
    const date = moment(timestamp);
    const parsedDate = date.format("MMMM DD YYYY, h:mm a");
    
    const getTimeZone = () => {
      const intl = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return moment.tz(intl).zoneAbbr();
    };
  
    return `${parsedDate} ${getTimeZone()}`;
  }
  return (
    <Box sx={{ height: "100%" }}>
      <Stack
        direction="row"
        justifyContent="end"
        onClick={() => setSelectedFileViewName("All")}
      >
        <Button variant="contained" sx={{fontSize:'14px'}}>
          View All {value === 1 ? "Domains" : "Emails"}
        </Button>
      </Stack>
      {isLoading ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : data && data?.length > 0 ? (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data?.map((item, index) => {
            const labelId = `checkbox-list-label-${index}`;
            // const decodedFileName = decodeURIComponent(
            //   item?.url?.split("/")?.pop()?.split("?")?.[0]
            // );
            // const fileName = decodedFileName
            //   ?.split("_")
            //   .slice(2)
            //   .join("_");
            // const date = decodedFileName?.split("_")?.[1] || new Date();
            // const getTimeZone = () => {
            //   const intl = Intl.DateTimeFormat().resolvedOptions().timeZone; // Asia/Tokyo
            //   moment.tz(intl).zoneName(); // JST
            //   return moment.tz(intl).zoneAbbr();
            // };
            // const uploadDate = `${moment(date).format("MMMM DD YYYY, h:mm a")} ${getTimeZone()}`;
            let fileName;
            let uploadDate;

            if(item?.url.includes("s3.wasabisys.com")) {
              const fileInfo = parseFileInfo(item?.url);
              fileName = fileInfo.fileName;
              uploadDate = fileInfo.uploadDate;
            } else {
              const fileInfo = extractFileInfo(item?.url);
              fileName = fileInfo.fileName;
              uploadDate = fileInfo.uploadDate;
            }
            return (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 1,
                  border: `1px solid ${alpha("#000000", 0.05)}`,
                  p: 1,
                  my: 1,
                  mt: index === 0 ? 0 : 1,
                  gap: 2,
                }}
              >
                <ListItemText sx={{ width: "30%","& .MuiTypography-root": { fontSize: "14px" }}} id={labelId}>
                  {fileName || ""}
                </ListItemText>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    width: "35%",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    Uploaded On :
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {uploadDate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                    width: "25%",
                  }}
                >
                  {item?.processed && item?.status === "completed" && (
                    <Tooltip
                      title={`${item?.lastProcessedRow} ${
                        item?.lastProcessedRow > 1
                          ? value === 1
                            ? "domains have"
                            : "emails have"
                          : value === 1
                          ? "domain has"
                          : "email has"
                      } been added!`}
                      placement="top"
                      arrow
                    >
                      <IconButton>
                        <CheckCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* <Tooltip title="Delete" placement="top" arrow>
                    <IconButton
                      sx={{
                        color: "#050C46",
                      }}
                    >
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </Tooltip> */}
                  <a
                    href={item?.url}
                    download
                    style={{
                      textDecoration: "none",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    <Tooltip title="Download" placement="top" arrow>
                      <IconButton
                        sx={{
                          color: "#050C46",
                        }}
                      >
                        <DownloadOutlined />
                      </IconButton>
                    </Tooltip>
                  </a>
                  <Tooltip
                    title={`View ${value === 1 ? "Domains" : "Emails"}`}
                    placement="top"
                    arrow
                    onClick={() => setSelectedFileViewName(fileName)}
                  >
                    <IconButton
                      sx={{
                        color: "#050C46",
                      }}
                    >
                      <VisibilityOutlined />
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
            );
          })}
          <ListItem
            disablePadding
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: 1,
              border: `1px solid ${alpha("#000000", 0.05)}`,
              p: 1,
              my: 1,
              mt: 1,
              gap: 2,
            }}
          >
            <ListItemText sx={{ width: "30%","& .MuiTypography-root": { fontSize: "14px" }}}>Others</ListItemText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 1,
                width: "25%",
              }}
            >
              <Tooltip
                title={`View ${value === 1 ? "Domains" : "Emails"}`}
                placement="top"
                arrow
                onClick={() => setSelectedFileViewName("other")}
              >
                <IconButton
                  sx={{
                    color: "#050C46",
                  }}
                >
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </ListItem>
        </List>
      ) : (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem
            disablePadding
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: 1,
              border: `1px solid ${alpha("#000000", 0.05)}`,
              p: 1,
              my: 1,
              mt: 1,
              gap: 2,
            }}
          >
            <ListItemText sx={{ width: "30%" }}>Others</ListItemText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 1,
                width: "25%",
              }}
            >
              <Tooltip
                title={`View ${value === 1 ? "Domains" : "Emails"}`}
                placement="top"
                arrow
                onClick={() => setSelectedFileViewName("other")}
              >
                <IconButton
                  sx={{
                    color: "#050C46",
                  }}
                >
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </ListItem>
        </List>
      )}
    </Box>
  );
};

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const BlackList = ({ userEmail }) => {
  const [value, setValue] = useState(0);
  const [blockedEmails, setBlockedEmails] = useState({});
  const [blockedDomains, setBlockedDomains] = useState({});
  const [searchEmail, setSearchEmail] = useState("");
  const [searchDomain, setSearchDomain] = useState("");
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isSelectAllEmailsEnabled, setIsSelectAllEmailsEnabled] =
    useState(false);
  const [isSelectAllDomainsEnabled, setIsSelectAllDomainsEnabled] =
    useState(false);
  const [selectedFileViewName, setSelectedFileViewName] = useState("");

  const activeSelectedData = value === 1 ? selectedDomains : selectedEmails;
  const activeTab = value === 1 ? "Domains" : "Emails";

  // const debouncedEmailSearchTerm = useDebouncedValue(searchEmail, 500);
  // const debouncedDomainSearchTerm = useDebouncedValue(searchDomain, 500);

  const handleClickOpen = () => {
    setIsDeleteOpen(true);
  };

  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const body =
        value === 0
          ? {
              clientEmail: userEmail,
              removeEmails: isSelectAllEmailsEnabled ? ["ALL"] : selectedEmails,
            }
          : value === 1
          ? {
              clientEmail: userEmail,
              removeDomains: isSelectAllDomainsEnabled
                ? ["ALL"]
                : selectedDomains,
            }
          : {};
      if (value === 0 && isSelectAllEmailsEnabled && !!searchEmail) {
        body.emailSearchExpr = searchEmail;
      }
      if (value === 1 && isSelectAllDomainsEnabled && !!searchDomain) {
        body.domainSearchExpr = searchDomain;
      }

      const response = await axios.put(
        `${API_SERVICE_BACKEND}/exclusionlist/updateExclusionList`,
        body
      );
      console.log("response", response);
      setIsDeleting(false);
      setIsDeleteOpen(false);
      const type = value === 1 ? "domains" : "emails";
      fetchBlackListedData(type, selectedFileViewName);
      if (value === 1) {
        setSelectedDomains([]);
        setIsSelectAllDomainsEnabled(false);
      } else {
        setSelectedEmails([]);
        setIsSelectAllEmailsEnabled(false);
      }
      toast.success("Successfully deleted!");
    } catch (error) {
      setIsDeleting(false);
      console.error("Error during deletion:", error);
      toast.error("Something went wrong!");
    }
  };

  const handleClear = () => {
    if (value === 0) {
      setSelectedEmails([]);
      setIsSelectAllEmailsEnabled(false);
    }
    if (value === 1) {
      setSelectedDomains([]);
      setIsSelectAllDomainsEnabled(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
  };
  const [isImportLeadsDialogOpen, setIsImportLeadsDialogOpen] = useState(false);

  const handleClickOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(true);
  };

  const handleCloseOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(false);
    setActiveStep(0);
    setFileInputKey(Date.now());
  };

  const [emailFiles, setEmailFiles] = useState([]);
  const [domainFiles, setDomainFiles] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedEmailFile, setSelectedEmailFile] = useState(null);
  const [selectedDomainFile, setSelectedDomainFile] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const handlePageChange = (e, value) => {
    setPage(value);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  let abortController = new AbortController();
  const fetchBlackListedData = async (type, selectedFileViewName) => {
    const search = value === 1 ? searchDomain : searchEmail;

    const meta = selectedFileViewName === undefined ? true : false;
    const fileName =
      selectedFileViewName === undefined
        ? false
        : selectedFileViewName === "All"
        ? ""
        : selectedFileViewName;

    try {
      setIsLoading(true);
      const response = await axios.get(
        `${API_SERVICE_BACKEND}/exclusionlist/getExclusionLists?clientEmail=${userEmail}&meta=${meta}&listType=${type}&fileName=${fileName}&page=${page}&limit=${limit}&search=${search}`,
        {
          signal: abortController?.signal,
        }
      );
      console.log("fetched response", response);

      if (type === "emails") {
        if (!meta) {
          setBlockedEmails(
            {
              data: response?.data?.[0]?.emails,
              total: response?.data?.[0]?.emailsTotal,
            } || {}
          );
        } else {
          const emailData = response?.data?.[0]?.uploadedExclusionList?.filter(
            (item) => item.operation === "addEmails"
          );
          setEmailFiles(emailData);
        }
      }
      if (type === "domains") {
        if (!meta) {
          setBlockedDomains(
            {
              data: response?.data?.[0]?.domains,
              total: response?.data?.[0]?.domainsTotal,
            } || {}
          );
        } else {
          const domainData = response?.data?.[0]?.uploadedExclusionList?.filter(
            (item) => item.operation === "addDomains"
          );
          setDomainFiles(domainData);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    abortController?.abort();
    abortController = new AbortController();

    if (value === 0) {
      if (selectedFileViewName) {
        fetchBlackListedData("emails", selectedFileViewName);
      } else {
        fetchBlackListedData("emails");
      }
    }
    if (value === 1) {
      if (selectedFileViewName) {
        fetchBlackListedData("domains", selectedFileViewName);
      } else {
        fetchBlackListedData("domains");
      }
    }
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    };
  }, [value, page, limit, searchDomain, searchEmail, selectedFileViewName]);

  const paginatedData = value === 1 ? blockedDomains : blockedEmails;
  const pageCount = Math.ceil(paginatedData?.total / limit);

  const activeSelectedDataLabel =
    value === 1
      ? (isSelectAllDomainsEnabled
          ? paginatedData?.total
          : activeSelectedData?.length) > 1
        ? "Domains"
        : "Domain"
      : (isSelectAllEmailsEnabled
          ? paginatedData?.total
          : activeSelectedData?.length) > 1
      ? "Emails"
      : "Email";

  return (
    <>
      <Card sx={{ overflow: "visible", border: "1px solid #EAECF0" }}>
        <CardContent>
          <div
            // ref={section9Ref}
            id="section9"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "15px",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#050C46",
                }}
              >
                Block List
              </Typography>
              <Button
                variant="contained"
                onClick={handleClickOpenImportLeadsDialog}
                sx={{fontSize:'14px'}}
              >
                Import {value === 1 ? "Domains" : "Emails"}
              </Button>
            </Box>
            <Divider />
            <Box>
              {!selectedFileViewName && (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor=""
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  className="tabUI"
                  style={{ margin: "10px 0", width: "100%" }}
                >
                  <Tab
                    label="Emails"
                    {...a11yProps(0)}
                    style={{
                      marginLeft: "0px",
                      width: "50%",
                      minHeight: "38px",
                      border: value === 0 ? "1px solid white" : "",
                      borderRadius: "8px",
                      fontSize: "14px",
                      margin: "5px",
                      fontWeight: "700",
                      boxShadow:
                        value === 0
                          ? `0px 1px 2px 0px ${alpha("#101828", 0.1)}`
                          : "none",
                      padding: "0px 10px",
                      color: value === 0 ? "#050C46" : "#55597A",
                      backgroundColor: value === 0 ? "white" : "transparent",
                    }}
                  />
                  <Tab
                    label="Domains"
                    {...a11yProps(1)}
                    style={{
                      marginLeft: "0px",
                      width: "50%",
                      minHeight: "38px",
                      border: value === 1 ? "1px solid white" : "",
                      borderRadius: "8px",
                      fontSize: "14px",
                      margin: "5px",
                      fontWeight: "700",
                      boxShadow:
                        value === 1
                          ? `0px 1px 2px 0px ${alpha("#101828", 0.1)}`
                          : "none",
                      padding: "0px 10px",
                      color: value === 1 ? "#050C46" : "#55597A",
                      backgroundColor: value === 1 ? "white" : "transparent",
                    }}
                  />
                </Tabs>
              )}

              {selectedFileViewName && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    gap: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: "center" }}
                  >
                    <div
                      onClick={() => setSelectedFileViewName(undefined)}
                      style={{ cursor: "pointer" }}
                    >
                      <ArrowBackIcon />
                    </div>
                    <ControlledInput
                      label={
                        value === 1 ? "Search By Domain" : "Search By Email"
                      }
                      search={value === 1 ? searchDomain : searchEmail}
                      setSearch={value === 1 ? setSearchDomain : setSearchEmail}
                      setPage={setPage}
                      value={value}
                    />
                  </Stack>
                  {/* <TextField
                  placeholder={
                    value === 1 ? "Search by domain" : "Search by email"
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined sx={{ ml: "5px" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{
                    width: { xs: "80%", md: 300 },
                    height: 40,
                    backgroundColor: "white",
                    "& div": { pl: 0.3 },
                    "& div fieldset": {
                      borderRadius: "8px",
                      border: "1px solid #E4E4E5",
                    },
                    "& div input": {
                      py: 1.3,
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0em",
                      "&::placeholder": {
                        color: "rgba(40, 40, 123, 0.5)",
                      },
                    },
                  }}
                  value={value === 1 ? searchDomain : searchEmail}
                  onChange={(e) => {
                    if (value === 1) {
                      setSearchDomain(e.target.value);
                    } else {
                      setSearchEmail(e.target.value);
                    }
                  }}
                /> */}
                  {(activeSelectedData?.length > 0 ||
                    (value === 1 && isSelectAllDomainsEnabled) ||
                    (value === 0 && isSelectAllEmailsEnabled)) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography>
                        {`${
                          (value === 0 && isSelectAllEmailsEnabled) ||
                          (value === 1 && isSelectAllDomainsEnabled)
                            ? paginatedData?.total
                            : activeSelectedData?.length
                        } ${activeSelectedDataLabel}`}{" "}
                        Selected
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ borderRadius: 1 }}
                          onClick={handleClickOpen}
                          size="small"
                        >
                          Delete
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ borderRadius: 1 }}
                          onClick={handleClear}
                          size="small"
                        >
                          Clear
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

              <TabPanel value={value} index={0}>
                {selectedFileViewName ? (
                  <BlackListPanel
                    data={blockedEmails?.data}
                    isLoading={isLoading}
                    value={value}
                    selected={selectedEmails}
                    setSelected={setSelectedEmails}
                    isSelectAllEnabled={isSelectAllEmailsEnabled}
                    setIsSelectAllEnabled={setIsSelectAllEmailsEnabled}
                  />
                ) : (
                  <BlackListFilesPanel
                    data={emailFiles}
                    isLoading={isLoading}
                    value={value}
                    selected={selectedEmails}
                    setSelected={setSelectedEmails}
                    setSelectedFileViewName={setSelectedFileViewName}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {selectedFileViewName ? (
                  <BlackListPanel
                    data={blockedDomains?.data}
                    isLoading={isLoading}
                    value={value}
                    selected={selectedDomains}
                    setSelected={setSelectedDomains}
                    isSelectAllEnabled={isSelectAllDomainsEnabled}
                    setIsSelectAllEnabled={setIsSelectAllDomainsEnabled}
                  />
                ) : (
                  <BlackListFilesPanel
                    data={domainFiles}
                    isLoading={isLoading}
                    value={value}
                    selected={selectedEmails}
                    setSelected={setSelectedEmails}
                    setSelectedFileViewName={setSelectedFileViewName}
                  />
                )}
              </TabPanel>
            </Box>
            {selectedFileViewName && paginatedData?.total > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 3,
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Typography>
                    Total {value === 1 ? "Domains" : "Emails"}:{" "}
                    <b>{paginatedData?.total || 0}</b>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 1,
                      width: { xs: "100%", sm: "auto" },
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      Rows per page
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={limit}
                      //label="Rows per page"
                      onChange={handleLimitChange}
                      size="small"
                      sx={{
                        "& .MuiSelect-select": { width: "30px", py: "4px" },
                      }}
                      variant="outlined"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
            )}
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={isImportLeadsDialogOpen}
        onClose={handleCloseOpenImportLeadsDialog}
        maxWidth="md"
        sx={{
          backgroundColor: "rgba(4, 4, 30, 0.5)",
          "& .MuiDialog-paper": {
            minWidth: "300px",
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "28px",
                    color: "#28287B",
                  }}
                >
                  Import {value === 1 ? "Domains" : "Emails"}
                </Typography>
                {activeStep !== 0 && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: 0.5,
                      }}
                    >
                      <ArrowRight />
                    </Box>
                  </>
                )}
                {activeStep === 1 ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "28px",
                        color: "#8181B0",
                      }}
                    >
                      Import CSV File
                    </Typography>
                  </>
                ) : activeStep === 2 ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "28px",
                        color: "#8181B0",
                      }}
                    >
                      Input Emails Manually
                    </Typography>
                  </>
                ) : null}
              </Box>

              {activeStep === 0 ? (
                <>
                  {/* <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#8181B0",
                      mt: 1,
                    }}
                  >
                    Choose one of the methods listed below to effortlessly
                    import {value === 1 ? "domains" : "emails"}.
                  </Typography> */}
                </>
              ) : (
                <>
                  {/* <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#8181B0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setActiveStep(0);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: 1,
                      }}
                    >
                      <ArrowLeft color="#8181B0" />
                    </Box>
                    Select a Different Method
                  </Typography> */}
                </>
              )}
            </Box>
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleCloseOpenImportLeadsDialog}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {activeStep === 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    p: 3,
                    border: "1px solid #00AA38",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    boxShadow: "0px 12px 15px 0px #4B71970D",
                  }}
                  onClick={() => {
                    setActiveStep(1);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <BulkUploadIcon /> */}
                    <UploadFileOutlined />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#28287B",
                        mt: 1.5,
                      }}
                    >
                      Upload CSV
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "none", // "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    width: "100%",
                    p: 3,
                    border: "1px solid #CECECE",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    boxShadow: "0px 12px 15px 0px #4B71970D",
                    mx: 2,
                  }}
                  onClick={() => {
                    setActiveStep(2);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <ManualEmailIcon /> */}
                    <EmailOutlined />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#28287B",
                        mt: 1.5,
                      }}
                    >
                      Enter {value === 1 ? "Domains" : "Emails"} Manually
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ) : activeStep === 1 ? (
            <CsvImport
              setIsImportLeadsDialogOpen={setIsImportLeadsDialogOpen}
              selectedFile={
                value === 1 ? selectedDomainFile : selectedEmailFile
              }
              setSelectedFile={
                value === 1 ? setSelectedDomainFile : setSelectedEmailFile
              }
              fileInputKey={fileInputKey}
              value={value}
              userEmail={userEmail}
              fetchBlackListedData={fetchBlackListedData}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "red",
            }}
          >
            <ReportProblemOutlined sx={{ mr: 1 }} />
            Delete {activeTab}
          </Typography>
          <IconButton onClick={handleClose} sx={{ pr: 0, color: "#050C46" }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "16px", color: "#050C46" }}
          >
            {`${
              (value === 0 && isSelectAllEmailsEnabled) ||
              (value === 1 && isSelectAllDomainsEnabled)
                ? paginatedData?.total
                : activeSelectedData?.length
            } ${activeSelectedDataLabel} ${
              ((value === 0 && isSelectAllEmailsEnabled) ||
              (value === 1 && isSelectAllDomainsEnabled)
                ? paginatedData?.total
                : activeSelectedData?.length) > 1
                ? "have"
                : "has"
            } been selected`}
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "16px", color: "#050C46" }}
          >
            Are you sure you want to permanently delete{" "}
            {((value === 0 && isSelectAllEmailsEnabled) ||
            (value === 1 && isSelectAllDomainsEnabled)
              ? paginatedData?.total
              : activeSelectedData?.length) > 1
              ? "them"
              : "it"}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 1 }}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "#e30000" },
            }}
            onClick={handleDelete}
            autoFocus
            disabled={isDeleting}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlackList;
