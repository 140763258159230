import {
  Box,
  Grid,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  TextField,
  Switch,
  styled,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import { useState, useEffect } from "react";
import { jobsApi } from "src/api/jobs";
import { AuthContext } from "src/contexts/auth/firebase-context";
import { useContext } from "react";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00AA38",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const DayToggleButton = ({ label, checked, onClick }) => {
  const labelToDay = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
  };

  return (
    <Tooltip
      title={`${checked ? "Disable" : "Enable"} for ${labelToDay[label]}`}
      arrow
      placement="top"
    >
      <Box
        sx={{
          backgroundColor: checked
            ? "rgb(33, 111, 237, 1)"
            : "rgba(0, 0, 0, 0.05)",
          color: checked ? "white" : "#28287B",
          textDecoration: checked ? "none" : "line-through",
          mr: 1,
          px: 1,
          borderRadius: "4px",
          border: checked
            ? "1px solid rgb(33, 111, 237, 0.5)"
            : "1px solid rgba(0,0,0,0.1)",
          cursor: "pointer",
          width: "48px",
          height: "24px",
          textAlign: "center",
          py: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "0em",
        }}
        onClick={onClick}
      >
        {label}
      </Box>
    </Tooltip>
  );
};

const CampaignSchedule = ({ duplicateChange, allow }) => {
  const testCampaigns = [
    {
      id: 0,
      senderName: "Campaign 1",
      schedule: {
        days: [1, 2, 3, 4, 5],
      },
    },
    {
      id: 1,
      senderName: "Campaign 2",
      schedule: {
        days: [0, 1, 2, 3, 4, 5],
      },
    },
    {
      id: 2,
      senderName: "Campaign 3",
      schedule: {
        days: [1, 2, 3, 4],
      },
    },
  ];
  const isGlobalSchedule = (campaigns, refSchedule) => {
    const isGlobal = campaigns?.every(
      (camp) =>
        camp?.campaignSettings?.schedule?.days?.every((day) =>
          refSchedule?.includes(day)
        ) &&
        camp?.campaignSettings?.schedule?.days?.length === refSchedule?.length
    );
    return isGlobal;
  };
  const { user } = useContext(AuthContext);
  const [userCampaigns, setUserCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("all");
  const [isUpdating, setIsUpdating] = useState(false);
  const refSchedule =
    userCampaigns?.[0]?.campaignSettings?.schedule?.days || [];
  const globalSchedule = isGlobalSchedule(userCampaigns, refSchedule)
    ? refSchedule
    : [];
  const schedule =
    selectedCampaign === "all"
      ? globalSchedule
      : selectedCampaign?.campaignSettings?.schedule?.days;
  const [selectedDays, setSelectedDays] = useState(schedule);
  const days = [
    { label: "Sun", value: 0 },
    { label: "Mon", value: 1 },
    { label: "Tue", value: 2 },
    { label: "Wed", value: 3 },
    { label: "Thu", value: 4 },
    { label: "Fri", value: 5 },
    { label: "Sat", value: 6 },
  ];

  let open = false;
  let click = false;
  let footer = false;
  let link = false;

  let delaySendTime = 0;
  useEffect(() => {
    open =
      selectedCampaign === "all"
        ? userCampaigns?.[0]?.campaignSettings?.openTracking
        : selectedCampaign?.campaignSettings?.openTracking;
    setOpenTracking(open);

    click =
      selectedCampaign === "all"
        ? userCampaigns?.[0]?.campaignSettings?.clickTracking
        : selectedCampaign?.campaignSettings?.clickTracking;
    setClickTracking(click);

    footer =
      selectedCampaign === "all"
        ? userCampaigns?.[0]?.campaignSettings?.unSubFooter
        : selectedCampaign?.campaignSettings?.unSubFooter;
    setUnSubFooter(footer);

  }, [userCampaigns]);

  const [openTracking, setOpenTracking] = useState(open);
  const [clickTracking, setClickTracking] = useState(click);
  const [delayEmailsMins, setDelayEmailsMins] = useState(6);
  const [additionalDelayEmailsMins, setAdditionalDelayEmailsMins] = useState(0);
  const [delayEmailsError, setDelayEmailsError] = useState(false);
  const [delayTime, setDelayTime] = useState(0);
  const [unSubFooter, setUnSubFooter] = useState(footer);

  useEffect(() => {
    if (delayEmailsMins < 6 || additionalDelayEmailsMins > 60 * 24) {
      setDelayEmailsError(true);
    } else {
      setDelayEmailsError(false);
    }
  }, [delayEmailsMins, additionalDelayEmailsMins]);

  useEffect(() => {
    const totalDelayTime = delayEmailsMins + additionalDelayEmailsMins;
    if (delayEmailsMins !== 0) {
      const delayMins = (15 * 60) / totalDelayTime;
      setDelayTime(delayMins);
    }
  }, [delayEmailsMins, additionalDelayEmailsMins]);

  const handleOpenTracking = (e) => {
    setOpenTracking(e.target.checked);
  };

  const handleClickTracking = (e) => {
    setClickTracking(e.target.checked);
  };

  const handleDelayChange = (e) => {
    setAdditionalDelayEmailsMins(parseInt(e.target.value, 10) || 0);
  };

  const handleMinimumDelayChange = (e) => {
    setDelayEmailsMins(parseInt(e.target.value, 10) || 0);
  };

  const handleFooterTracking = (e) => {
    setUnSubFooter(e.target.checked);
  };

  const fetchUserCampaigns = () => {
    jobsApi
      .getUserCampaigns(user?.email)
      .then((res) => {
        if (selectedCampaign !== "all") {
          const updatedCampaign =
            res?.data?.find((d) => d?._id === selectedCampaign?._id) || "";
          setSelectedCampaign(updatedCampaign);
        } else {
          const refSchedule =
            res?.data?.[0]?.campaignSettings?.schedule?.days || [];
          const isGlobal = isGlobalSchedule(res?.data, refSchedule);
          if (isGlobal) {
            setSelectedDays(refSchedule);
          } else {
            setSelectedDays([]);
          }
        }
        setUserCampaigns(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCampaignChange = (e) => {
    const campaign = e.target.value;
    setSelectedCampaign(campaign);
    if (campaign === "all") {
      setSelectedDays(globalSchedule);
      setDelayEmailsMins(campaign?.[0]?.campaignSettings?.minDelayInMins || 6);
      setAdditionalDelayEmailsMins(
        campaign?.[0]?.campaignSettings?.delayEmailsInMins || 0
      );
      setOpenTracking(campaign?.[0]?.campaignSettings?.openTracking || false);
      setClickTracking(campaign?.[0]?.campaignSettings?.clickTracking || false);
      setUnSubFooter(campaign?.[0]?.campaignSettings?.unSubFooter || false);
    } else {
      setSelectedDays(campaign?.campaignSettings?.schedule?.days || []);
      setAdditionalDelayEmailsMins(
        campaign?.campaignSettings?.delayEmailsInMins || 0
      );
      setDelayEmailsMins(campaign?.campaignSettings?.minDelayInMins || 6);
      setOpenTracking(campaign?.campaignSettings?.openTracking);
      setClickTracking(campaign?.campaignSettings?.clickTracking);
      setUnSubFooter(campaign?.campaignSettings?.unSubFooter);
    }
  };

  const handleSave = async (updateType) => {
    if (userCampaigns?.length === 0) return;

    const generatePayload = (campaignId) => {
      const basePayload = { id: campaignId, payload: {} };
      switch (updateType) {
        case "schedule":
          basePayload.payload["campaignSettings.schedule"] = {
            days: selectedDays,
          };
          break;
        case "openTracking":
          basePayload.payload["campaignSettings.openTracking"] = openTracking;
          break;
        case "delayEmails":
          basePayload.payload["campaignSettings.minDelayInMins"] =
            delayEmailsMins;
          basePayload.payload["campaignSettings.delayEmailsInMins"] =
            additionalDelayEmailsMins;
          break;
        case "clickTracking":
          basePayload.payload["campaignSettings.clickTracking"] = clickTracking;
          break;
        case "unSubFooter":
          basePayload.payload["campaignSettings.unSubFooter"] = unSubFooter;
          break;
        default:
          break;
      }
      return basePayload;
    };
    if (selectedCampaign === "all") {
      try {
        setIsUpdating(true);
        const url = `${API_SERVICE_BACKEND}/updateCampaign`;
        const promises = userCampaigns.map((campaign) =>
          axios.patch(url, generatePayload(campaign._id))
        );
        const response = await Promise.all(promises);
        const success = response?.filter((res) => res.status === 204);
        if (success?.length === userCampaigns?.length) {
          toast.success(`Schedule Updated for all AI Agents!`);
          setIsUpdating(false);
          fetchUserCampaigns();
        } else {
          setIsUpdating(false);
          throw new Error("Updating Schedule failed, Try again !");
        }
      } catch (error) {
        setIsUpdating(false);
        toast.error("Something went wrong!");
      }
    } else {
      try {
        setIsUpdating(true);
        const url = `${API_SERVICE_BACKEND}/updateCampaign`;
        const res = await axios.patch(
          url,
          generatePayload(selectedCampaign._id)
        );
        if (res?.status === 204) {
          toast.success(`Agent Schedule Updated!`);
          setIsUpdating(false);
          fetchUserCampaigns();
        } else {
          setIsUpdating(false);
          throw new Error("Updating Schedule failed, Try again !");
        }
      } catch (error) {
        setIsUpdating(false);
        toast.error("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    fetchUserCampaigns();
  }, []);
  useEffect(() => {
    const saveBtn = document.getElementById("save-settings-btn");
    saveBtn.style.display = "none";
    return () => {
      saveBtn.style.display = "flex";
    };
  }, []);

  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCampaign}
        onChange={handleCampaignChange}
        sx={{
          width: "fit-content",
          "& .MuiSelect-select": { py: "8px" },
        }}
        variant="outlined"
        displayEmpty
      >
        <MenuItem key={0} value={"all"}>
          All AI Agents
        </MenuItem>
        {userCampaigns?.length > 0 &&
          userCampaigns?.map((campaign) => (
            <MenuItem key={campaign?.id} value={campaign}>
              {campaign?.senderName}
            </MenuItem>
          ))}
      </Select>
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0px 12px 15px 0px #4B71970D",
            mt: 1.5,
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.5)",
              boxShadow: 2,
            },
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
              p: 3,
              flexDirection: "column",
              height: "fit-content",
              transition: "1s all ease",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "0px",
                  color: "#28287B",
                }}
              >
                Delay Between Emails:
              </Typography>
            </Box>
            <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // gap: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "100%",
                      gap: 2,
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        size="small"
                        label="Min"
                        variant="outlined"
                        onChange={handleMinimumDelayChange}
                        value={delayEmailsMins}
                        style={{
                          width: "76px",
                          marginRight: "10px",
                        }}
                      />
                      <Typography>+</Typography>
                      <TextField
                        size="small"
                        label="Aditional Delay"
                        variant="outlined"
                        value={additionalDelayEmailsMins}
                        style={{
                          width: "110px",
                          marginLeft: "10px",
                        }}
                        onChange={handleDelayChange}
                      />
                    </div>
                    {/* {delayTime ? <Typography style={{ fontSize: '12px', marginLeft: '5px' }}>Maximum number of mails per mailbox, per day is: {delayTime}</Typography> : <Typography style={{ fontSize: '12px', marginLeft: '5px' }}>(Minutes)</Typography>} */}
                    {delayEmailsError && (
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        Please enter the mimimum delay time above 6{" "}
                      </Typography>
                    )}
                  </Box>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={delayEmailsMins < 6 ? true : false}
                    onClick={() => handleSave("delayEmails")}
                    sx={{ borderRadius: 1 }}
                    loading={isUpdating}
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0px 12px 15px 0px #4B71970D",
            mt: 1.5,
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.5)",
              boxShadow: 2,
            },
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
              p: 3,
              flexDirection: "column",
              height: "fit-content",
              transition: "1s all ease",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "0px",
                  color: "#28287B",
                }}
              >
                Manage Duplicates:
              </Typography>
            </Box>
            <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "100%",
                      gap: 2,
                      marginTop: "10px",
                    }}
                  >
                    <RadioGroup
                      onChange={duplicateChange}
                      row
                      aria-label="allow"
                      value={allow?.toString()}
                      name="isSetduplicate"
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Don't Allow"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Allow "
                      />
                    </RadioGroup>
                  </Box>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={isUpdating}
                    onClick={() => handleSave("openTracking")}
                    sx={{ borderRadius: 1 }}
                    loading={isUpdating}
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box> */}

        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0px 12px 15px 0px #4B71970D",
            mt: 1.5,
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.5)",
              boxShadow: 2,
            },
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
              p: 3,
              flexDirection: "column",
              height: "fit-content",
              transition: "1s all ease",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "0px",
                  color: "#28287B",
                }}
              >
                Link:
              </Typography>
            </Box>
            <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "100%",
                      gap: 2,
                      marginTop: "10px",
                    }}
                  >
                    Off{" "}
                    <IOSSwitch
                      onChange={handleLinkTracking}
                      checked={unSubLink}
                    />{" "}
                    On
                  </Box>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={isUpdating}
                    onClick={() => handleSave("unSubLink")}
                    sx={{ borderRadius: 1 }}
                    loading={isUpdating}
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box> */}
      </>
    </>
  );
};

export default CampaignSchedule;
