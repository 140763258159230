import React from "react";
import { Box } from "@mui/system";
import Spf from "./instructions/spf";
import Dkim from "./instructions/dkim";
import Dmarc from "./instructions/dmarc";
import { Typography } from "@mui/material";
const Instructions = ({ domain, service, type }) => {
  const getInstructions = (type) => {
    switch (type) {
      case "SPF":
        return <Spf domain={domain} service={service} />;
      case "DKIM":
        return <Dkim domain={domain} service={service} />;
      case "DMARC":
        return <Dmarc domain={domain} service={service} />;
      case "MX":
        return <Dmarc domain={domain} service={service} />;
      default:
        return;
    }
  };
  return (
    <Box sx={{ height: { xs: "100%", sm: "auto" } }}>
      {getInstructions(type)}
    </Box>
  );
};

export default Instructions;
