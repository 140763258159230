import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { API_SERVICE_BACKEND } from "src/config";
import { jobsApi } from "src/api/jobs";

const unarchiveCampaign = ({ id, email }) => {
  return axios.put(`${API_SERVICE_BACKEND}/unarchive-campaign/${id}/false`, {
    payload: { clientEmail: email },
  });
};

export const useUnarchiveCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: unarchiveCampaign,
    onMutate: async (campaignId) => {
      await queryClient.cancelQueries(["archivedCampaigns"]);

      const previousCampaigns = queryClient.getQueryData(["archivedCampaigns"]);

      queryClient.setQueryData(["archivedCampaigns"], (old) =>
        old.filter((campaign) => campaign._id !== campaignId)
      );

      return { previousCampaigns };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["archivedCampaigns"]);
      queryClient.invalidateQueries(["activeCampaigns"]);
      toast.success("Campaign unarchived successfully");
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ["archivedCampaigns"],
        context.previousCampaigns
      );
      toast.error(
        error.response?.data?.error ||
          error.message ||
          "Failed to unarchive campaign"
      );
    },
  });
};

const deleteDraftCampaign = (campaignId) =>
  axios.delete(
    `${API_SERVICE_BACKEND}/saveAsDraftDeleteCampaign/${campaignId}`,
    {}
  );

export const useDeleteDraftCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDraftCampaign,
    onMutate: async (campaignId) => {
      await queryClient.cancelQueries(["draftCampaigns"]);

      const previousCampaigns = queryClient.getQueryData(["draftCampaigns"]);

      queryClient.setQueryData(["draftCampaigns"], (old) =>
        old.filter((campaign) => campaign._id !== campaignId)
      );

      return { previousCampaigns };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["draftCampaigns"]);
      toast.success("Draft campaign deleted successfully");
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["draftCampaigns"], context.previousCampaigns);
      toast.error(error.message || "Failed to delete draft campaign");
    },
  });
};

const toggleCampaignPaused = async ({ campaignId, tempPaused }) => {
  return jobsApi.updateCampaign(campaignId, {
    paused: !tempPaused,
  });
};

export const useToggleCampaignPaused = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: toggleCampaignPaused,
    onMutate: async ({ campaignId, tempPaused }) => {
      await queryClient.cancelQueries(["activeCampaigns"]);

      const previousCampaigns = queryClient.getQueryData(["activeCampaigns"]);

      queryClient.setQueryData(["activeCampaigns"], (old) =>
        old.map((campaign) =>
          campaign._id === campaignId
            ? { ...campaign, paused: !tempPaused }
            : campaign
        )
      );

      return { previousCampaigns };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["activeCampaigns"], context.previousCampaigns);
      toast.error(error.message || "Failed to toggle campaign status");
    },
  });
};
