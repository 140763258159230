import React, { useState, useCallback } from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  Stack,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const MultiInput = ({
  label,
  // handleKeyPress,
  // handleAdd,
  selectedState,
  setSelectedState,
  // handleDelete,
  onChange,
  vid,
  view,
  id,
  placeholder,
  name,
  buttonId,
  style,
  limit,
  islgUp,
  isApollo,
  ismdUp
}) => {
  const [state, setState] = useState([]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(buttonId).click();
    }
  };

  const [domainError,setDomainError]=useState(false)

  const handleAdd = useCallback((value) => {
    setSelectedState((prevState) => {
      const newValues = [];
      if(isApollo){
        if(value.includes('.')){
          setDomainError(false)
          const uniqueValues = new Set([...prevState, value]);
          for (let item of uniqueValues.values()) {
            newValues.push(item);
          }
        }else{
          setDomainError(true)
          return prevState;
        }
      }else{
        const uniqueValues = new Set([...prevState, value]);
          for (let item of uniqueValues.values()) {
            newValues.push(item);
          }
      }
      return newValues;
    });
  }, []);
  const handleDelete = useCallback((value) => {
    setSelectedState((prevState) => {
      return prevState.filter((t) => t !== value);
    });
  }, []);

  return (
    <div id={id} style={{ ...style }}>
      {!view && (
        <Typography
          sx={{ fontSize: "14px", fontWeight: "500" }}
        >
          {label}
        </Typography>
      )}
      <TextField
        onKeyDown={handleKeyPress}
        disabled={view ? true : false}
        fullWidth
        variant="filled"
        title={placeholder}
        // label={view ? label : placeholder}
        label={view ? label :( ismdUp ? placeholder : placeholder.title?.length <= 5 ? placeholder : placeholder.slice(0,11)+ " ...")}
        InputProps={{
          startAdornment: view && selectedState && selectedState.length > 0 && selectedState.map((tag, index) => (
            <Chip
              disabled={view ? true : false}
              key={index}
              label={tag}
              onDelete={() => handleDelete(tag)}
              variant="outlined"
              sx={{
                backgroundColor: "#111927",
                color: "white",
                "& .MuiChip-deleteIcon": {
                  backgroundColor: "transparent !important",
                  color: "white !important",
                  display: view ? "none" : '',
                  "&:hover": {
                    color: "white",
                  },
                },
              }}
            />
          )),
          endAdornment: (
            <>
              {!view && (
                <InputAdornment position="end">
                  {!view && <Button
                    // color={"inherit"}
                    disabled={selectedState.length === limit}
                    id={buttonId}
                    sx={{
                      ml: 2,
                      borderRadius: 1,
                      color: "white",
                      backgroundColor:
                        selectedState.length !== limit
                          ? "#5761FE"
                          : "rgba(17, 25, 39, 0.12)",
                      padding: "8px 14px 8px 10px",
                      "&:hover": {
                        backgroundColor: "#5761FE",
                        color: "white",
                      },
                      "& .MuiSvgIcon-root": { color: "#FFFFFF" },
                    }}
                    onClick={() => {
                      if (!state || /^\s+$/.test(state)) {
                        return;
                      }

                      state.map((item) => {
                        return handleAdd(item.replace(/^\s+|\s+(?=\s)|\s+$/g, ""));
                      });
                      setState([]);
                    }}
                  // variant={"contained}
                  >
                    <AddIcon />
                    Add
                  </Button>}
                </InputAdornment>
              )}
            </>
          ),
        }}
        name={name}
        onChange={(event) => setState(event.target.value.split(","))}
        value={state}
        sx={{
          backgroundColor: "white",
          borderRadius: 1,
          mt: !!label ? 0.8 : 0,
        }}
      />
      {domainError && <Typography color="error" sx={{ fontSize: "12px" }}>Please enter valid Domain</Typography>}
      {selectedState && selectedState?.length === limit && (
        <Typography color="error" sx={{ fontSize: "12px" }}>
          {name === "school"
            ? "Schools limit reached"
            : name === "company"
              ? "Companies or domains limit reached"
              : "Skills limit reached"}
        </Typography>
      )}
      <Stack
        direction={islgUp ? "column" : "row"}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {!view && <Stack
          mt={1}
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          gap={1}
        >
          {selectedState && selectedState.length > 0 && selectedState.map((tag, index) => (
            <Chip
              disabled={view ? true : false}
              key={index}
              label={tag}
              onDelete={() => handleDelete(tag)}
              variant="outlined"
              sx={{
                backgroundColor: "#111927",
                color: "white",
                "& .MuiChip-deleteIcon": {
                  backgroundColor: "transparent !important",
                  color: "white !important",
                  display: view ? "none" : '',
                  "&:hover": {
                    color: "white",
                  },
                },
              }}
            />
          ))}
        </Stack>}
        {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}> */}
        {!view && <Button
          sx={{
            display: selectedState.length === 0 && "none",
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 600,
            mr: 0.5,
            // mt: 1,
            color: "blue",
            "&:hover": { color: "red" },
          }}
          onClick={() => {
            setSelectedState([]);
            //  onChange(vid, []);
          }}
        >
          Clear all
        </Button>}
      </Stack>
    </div>
  );
};

export default MultiInput;
