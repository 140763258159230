import React, { useEffect, useState, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactJson from "react-json-view";
import Cookies from "js-cookie";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Divider,
  Stack,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  MenuItem,
  alpha,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
  Tooltip,
  Switch,
  styled,
  Select,
  DialogActions,
  ListItem,
  ListItemText,
  List,
  Snackbar,
  Pagination,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  SearchOutlined,
  AddOutlined,
  Close,
  DeleteOutline,
  ExpandMore,
  ExpandLess,
  InfoOutlined,
  LanguageOutlined,
  CheckCircle,
  Cancel,
  CloseOutlined,
  ReportProblemOutlined,
} from "@mui/icons-material";
import { GmailIcon } from "src/components/logos/gmailIcon";
import { MicrosoftIcon } from "src/components/logos/microsoftIcon";
import Instructions from "./instructions";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  googleWebAppConfig,
  microsoftWebAppConfig,
  API_SERVICE_BACKEND,
} from "../../../../config";
import "../style.css";
import { getSessionStorage, setSessionStorage } from "src/utils/storage";
import ConnectNewAccount from "src/components/connectNewAccount";

const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00AA38",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const CustomButton = ({ label, error, handleClick, acc, setOpenSnackbar }) => {
  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState("godaddy");
  const [service, setService] = useState("google");
  const [subDomain, setSubDomain] = useState("");
  const [subDomainError, setSubDomainError] = useState("");
  const [checking, setChecking] = useState(false);
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isValidDomain = (subdomain) => {
    const regex = /[^a-zA-Z0-9\-]/;
    return subdomain !== "" ? !regex.test(subdomain) : false;
  };
  const handleOpen = () => [setOpen(true)];
  const handleClose = () => {
    setOpen(false);
  };
  const [expandedSpf, setExpandedSpf] = useState(true);
  const handleExpandSPF = () => {
    setExpandedSpf(!expandedSpf);
  };

  const checkStatus = () => {
    if (isValidDomain(subDomain)) {
      setSubDomainError("");
      setChecking(true);
      setTimeout(() => setChecking(false), 5000);
    } else {
      setSubDomainError("Invalid sub domain!");
    }
  };
  const CustomDomainInstructions = () => {
    return (
      <List
        sx={{
          listStyle: "decimal",
          pl: 4,
          "& .MuiListItem-root": { p: 0, fontSize: "14px" },
          "& .MuiListItemText-root .MuiTypography-root": {
            fontWeight: "400",
            fontSize: "14px",
            color: "#ffffff",
          },
        }}
      >
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Go to your domain's DNS Settings</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Create a new CNAME record</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Enter the following details</ListItemText>
        </ListItem>
      </List>
    );
  };
  return (
    <>
      <Button
        onClick={
          ["SPF", "DMARC", "DKIM", "Custom Tracking", "MX"].includes(label)
            ? handleOpen
            : handleClick
        }
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          borderRadius: 1,
          py: 1,
          px: 2,
          border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
          "&:hover": {
            border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
            backgroundColor: alpha(error ? "#E20E0E" : "#00AA38", 0.1),
          },
        }}
      >
        <Typography
          sx={{ fontWeight: "700", color: "#111927", fontSize: "12px" }}
        >
          {label}
        </Typography>
        {error ? (
          <Cancel sx={{ color: "#E20E0E", fontSize: "22px" }} />
        ) : (
          <CheckCircle sx={{ color: "#00AA38", fontSize: "22px" }} />
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen={isSmDown}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {label === "Custom Tracking"
              ? "Set up a custom tracking subdomain"
              : "Configure Domain Authentication"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: { xs: "hidden", sm: "auto" },
            // flex: {
            //   xs: `${label === "Custom Tracking" ? 0 : 1} 1 auto`,
            //   sm: "1 1 auto",
            // },
          }}
        >
          {label === "Custom Tracking" ? (
            <Stack rowGap={2}>
              <Typography
                className=""
                sx={{ fontSize: "14px", fontWeight: "400", color: "#111927" }}
              >
                Default tracking domains can put your sending reputation at
                risk, so it's best to set up a custom tracking subdomain to
                maintain deliverability.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: "#111927" }}
              >
                Configuring a custom subdomain will require access to your
                company's DNS.
              </Typography>
              <Stack rowGap={0.7}>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "600", color: "#111927" }}
                >
                  Add custom subdomain
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    sx={{ width: { xs: "100%", sm: "100px" }, flexGrow: 1 }}
                    value={subDomain}
                    onChange={(e) => setSubDomain(e.target.value)}
                    error={!!subDomainError}
                    placeholder={"track"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#111927",
                            }}
                          >
                            .{acc?.senderEmail.split("@")?.[1]}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={subDomainError}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      loading={checking}
                      // loadingPosition="start"
                      loadingIndicator="Checking…"
                      sx={{ borderRadius: 1, py: "6px" }}
                      onClick={checkStatus}
                    >
                      <span>Check Status</span>
                    </LoadingButton>
                    {checking && (
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: 1, py: "6px" }}
                        onClick={() => {
                          setChecking(false);
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Box>
                </Box>
              </Stack>
              <Stack rowGap={1}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#111927",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Add a new CNAME record for your tracking domain or subdomain.{" "}
                  <Tooltip title={<CustomDomainInstructions />}>
                    <InfoOutlined sx={{ fontSize: "18px" }} />
                  </Tooltip>
                </Typography>

                <Stack
                  rowGap={1}
                  sx={{
                    backgroundColor: alpha("#000000", 0.05),
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#111927",
                        width: "100px",
                      }}
                    >
                      Record Type:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#111927",
                      }}
                    >
                      CNAME
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#111927",
                        width: "100px",
                      }}
                    >
                      Host:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#111927",
                      }}
                    >
                      {subDomain || "track"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#111927",
                        width: "100px",
                      }}
                    >
                      Value:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#111927",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      prox.itrackly.com{" "}
                      <span
                        style={{
                          borderRadius: "8px",
                          padding: "4px 8px",
                          marginLeft: "8px",
                          backgroundColor: "#111927",
                          color: "#ffffff",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenSnackbar(true);
                          navigator.clipboard.writeText("prox.itrackly.com");
                        }}
                      >
                        Copy
                      </span>
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#111927",
                  mt: 1,
                }}
              >
                {label === "DKIM" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={acc?.rapidApiData?.records[label]}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
                {label === "MX" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={acc?.rapidApiData?.records[label]}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
                {label === "DMARC" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={{
                                  DMARC: acc?.rapidApiData?.records[label],
                                }}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div>
                      <div className="pop-authenticate-domain"
                        onClick={handleExpandSPF}>
                        <p>Advanced Option</p>
                        <IconButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: "#ECE9F5",
                            color: "#5761FE",
                            p: 0.5,
                            "&:hover": { backgroundColor: "#ECE9F5" },
                          }}
                        >
                          {expandedSpf ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {expandedSpf && (<pre style={{ whiteSpace: 'pre-wrap', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px', }}>
                          <p className="black-authencate">{'"' + label + '" :'}</p> <p className="red-authencate">{JSON.stringify(acc?.rapidApiData?.records[label], null, 2)}</p>
                        </pre>)}
                      </div>


                    </div> */}
                  </>
                )}
                {label === "SPF" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={{ SPF: acc?.rapidApiData?.records[label] }}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div>

                      <div className="pop-authenticate-domain"
                        onClick={handleExpandSPF}>
                        <p>Advanced Option</p>
                        <IconButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: "#ECE9F5",
                            color: "#5761FE",
                            p: 0.5,
                            "&:hover": { backgroundColor: "#ECE9F5" },
                          }}
                        >
                          {expandedSpf ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {expandedSpf && (<pre style={{ whiteSpace: 'pre-wrap', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px', }}>
                          <p className="black-authencate">{'"' + label + '" :'}</p> <p className="red-authencate">{JSON.stringify(acc?.rapidApiData?.records[label], null, 2)}</p>
                        </pre>)}
                      </div>


                    </div> */}
                  </>
                )}
                How to configure domain attributes?{" "}
                <span
                  style={{
                    display: isSmDown ? "inline" : "none",
                    borderRadius: "8px",
                    padding: "4px",
                    border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                >
                  {" "}
                  {label}
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  my: 1,
                }}
              >
                {" "}
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block" },
                    borderRadius: 1,
                    py: 1,
                    px: 2,
                    border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {label}
                </Typography>
                <Select
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  size="small"
                >
                  <MenuItem value="godaddy">GoDaddy</MenuItem>
                  <MenuItem value="namecheap">NameCheap</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                <Select
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  size="small"
                >
                  <MenuItem value="google">Google WorkSpace</MenuItem>
                  <MenuItem value="microsoft">Microsoft 365</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </Box>
              <Instructions
                domain={domain}
                service={service}
                type={label}
                rapiddata={acc.rapidApiData}
              />
            </>
          )}
        </DialogContent>
        {label === "Custom Tracking" && (
          <DialogActions>
            <Button
              fullWidth={isSmDown}
              variant="contained"
              sx={{ alignSelft: "end", borderRadius: 1 }}
              onClick={checkStatus}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const GooglePostMaster = () => {
  return (
    <Stack
      rowGap={2}
      sx={{
        border: "1px solid #ECE9F5",
        backgroundColor: "#ffffff",
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{ color: "#111927", fontWeight: "700", fontSize: "12px" }}
      >
        Google Postmaster:{" "}
        <span
          style={{
            color: "#55597A",
            fontWeight: "700",
            fontSize: "12px",
          }}
        >
          Domain Health
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Spam Rate :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                1.2%
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                0.98%
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Domain Reputation :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            IP Reputation :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
const MailItem = ({
  acc,
  index,
  selected,
  handleChange,
  expandAll,
  userType,
  setOpenSnackbar,
  fetchAllMailBoxes,
  userEmail,
}) => {
  // console.log("acc=====>", acc);
  const defaultHourlyLimit = acc?.hourlyLimit || 0;
  const defaultDailyLimit = acc?.maxAllowed || 0;
  const defaultDelay = acc?.delayEmails
    ? Math.floor(acc?.delayEmails / (1000 * 60))
    : 0 || 0;
  const [expanded, setExpanded] = useState(false);
  const [hourlyLimit, setHourlyLimit] = useState(defaultHourlyLimit);
  const [dailyLimit, setDailyLimit] = useState(defaultDailyLimit);
  const [delay, setDelay] = useState(defaultDelay);
  const [openTracking, setOpenTracking] = useState(acc?.openTracking);
  const [clickTracking, setClickTracking] = useState(acc?.clickTracking);
  const navigate = useNavigate();
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  const handleOpenTracking = async (e) => {
    setOpenTracking(e.target.checked);
    try {
      let payload = {
        openTracking: e.target.checked,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );

      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const handleClickTracking = async (e) => {
    setClickTracking(e.target.checked);
    try {
      let payload = {
        clickTracking: e.target.checked,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );

      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const isValidInput = (input) => {
    return /^[0-9]*$/.test(input);
  };
  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);
  const isSaveVisible =
    hourlyLimit !== defaultHourlyLimit ||
    dailyLimit !== defaultDailyLimit ||
    delay !== defaultDelay;
  const [loading, setLoading] = useState(false);
  const mailBoxUpdate = async () => {
    try {
      let payload = {
        hourlyLimit: hourlyLimit || 0,
        delayEmails: delay ? Math.floor(delay * 1000 * 60) : 0,
        maxAllowed: dailyLimit || 0,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );
      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const handleDelete = async (mailboxId) => {
    const id = mailboxId;
    try {
      setIsDeleting(true);
      const res = await axios.delete(`${API_SERVICE_BACKEND}/mailbox/${id}`);
      if (res.status === 200) {
        setIsDeleting(false);
        toast.success("Account deleted successfully!");
        setIsDeleteOpen(false);
        await fetchAllMailBoxes(userEmail);
      } else {
        setIsDeleting(false);
        console.error("Failed to unassign the mailbox:", res);
        toast.error("Failed to unassign the mailbox!");
        //alert('Failed to unassign the mailbox');
      }
    } catch (err) {
      setIsDeleting(false);
      console.error("Error when deleting the mailbox:", err);
      toast.error("An error occurred while deleting the mailbox!");
      //alert('An error occurred while deleting the mailbox');
    } finally {
      setLoading(false);
      setIsDeleting(false);
    }
  };
  return (
    <>
      <Card
        key={index}
        sx={{
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 }, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              mb: 2,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {acc?.provider === "google" ? (
                <GmailIcon size={"20px"} />
              ) : (
                <MicrosoftIcon size={"20px"} />
              )}
              <Typography
                sx={{ fontWeight: "700", color: "#111927", fontSize: "14px" }}
              >
                {acc.senderEmail}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", sm: "flex-end" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {" "}
              {loading ? (
                <CircularProgress size={25} />
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    display: {
                      xs: "none",
                      sm: isSaveVisible ? "block" : "none",
                    },
                    borderRadius: 1,
                    py: "4.6px",
                  }}
                  onClick={mailBoxUpdate}
                >
                  Save
                </Button>
              )}
              <Button
                variant="outlined"
                color="error"
                onClick={() => setIsDeleteOpen(true)}
                sx={{ borderRadius: 1, py: "4.6px" }}
                size="small"
              >
                Delete
              </Button>
              {acc?.healthStatus && (
                <Box
                  sx={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    backgroundColor: "#F8F7FC",
                    border: "1px solid #5761FE",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={acc?.healthStatus ? acc?.healthStatus : 0}
                    sx={{ width: "100%" }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#111927",
                      fontSize: "14px",
                    }}
                  >
                    {acc?.healthStatus ? acc?.healthStatus : 0}%
                  </Typography>
                </Box>
              )}
              <IconButton
                onClick={handleExpand}
                sx={{
                  borderRadius: 1,
                  backgroundColor: "#ECE9F5",
                  color: "#5761FE",
                  p: 0.5,
                  "&:hover": { backgroundColor: "#ECE9F5" },
                }}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              p: 2,
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Daily Sending Limits
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {/* <span
                style={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                50
              </span> */}
                <TextField
                  sx={{
                    width: "40px",
                    border: "none",

                    "& .MuiOutlinedInput-input": {
                      p: 0,
                      px: 1,
                      border: "none",
                    },
                    "& input": {
                      fontWeight: "700",
                      color: "#111927",
                      fontSize: "14px",
                    },
                  }}
                  InputProps={{
                    // readOnly: userType === "enterprise" ? false : true,
                    readOnly: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="This is a tooltip!" arrow>
                          <InfoOutlined
                            sx={{
                              color: alpha("#55597A", 0.5),
                              fontSize: "16px",
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={dailyLimit}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      const value =
                        e.target.value === ""
                          ? e.target.value
                          : parseInt(e.target.value);
                      setDailyLimit(value);
                    }
                  }}
                />
                <Tooltip title="Number of emails you can send per DAY" arrow>
                  <InfoOutlined
                    sx={{ color: alpha("#55597A", 0.5), fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Hourly Sending Limits
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {/* <span
                style={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                10
              </span> */}
                <TextField
                  sx={{
                    width: "40px",
                    border: "none",
                    "& .MuiOutlinedInput-input": {
                      p: 0,
                      px: 1,
                      border: "none",
                    },
                    "& input": {
                      fontWeight: "700",
                      color: "#111927",
                      fontSize: "14px",
                    },
                  }}
                  InputProps={{
                    // readOnly: userType === "enterprise" ? false : true,
                    readOnly: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="This is a tooltip!" arrow>
                          <InfoOutlined
                            sx={{
                              color: alpha("#55597A", 0.5),
                              fontSize: "16px",
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={hourlyLimit}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      const value =
                        e.target.value === ""
                          ? e.target.value
                          : parseInt(e.target.value);
                      setHourlyLimit(value);
                    }
                  }}
                />
                <Tooltip title="Number of emails you can send per HOUR" arrow>
                  <InfoOutlined
                    sx={{ color: alpha("#55597A", 0.5), fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Delay Between Emails
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    color: "#111927",
                    fontSize: "14px",
                  }}
                >
                  <TextField
                    sx={{
                      width: "40px",
                      border: "none",
                      "& .MuiOutlinedInput-input": {
                        p: 0,
                        px: 1,
                        border: "none",
                      },
                      "& input": {
                        fontWeight: "700",
                        color: "#111927",
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{
                      // readOnly: userType === "enterprise" ? false : true,
                      readOnly: true,
                    }}
                    variant="outlined"
                    value={delay}
                    onChange={(e) => {
                      if (isValidInput(e.target.value)) {
                        const value =
                          e.target.value === ""
                            ? e.target.value
                            : parseInt(e.target.value);
                        setDelay(value);
                      }
                    }}
                  />{" "}
                  min
                </span>

                <Tooltip title="Random delay between the emails you send" arrow>
                  <InfoOutlined
                    sx={{ color: alpha("#55597A", 0.5), fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Delivery Score
              </Typography>
              <Box
                sx={{
                  borderRadius: 4,
                  px: 1,
                  backgroundColor: "#00AA38",
                  display: "inline",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "#ffffff",
                  }}
                >
                  {acc?.performance?.sending?.value
                    ? acc?.performance?.sending?.value +
                      acc?.performance?.sending?.unit
                    : 0 + "%"}
                  {/* 99.5% */}
            {/* </Typography>
              </Box>
            </Box> */}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Open Tracking
              </Typography>
              <IOSSwitch onChange={handleOpenTracking} checked={openTracking} />
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Click Tracking
              </Typography>
              <IOSSwitch
                onChange={handleClickTracking}
                checked={clickTracking}
              />
            </Box>
          </Box>
          {expanded && (
            <Stack
              sx={{
                borderRadius: 2,
                p: 2,
                backgroundColor: "#F8F7FC",
                border: "1px solid #ECE9F5",
              }}
              rowGap={2}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#5761FE",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <LanguageOutlined />
                Domain:{" "}
                <span style={{ color: "#000000" }}>
                  {acc.senderEmail.split("@")?.[1]}
                </span>
              </Typography>
              <Stack
                rowGap={2}
                sx={{
                  border: "1px solid #ECE9F5",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "700", color: "#111927", fontSize: "12px" }}
                >
                  Click to Setup or Fix Errors:
                </Typography>
                <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomButton
                    label={"SPF"}
                    error={
                      acc?.rapidApiData?.records?.SPF?.length > 0 ? false : true
                    }
                    handleClick={() => {}}
                    acc={acc}
                  />
                  {/* <CustomButton
                  label={"DKIM"}
                  error={
                    acc?.rapidApiData?.records?.DKIM?.length > 0 ? false : true
                  }
                  // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DKIM === "Found") ? false : true}
                  handleClick={() => { }}
                  acc={acc}
                /> */}
                  <CustomButton
                    label={"DMARC"}
                    error={
                      acc?.rapidApiData?.records?.DMARC?.length > 0
                        ? false
                        : true
                    }
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DMARC === "Found") ? false : true}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                    label={"MX"}
                    error={
                      acc?.rapidApiData?.records?.MX?.length > 0 ? false : true
                    }
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.MX === "Found") ? false : true}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  {/* <CustomButton
                  label={"Redirection"}
                  error={true}
                  handleClick={() => {}}
                /> */}
                  <CustomButton
                    label={"Custom Tracking"}
                    error={false}
                    handleClick={() => {}}
                    acc={acc}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </Box>
              </Stack>
              {/* <GooglePostMaster /> */}
            </Stack>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "red",
            }}
          >
            <ReportProblemOutlined sx={{ mr: 1 }} />
            Delete Sending Account
          </Typography>
          <IconButton onClick={handleClose} sx={{ pr: 0, color: "#111927" }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "16px", color: "#111927" }}
          >
            Are you sure you want to permanently delete the account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 1 }}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "#e30000" },
            }}
            onClick={() => handleDelete(acc._id)}
            autoFocus
            disabled={isDeleting}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const EmailAccounts = ({ sectionRef, id, userEmail, userType }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [expandAll, setExpandAll] = useState(false);
  const handleExpandAll = () => {
    setExpandAll(!expandAll);
  };
  const [mailAccounts, setMailAccounts] = useState([]);
  const connectedAccounts =
    mailAccounts?.filter((acc) => acc?.assigned?.campaignId !== null).length ||
    0;
  const [selected, setSelected] = useState([]);
  const [dialogType, setDialogType] = useState("");
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const location = useLocation();
  const getSearchParams = (search) => new URLSearchParams(search);
  const searchParams = useMemo(
    () => getSearchParams(location?.search),
    [location?.search]
  );
  const [code, setCode] = useState(searchParams?.get("code"));
  // console.log("code=======>", code);
  const EmailProviders = Object.freeze({
    GOOGLE: "google",
    MICROSOFT: "microsoft",
  });
  const handleConnectGoogleAccount = async () => {
    setSessionStorage("emailProviderType", EmailProviders.GOOGLE);
    const expirationTimeCamp = 2 * 60 * 1000;
    Cookies.set("campaignId", null, { expires: expirationTimeCamp });
    const expirationTime = 1 * 60 * 1000;
    Cookies.set("emailProviderType", EmailProviders.GOOGLE, {
      expires: expirationTime,
    });
    const params = new URLSearchParams({
      redirect_uri: googleWebAppConfig.redirect_uri,
      client_id: googleWebAppConfig.client_id,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: googleWebAppConfig.scopes,
    });
    const url = `${googleWebAppConfig.oauth_url}?${params.toString()}`;
    window.open(url, "_self");
  };
  const handleConnectMicrosoftAccount = async () => {
    const expirationTimeCamp = 2 * 60 * 1000;
    Cookies.set("campaignId", null, { expires: expirationTimeCamp });
    setSessionStorage("emailProviderType", EmailProviders.MICROSOFT);
    const expirationTime = 5 * 60 * 1000;
    Cookies.set("emailProviderType", EmailProviders.MICROSOFT, {
      expires: expirationTime,
    });
    const params = new URLSearchParams({
      redirect_uri: microsoftWebAppConfig.redirect_uri,
      client_id: microsoftWebAppConfig.client_id_azure,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: microsoftWebAppConfig.scopes,
    });
    const url = `${microsoftWebAppConfig.oauth_url}?${params?.toString()}`;
    window.open(url, "_self");
  };
  const [tostrDisplayed, setToastrDisplayed] = useState(false);
  const exchangeCodeForAccessToken = async (code) => {
    const accessTokenUrlsMap = {
      [EmailProviders.GOOGLE]: "/getAccessTokenEnterprice",
      [EmailProviders.MICROSOFT]: "/exchangeCodeForAccessTokenMicrosoft2",
    };
    if (!tostrDisplayed) {
      const emailProviderType = getSessionStorage("emailProviderType");
      console.log(
        "emailProviderType====>",
        accessTokenUrlsMap[emailProviderType]
      );
      const res = await axios.post(
        `${API_SERVICE_BACKEND}/mailbox${accessTokenUrlsMap[emailProviderType]}`,
        {
          code,
          createdBy: userEmail,
        }
      );
      // console.log("res======>", res);
      return res.data;
    }
  };
  useEffect(() => {
    const codeFromUrl = searchParams.get("code");
    // console.log("codeFromUrl========>", codeFromUrl);
    if (code && !tostrDisplayed) {
      setToastrDisplayed(true);
      setCode(code);
      setLoading(true);
      exchangeCodeForAccessToken(code)
        .then((res) => {
          let toastrCount = false;
          if (!toastrCount) {
            if (typeof res.resource == "number" && res.resource >= 1) {
              toast.error("Mail box already exist");
              toastrCount = true;
            } else {
              toast.success("Mail box added successfully");
              toastrCount = true;
              // console.log(res.resource.error, "=====error");
            }
            // setTimeout(() => {
            navigate("/dashboard/account", { replace: true });
            // }, 1000);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [
    tostrDisplayed,
    /*code*/
  ]);

  const handleClickOpen = (type) => {
    setDialogType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDialogType("");
  };
  const handleChange = (e, acc) => {
    if (e.target.checked) {
      setSelected([...selected, acc]);
    } else {
      setSelected(selected.filter((e) => e !== acc));
    }
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const baseUrl = API_SERVICE_BACKEND;
  // const email = localStorage.getItem("userEmail");

  // const getUserInfo = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://campaign-app-server-azure-pipeline.azurewebsites.net/api/v1/main/getuserdetail/${email}`
  //     );
  //     const userData = res.data.data;
  //     setShowInstructions(userData?.userInstructions);
  //   } catch (error) {
  //     throw error; // You can choose to re-throw the error or handle it here
  //   }
  // };

  const fetchAllMailBoxes = async (account) => {
    try {
      setLoading(true);
      // const res = await fetch(`${API_SERVICE_BACKEND}/getuserAnalytics?page=${page}&limit=${rowsPerPage}&search=${encodeURIComponent(searchText)}`);
      const res = await fetch(`${baseUrl}/usermailbox/${account}`);
      const userdata = await res.json();
      setLoading(false);
      setMailAccounts(userdata?.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    // getUserInfo(userEmail)
    fetchAllMailBoxes(userEmail);
  }, []);
  // console.log("mail accounts", mailAccounts);

  const handlePageChange = (e, value) => {
    setPage(value);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const filteredAccounts = mailAccounts.filter((acc) =>
    acc.senderEmail.includes(search)
  );
  const start = (page - 1) * limit;
  const end = limit * page;
  const paginatedAccounts = filteredAccounts.slice(start, end);

  // const handleDelete = async (mailboxId) => {
  //   const id = mailboxId
  //   try {
  //     const res = await axios.delete(
  //       `${API_SERVICE_BACKEND}/mailbox/${id}`,
  //     );
  //     if (res.status === 200) {
  //       await fetchAllMailBoxes(userEmail);

  //     } else {
  //       console.error('Failed to unassign the mailbox:', res);
  //       alert('Failed to unassign the mailbox');
  //     }
  //   } catch (err) {
  //     console.error('Error when deleting the mailbox:', err);
  //     alert('An error occurred while deleting the mailbox');
  //   } finally {
  //     setLoading(false);
  //   }

  // };

  const pageCount = Math.ceil(filteredAccounts.length / limit);
  useEffect(() => {
    const saveBtn = document.getElementById("save-settings-btn");
    if (saveBtn) {
      saveBtn.style.display = "none";
    }
    return () => {
      if (saveBtn) {
        saveBtn.style.display = "flex";
      }
    };
  }, []);
  return (
    <>
      <Box sx={{ paddingX: "30px" }}>
        <div
          ref={sectionRef}
          id={id}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "32px",
                  color: "#111927",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <span style={{ fontWeight: "700" }}>Sending Accounts</span>
                {!isSmDown && (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: alpha("#111927", 0.1) }}
                    />
                    <span style={{ fontWeight: "500", fontSize: "20px" }}>
                      Linked Accounts
                    </span>
                  </>
                )}
              </Typography>
              <Box
                sx={{
                  borderRadius: 4,
                  px: 1,
                  backgroundColor: "#00AA38",
                  display: { xs: "none", sm: "inline" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#ffffff",
                  }}
                >
                  {connectedAccounts} /
                  <span style={{ fontWeight: "500", opacity: 0.6 }}>
                    {mailAccounts?.length || 0}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#111927",
                  }}
                >
                  Linked Accounts
                </Typography>
                <Box
                  sx={{
                    borderRadius: 4,
                    px: 1,
                    backgroundColor: "#00AA38",
                    display: "inline",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#ffffff",
                    }}
                  >
                    {connectedAccounts} /
                    <span style={{ fontWeight: "500", opacity: 0.6 }}>
                      {mailAccounts?.length || 0}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Stack rowGap={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Search by email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined sx={{ ml: "5px" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{
                  width: { xs: "80%", md: 300 },
                  height: 40,
                  backgroundColor: "white",
                  "& div": { pl: 0.3 },
                  "& div fieldset": {
                    borderRadius: "8px",
                    border: "1px solid #E4E4E5",
                  },
                  "& div input": {
                    py: 1.3,
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    "&::placeholder": {
                      color: "rgba(40, 40, 123, 0.5)",
                    },
                  },
                }}
                value={search}
                onChange={handleSearchChange}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Button
                  sx={{
                    borderRadius: 1,
                    borderColor: "#ECE9F5",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#111927",
                    px: { xs: 1, sm: 2 },
                    backgroundColor: "#ffffff",
                    "&:hover": {
                      borderColor: "#ECE9F5",
                      backgroundColor: "#ffffff",
                    },
                  }}
                  variant="outlined"
                  disableRipple
                  endIcon={expandAll ? <ExpandLess /> : <ExpandMore />}
                  onClick={handleExpandAll}
                >
                  {expandAll ? "Shrink All" : "Expand All"}
                </Button>
                {selected.length > 0 ? (
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: 1,
                      backgroundColor: "#5761FE",
                    }}
                    onClick={() => handleClickOpen("delete")}
                  >
                    <DeleteOutline />
                    {!isSmDown && <Typography>Delete</Typography>}{" "}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      display: userType === "enterprise" ? "flex" : "none",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: 1,
                      backgroundColor: "#5761FE",
                    }}
                    onClick={() => handleClickOpen("add")}
                  >
                    <AddOutlined />
                    {!isSmDown && (
                      <Typography style={{ fontSize: "14px" }}>
                        Add New
                      </Typography>
                    )}{" "}
                  </Button>
                )}
              </Box>
            </Box>

            {mailAccounts.length > 0 ? (
              <Stack
                rowGap={2}
                sx={{
                  minHeight: "300px",
                  // maxHeight: { xs: "90vh", md: "70vh" },
                  // overflow: "auto",
                  position: "relative",
                  ...scrollBarStyle,
                }}
              >
                {paginatedAccounts.length > 0 ? (
                  paginatedAccounts.map((acc, index) => (
                    <MailItem
                      index={index}
                      acc={acc}
                      selected={selected}
                      handleChange={handleChange}
                      expandAll={expandAll}
                      userType={userType}
                      setOpenSnackbar={setOpenSnackbar}
                      fetchAllMailBoxes={fetchAllMailBoxes}
                      userEmail={userEmail}
                    />
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      No Results
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    No Accounts
                  </Typography>
                )}
              </Box>
            )}
            {paginatedAccounts.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    Rows per page
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={limit}
                    //label="Rows per page"
                    onChange={handleLimitChange}
                    size="small"
                    sx={{ "& .MuiSelect-select": { width: "30px", py: "4px" } }}
                    variant="outlined"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </Box>
              </Box>
            )}
          </Stack>
        </div>

        <Dialog open={open} onClose={handleClose} fullScreen={isSmDown}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {dialogType === "add"
                ? "Connect Your Account"
                : dialogType === "delete"
                ? "Delete Account"
                : ""}{" "}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{
                marginLeft: "10px",
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            {dialogType === "add" ? (
              <>
                <ConnectNewAccount
                  handleConnectGoogleAccount={handleConnectGoogleAccount}
                  handleConnectMicrosoftAccount={handleConnectMicrosoftAccount}
                  showInstructions={true}
                />
                {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100px", sm: "auto" },
                      height: { xs: "100px", sm: "auto" },
                    }}
                    onClick={handleConnectGoogleAccount}
                  >
                    <GmailIcon />
                  </Button>

                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Google Workspace
                  </Typography>
                </Stack>
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100px", sm: "auto" },
                      height: { xs: "100px", sm: "auto" },
                      // height: "144px",
                      // width:'144px'
                    }}
                    onClick={handleConnectMicrosoftAccount}
                  >
                    <MicrosoftIcon />
                  </Button>

                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Microsoft
                  </Typography>
                </Stack>
              </Box> */}
              </>
            ) : dialogType === "delete" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Typography>
                  Are you sure you want to delete the
                  {selected.length > 1
                    ? ` ${selected.length} accounts`
                    : " account"}
                  ?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 1 }}
                    color="error"
                    onClick={handleClose}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          ContentProps={{
            sx: {
              background: "#5761FE",
            },
          }}
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          message="Copied  Successfully!"
        />
      </Box>
    </>
  );
};

export default EmailAccounts;
