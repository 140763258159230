import { lazy } from 'react';
import { Layout as AuthClassicLayout } from 'src/layouts/auth/classic-layout';
import { Layout as AuthModernLayout } from 'src/layouts/auth/modern-layout';

const ForgotPasswordClassicPage = lazy(() => import('src/pages/auth-demo/forgot-password/classic'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth-demo/forgot-password/modern'));
const LoginClassicPage = lazy(() => import('src/pages/auth-demo/login/classic'));
const LoginModernPage = lazy(() => import('src/pages/auth-demo/login/modern'));
const RegisterClassicPage = lazy(() => import('src/pages/auth-demo/register/classic'));
const RegisterModernPage = lazy(() => import('src/pages/auth-demo/register/modern'));
const ResetPasswordClassicPage = lazy(() => import('src/pages/auth-demo/reset-password/classic'));
const ResetPasswordModernPage = lazy(() => import('src/pages/auth-demo/reset-password/modern'));
const VerifyCodeClassicPage = lazy(() => import('src/pages/auth-demo/verify-code/classic'));
const VerifyCodeModernPage = lazy(() => import('src/pages/auth-demo/verify-code/modern'));
const SetNewPasswordModernPage = lazy(() => import('src/pages/auth-demo/setNew-password/modern'));
const PasswordEmailModernPage = lazy(() => import('src/pages/auth-demo/password-email/modern'));
const PasswordResetModernPage = lazy(() => import('src/pages/auth-demo/password-reset/modern'));
const PricingPage = lazy(() => import('src/pages/pricingPage'))
const WorkspacePage = lazy(() => import('src/pages/auth-demo/workspace'));

export const authDemoRoutes = [
  {
    path: 'auth-main',
    children: [
      {
        path: 'forgot-password',
        children: [
          {
            path: 'classic',
            element: (
              <AuthClassicLayout>
                <ForgotPasswordClassicPage />
              </AuthClassicLayout>
            )
          },
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <ForgotPasswordModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'login',
        children: [
          {
            path: 'classic',
            element: (
              <AuthClassicLayout>
                <LoginClassicPage />
              </AuthClassicLayout>
            )
          },
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <LoginModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'register',
        children: [
          {
            path: 'classic',
            element: (
              <AuthClassicLayout>
                <RegisterClassicPage />
              </AuthClassicLayout>
            )
          },
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <RegisterModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'reset-password',
        children: [
          {
            path: 'classic',
            element: (
              <AuthClassicLayout>
                <ResetPasswordClassicPage />
              </AuthClassicLayout>
            )
          },
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <ResetPasswordModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'verify-code',
        children: [
          {
            path: 'classic',
            element: (
              <AuthClassicLayout>
                <VerifyCodeClassicPage />
              </AuthClassicLayout>
            )
          },
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <VerifyCodeModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'setNew-password',
        children: [
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <SetNewPasswordModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'password-reset',
        children: [
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <PasswordResetModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'password-email',
        children: [
          {
            path: 'v2',
            element: (
              <AuthModernLayout>
                <PasswordEmailModernPage />
              </AuthModernLayout>
            )
          }
        ]
      },
      {
        path: 'pricingPage',
        element: <PricingPage />
      },
      {
        path: 'workspace',
        element: <WorkspacePage />
      }
    ]
  }
];
