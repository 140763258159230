export const paths = {
  index: "/",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
    auth0: {
      callback: "/auth/auth0/callback",
      login: "/auth/auth0/login",
    },
    jwt: {
      login: "/auth/jwt/login",
      register: "/auth/jwt/register",
    },
    firebase: {
      login: "/auth/firebase/login",
      register: "/auth/firebase/register",
    },
    amplify: {
      confirmRegister: "/auth/amplify/confirm-register",
      forgotPassword: "/auth/amplify/forgot-password",
      login: "/auth/amplify/login",
      register: "/auth/amplify/register",
      resetPassword: "/auth/amplify/reset-password",
    },
  },
  authDemo: {
    forgotPassword: {
      classic: "/auth-demo/forgot-password/classic",
      modern: "/auth-demo/forgot-password/modern",
    },
    login: {
      classic: "/auth-demo/login/classic",
      modern: "/auth-demo/login/modern",
    },
    register: {
      classic: "/auth-demo/register/classic",
      modern: "/auth-demo/register/modern",
    },
    resetPassword: {
      classic: "/auth-demo/reset-password/classic",
      modern: "/auth-demo/reset-password/modern",
    },
    verifyCode: {
      classic: "/auth-demo/verify-code/classic",
      modern: "/auth-demo/verify-code/modern",
    },
    pricingPage: "/auth-main/pricingPage",
    workspace: "/auth-main/workspace",
  },
  dashboard: {
    // index: "/dashboard",
    bdrAiAgent: "/dashboard/bdraiagent",
    dashboard: "/dashboard/dashboard",
    emailReplies: "/dashboard/emailreplies",
    lists: "/dashboard/prospect/lists",
    search: "/dashboard/prospect/search",
    enrich: "/dashboard/prospect/enrich",
    validate: "/dashboard/prospect/validate",
    cleanUp: "/dashboard/prospect/cleanup",
    intentData: "/dashboard/prospect/intentdata",
    engage: "/dashboard/engage",
    unifiedInbox: "/dashboard/engage/unifiedinbox",
    warmup: "/dashboard/engage/warmup",
    engageCampaigns: "/dashboard/engage/engagecampaigns",
    crm: {
      index: "/dashboard/crm/index",
      lists: "/dashboard/crm/lists",
      leads: "/dashboard/leads/all",
      search: "/dashboard/crm/search",
      enrich: "/dashboard/crm/enrich",
      validate: "/dashboard/crm/validate",
      cleanUp: "/dashboard/crm/cleanup",
      intentData: "/dashboard/crm/intentdata",
    },
    emailAccounts: {
      manage: "/dashboard/emailaccounts/manage",
      warmup: "/dashboard/emailaccounts/warmup",
      orderStatus: "/dashboard/emailaccounts/orderstatus",
      add: "/dashboard/emailaccounts/orderstatus/add",
    },
    freeTools: {
      index: "/dashboard/freetools",
      spamChecker: "/dashboard/freetools/spamchecker",
      unifiedCalendar: "/dashboard/freetools/unifiedcalendar",
      unifiedInbox: "/dashboard/freetools/unifiedinbox",
      meetingScheduler: {
        index: "/dashboard/freetools/meetingscheduler",
        eventTypes: "/dashboard/freetools/meetingscheduler/event_types"
      }
    },
    academy: {
      index: "/dashboard/academy",
      courseDetails: "/dashboard/academy/courses/:courseId",
    },
    integrations: "/dashboard/integrations",
    leads: "/dashboard/leads",
    account: "/dashboard/account",
    analytics: "/dashboard/analytics",
    blank: "/dashboard/blank",
    blog: {
      index: "/dashboard/blog",
      postDetails: "/dashboard/blog/:postId",
      postCreate: "/dashboard/blog/create",
    },
    campaigns: "/dashboard/campaigns",
    allContacts: "/dashboard/contacts/all",
    contacts: "/dashboard/contacts",
    conversations: "/dashboard/conversations",
    calendar: "/dashboard/calendar",
    chat: "/dashboard/chat",
    supportCenter: "/dashboard/supportCenter",
    crypto: "/dashboard/crypto",
    customers: {
      index: "/dashboard/customers",
      details: "/dashboard/customers/:customerId",
      edit: "/dashboard/customers/:customerId/edit",
    },
    ecommerce: "/dashboard/ecommerce",
    fileManager: "/dashboard/file-manager",
    invoices: {
      index: "/dashboard/invoices",
      details: "/dashboard/invoices/:orderId",
    },
    jobs: {
      index: "/dashboard/jobs",
      create: "/dashboard/jobs/create",
      companies: {
        details: "/dashboard/jobs/companies/:companyId",
      },
    },
    kanban: "/dashboard/kanban",
    logistics: {
      index: "/dashboard/logistics",
      fleet: "/dashboard/logistics/fleet",
    },
    mail: "/dashboard/mail",
    replies: "/dashboard/replies",
    orders: {
      index: "/dashboard/orders",
      details: "/dashboard/orders/:orderId",
    },
    products: {
      index: "/dashboard/products",
      create: "/dashboard/products/create",
    },
    social: {
      index: "/dashboard/social",
      profile: "/dashboard/social/profile",
      feed: "/dashboard/social/feed",
    },
  },
  components: {
    index: "/components",
    dataDisplay: {
      detailLists: "/components/data-display/detail-lists",
      tables: "/components/data-display/tables",
      quickStats: "/components/data-display/quick-stats",
    },
    lists: {
      groupedLists: "/components/lists/grouped-lists",
      gridLists: "/components/lists/grid-lists",
    },
    forms: "/components/forms",
    modals: "/components/modals",
    charts: "/components/charts",
    buttons: "/components/buttons",
    typography: "/components/typography",
    colors: "/components/colors",
    inputs: "/components/inputs",
  },
  docs: "https://material-kit-pro-react-docs.devias.io",
  401: "/401",
  404: "/404",
  500: "/500",
};
