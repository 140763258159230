import axios from "axios";
import { API_SERVICE_BACKEND, API_SERVICE_BACKEND_2 } from "src/config";

class CronofyApi {
  getAccessToken = async ({ code, redirect_uri }) => {
    const accessToken = await axios
      .get(`${API_SERVICE_BACKEND}/cronofy/getAccessToken`, {
        params: {
          code,
          redirect_uri,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return accessToken;
  };

  conferencing = async ({ token, redirect_uri, email, profile_email }) => {
    const conferencing = await axios
      .post(`${API_SERVICE_BACKEND}/cronofy/conferencingService`, {
        token,
        redirect_uri,
        email,
        profile_email,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return conferencing;
  };

  conferencingByCampaign = async ({ token, redirect_uri, email, profile_email,campaign_id }) => {
    const conferencing = await axios
        .post(`${API_SERVICE_BACKEND}/cronofy/conferencingServiceByCampaign`, {
          token,
          redirect_uri,
          email,
          profile_email,
          campaign_id
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    return conferencing;
  };

  conferencingLinked = async ({ email }) => {
    const linked = await axios.post(`${API_SERVICE_BACKEND_2}/conferencing-linked`, {
      email: email,
    });
    return linked.status;
  };

  conferencingLinkedByCampaign = async ({campaign_id}) => {
      const linked = await axios.post(`${API_SERVICE_BACKEND_2}/conferencing-linked-by-campaign`, {
          campaign_id: campaign_id,
      });
      return linked.status;
  };
  getElementToken = async ({ sub, permissions, origin }) => {
    const eleToken = await axios
      .get(`${API_SERVICE_BACKEND}/cronofy/getElementToken`, {
        params: {
          sub,
          permissions: permissions.join(","),
          origin,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return eleToken;
  };

  refreshAccessToken = async ({ sub, permissions, origin }) => {
    // const eleToken = await axios
    //   .get(`${API_SERVICE_BACKEND}/cronofy/getElementToken`, {
    //     params: {
    //       sub,
    //       permissions: permissions.join(","),
    //       origin,
    //     },
    //   })
    //   .then((res) => {
    //     return res.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // return eleToken;
  };

  readEvents = async ({ accessToken }) => {
    return await axios
      .post(`${API_SERVICE_BACKEND}/cronofy/readEvents`, {
        payload: {
          accessToken,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          return res.data.events;
        } else {
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  readEventsFromSysDB = async ({ campaignId, campaignIdListStr, projFields }) => {
    return await axios
      .get(`${API_SERVICE_BACKEND}/cronofy/readEventsFromSysDB`, {
        params: {
          campaignId,
          campaignIdListStr,
          projFields: projFields.join(","),
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          return res.data.events;
        } else {
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export const cronofyApi = new CronofyApi();
