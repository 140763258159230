import { Button, Stack } from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { usePathname } from "src/hooks/use-pathname";

const NavItem = ({ item, key }) => {
  const pathname = usePathname();
  const active =
    pathname === item.path ||
    (item.childPaths && item.childPaths.includes(pathname));

  const linkProps = {
    component: RouterLink,
    href: item.path,
    state: { prev: item.path },
  };
  return (
    <Button
      key={key}
      {...linkProps}
      sx={{
        color: active ? "rgba(88, 98, 255, 1)" : "rgba(97, 97, 111, 1)",
        backgroundColor: active ? "#ffffff" : "transparent",
        borderRadius: "10px",
        px: 2,
        py: 1,
        "&:active,:focus": {
          textDecoration: "none",
          color: "rgba(88, 98, 255, 1)",
        },
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "#ffffff",
          color: active ? "rgba(88, 98, 255, 1)" : "rgba(97, 97, 111, 1)",
        },
      }}
    >
      {item.title}
    </Button>
  );
};

export const HorizontalTabs = ({ sections }) => {
  const pathname = usePathname();
  const parentSection = sections?.find((section) =>
    section?.items?.some(
      (item) => item?.path === pathname || item?.childPaths?.includes(pathname)
    )
  );

  const paths = parentSection
    ? parentSection?.items?.flatMap((e) => [e.path, ...(e.childPaths || [])])
    : [];
  const isVisible = paths?.includes(pathname);
  return (
    <Stack
      component="nav"
      sx={{
        display:
          isVisible && parentSection?.items?.length > 1 ? "block" : "none",
      }}
    >
      <Stack
        component="ul"
        direction={"row"}
        sx={{
          backgroundColor: "rgba(233, 233, 236, 1)",
          border: "1px solid rgba(224, 224, 229, 1)",
          borderRadius: "10px",
          gap: 1,
          p: 0.5,
          width: "fit-content",
          m: 0,
        }}
      >
        {parentSection?.items?.map((item, index) => (
          <NavItem item={item} key={index} />
        ))}
      </Stack>
    </Stack>
  );
};
