import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useEffect, useState } from "react";
import Features from "./features";
import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";
import toast from "react-hot-toast";
import AlertBox from "../../../../layouts/alerts/AlertBox";
import CampaignList from "../../../../layouts/alerts/CampaignList";
import "../style.css";
import { getSessionStorage } from "src/utils/storage";
const Plan = (props) => {
  const { mode, details, getUserInfo } = props;
  // console.log(details, "====>>>>>");

  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [billingMonthly, setBillingMonthly] = useState("monthly");
  const [productData, setProductData] = useState();
  const email = getSessionStorage("userEmail");
  const [inputVisible, setInputVisible] = useState(false);
  const [showInputBox, setShowInputBox] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [disBtn, setDisBtn] = useState();
  const [alertModal, setAlertModal] = useState(false);
  const [priceid, serPriceid] = useState();
  const [campList, setCampList] = useState();
  const [campCount, setCampCount] = useState(false);
  const [campaignData, setCampignData] = useState();
  const [count, setCount] = useState(0);
  const [stripeDiscountedProduct, setStripeDiscountedProduct] = useState();

  const [addPrice, setAddPrice] = useState();
  usePageView();

  useEffect(() => {
    agentCount();
  }, []);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });

  let agentCount = async () => {
    try {
      setDisBtn(true);
      let data = {
        email: email,
      };
      const response = await axios.post(
        `${API_SERVICE_BACKEND}/campaginCount`,
        data
      );
      // console.log(response.data, "<response data");
      setCount(response.data.count);
      setCampList(response.data);
      setDisBtn(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setDisBtn(false);
    }
  };
  const [conponError, setCouponError] = useState("")
  const [loading, setLoading] = useState(false);
  const agreeFunction = async (price, coupon) => {
    try {
      setDisBtn(true);
      setLoading(true)
      let data = {
        planId: price,
        customerId: details?.customerId || null,
        userEmail: email,
        qty: 1,
        promoId: coupon,
        additionalData: inputValue > 0 ? addPrice : {},
        page: "subscription"
      };
      if (
        details &&
        details.userSubscription &&
        Object.keys(details.userSubscription).length !== 0 &&
        count > campaignData?.metadata?.Agents
      ) {
        setCampCount(true);
      } else {
        const response = await axios.post(
          `${API_SERVICE_BACKEND}/updatePlan`,
          data
        );
        if (response.status === 200) {
          if (response.data.url) {
            window.location.href = response.data.url;
          } else {
            toast.success("Plan Updated Successfully");
            getproductlist();
            getInvoiceData();
            getUserInfo(email);
          }
        } else {
          throw response;
        }
        setLoading(false)
        setDisBtn(false);
        setAlertModal(false);
      }
    } catch (error) {
      // console.log(error, "===");
      if (error.response.status == 400) {
        setCouponError(error.response.data.message)
        setLoading(false)
        return;
      }
      toast.error(error.message);
      setDisBtn(false);
      setAlertModal(false);
      setLoading(false)
    }
  };

  const selectCampUpdate = async () => {
    try {
      let data = {
        planId: priceid,
        customerId: details?.customerId || null,
        userEmail: email,
        qty: 1,
        additionalData: inputValue > 0 ? addPrice : {},
      };
      const response = await axios.post(
        `${API_SERVICE_BACKEND}/updatePlan`,
        data
      );
      if (response.status === 200) {
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          toast.success("Plan Updated Successfully");
          getproductlist();
          getInvoiceData();
          getUserInfo(email);
        }
      } else {
        throw response;
      }
      setDisBtn(false);
      setAlertModal(false);
    } catch (error) {
      // console.log(error, "===");
      toast.error(error.message);
      setDisBtn(false);
      setAlertModal(false);
    }
  };

  const handleCheckoutForm = async (price, obj) => {
    // console.log("runnnnnnnnnnn");
    serPriceid(price);
    setDisBtn(true);
    setAlertModal(true);
    setCampignData(obj);
  };

  const getStripeProducts = async () => {
    const res = await axios.post("https://api.stripe.com/v1/products", {
      planId: "price_1OHWefFrYeHPQNJAqbpJfb7G",
      userEmail: email,
    });
    if (res) {
      window.open(res.data.url, "_blank");
    }
  };

  const getproductlist = async () => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/stripe-product-list/${email}`
      );
      // console.log(res, '====>>>>>>res')
      const data = res?.data;
      // console.log(res, "product res");
      if (data) {
        setProductData(data);
        // if (details?.planStatus === "inactive" || details?.planStatus === "expired") {
        //   setProductData(arrangedData);
        // }
        // if (details?.planStatus === "active") {
        //   const filteredData = arrangedData.filter((e) => e.product.id === details?.planId);
        //   setInputVisible(!inputVisible);
        //   setProductData(filteredData);
        // }
      } else {
        console.error("Response data is empty or undefined");
      }
    } catch (error) {
      console.error("Error fetching product list:", error.message);
      // Handle the error as needed, e.g., show a user-friendly error message
    }
  };

  const getStripeProduct = async () => {
    // dev
    const stripeProducts = ["prod_QYtThuYS9lfXGM", "prod_QYtUHYtoW3GJUB", "prod_QYtUNNVelqjdH0"];
    // production
    // const stripeProducts = ["prod_QZfEUHBDtadoIg", "prod_QZfE2fT0NZ0hkz", "prod_QZfEQS9pJBQuSo"];
    try {
      if(stripeProducts.includes(details?.userSubscription?.productId)) {
        const res = await axios.get(
          `${API_SERVICE_BACKEND}/stripe-product/?productId=${details?.userSubscription?.productId}&priceId=${details?.userSubscription?.priceId}`
        );
        const data = res?.data;
        console.log(data, "product res");
        if (data) {
          setStripeDiscountedProduct(data.product);
        } else {
          console.error("Response data is empty or undefined");
        }
      }
    } catch (error) {
      console.error("Error fetching product list:", error.message);
    }
  };

  const handleInputBox = (val) => {
    setShowInputBox(val);
    if (val === false) {
      setInputValue(0);
    }
  };

  const handleInputChange = (e, data) => {
    if (e.target.value >= 0 || e.target.value % 1 !== 0) {
      if (Number(e.target.value) > Number(data?.Agents)) {
        setInputValue(0);
      } else {
        setInputValue(e.target.value);
      }
    }
  };

  const handlePlusMinus = (type, data, addAgent) => {
    if (type === "plus") {
      var remainAgent = 0;
      if (Number(addAgent) >= 0) {
        remainAgent = Number(data?.Agents) - Number(addAgent);
      } else {
        remainAgent = Number(data?.Agents);
      }
      if (Number(inputValue) + 1 > remainAgent) {
        setInputValue(0);
      } else {
        setInputValue(Number(inputValue) + 1);
      }
    } else if (type === "minus") {
      if (Number(inputValue) > 0) {
        setInputValue(Number(inputValue) - 1);
      } else {
        setInputValue(0);
      }
    }
  };

  const handleInputBlur = (priceid) => {
    setAddPrice({ quantity: inputValue, price: priceid });
  };

  const buyExtraAgent = async (id, SubscriptionId) => {
    if (inputValue > 0) {
      let data = {
        price: id,
        quantity: parseInt(inputValue),
        email: details.email,
        subscriptionId: SubscriptionId,
        customer: details.customerId,
      };
      try {
        setDisBtn(true);
        const res = await axios.post(
          `${API_SERVICE_BACKEND}/aiAgent-checkout-session`,
          data
        );
        if (res) {
          window.open(res.data.url, "_blank");
        }
        setDisBtn(false);
      } catch (error) {
        console.log(error, "===");
        setDisBtn(false);
      }
    }
  };
  const [invoiceData, setInviceData] = useState();
  const [seeData, setSeeData] = useState(true);

  const toggleSeeData = () => {
    setSeeData(!seeData);
  };
  const getInvoiceData = async (limit) => {
    try {
      const res = await axios.post(`${API_SERVICE_BACKEND}/getInvoice/`, {
        customerId: details.customerId,
        limit: limit || 5,
      });
      if (res) {
        setInviceData(res.data);
      }
      toggleSeeData();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(invoiceData, "====invoiceData");

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const [cardData, setCardData] = useState();
  const getCards = async () => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getCreds/` + details.customerId
      );
      if (res) {
        setCardData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const geterateBillingPortal = async () => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/billingPortal/` + details.customerId
      );
      if (res) {
        window.open(res.data.url, "_blank");
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStripeProduct();
    getproductlist();
    if (details) {
      getCards();
      getInvoiceData();
    }
  }, [details]);

  return (
    <>
      {alertModal ? (
        <AlertBox
          disBtn={disBtn}
          agreeFunction={agreeFunction}
          priceid={priceid}
          setDisBtn={setDisBtn}
          setAlertModal={setAlertModal}
          conponError={conponError}
          loading={loading}
        />
      ) : (
        ""
      )}

      {campCount ? (
        <CampaignList
          count={count}
          campCount={campCount}
          campList={campList}
          setCampCount={setCampCount}
          priceid={priceid}
          selectCampUpdate={selectCampUpdate}
          setDisBtn={setDisBtn}
          campaignData={campaignData}
        />
      ) : (
        ""
      )}

      {stripeDiscountedProduct && <Grid item xs={12}>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            border: "1px solid #ECE9F5",
            p: 2,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "flex-start",
            alignItems: "flex-start",
            "&.MuiCard-root.MuiPaper-root": {
              boxShadow: "0px 12px 16px -4px #10182814",
              padding: 0,
            },
          }}
        >
          <CardContent
            sx={{
              width: { xs: "100%", sm: "225px" },
              height: { xs: "fit-content", sm: "100%" },
              display: "flex",
              justifyContent: "space-between",
              padding: "24px",
            }}
            className="addit-div"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "center", sm: "center" },
                width: "100%",
                gap: "24px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "var(--primary-dark-purple, #050C46)",
                  backgroundColor: "#ECE9F5",
                  borderRadius: "16px",
                  padding: "4px 10px 4px 10px",
                  textAlign: "center",
                }}
              >
                {stripeDiscountedProduct?.metadata?.packageName}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: 700,
                      lineHeight: "32px",
                      color: "var(--primary-dark-purple, #050C46)",
                    }}
                  >
                    {currencyFormatter?.format(stripeDiscountedProduct?.price?.unit_amount / 100)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "var(--text-grey, #475467)",
                      fontFamily: "Inter",
                    }}
                  >
                    /year
                  </Typography>
                </Box>
              </Box>
              <CardActions sx={{ width: "100%", p: 0 }}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled
                  sx={{ borderRadius: "8px" }}
                >
                  Current Plan
                </Button>
              </CardActions>
            </Box>
          </CardContent>
          <Divider
            sx={{
              height: { xs: "0%", sm: "100%" },
              width: { xs: "100%", sm: "0%" },
            }}
            orientation="vertical"
          />
          <CardContent
            sx={{
              p: 0,
              "&.MuiCardContent-root": {
                p: "24px",
                pt: "16px",
              },
            }}
            className="feature-div"
          >
            <Features
              featureList={stripeDiscountedProduct.features}
              plan={stripeDiscountedProduct?.metadata?.packageName}
              data={stripeDiscountedProduct?.metadata}
              // setAddPrice={setAddPrice}
              priceid={stripeDiscountedProduct?.price && stripeDiscountedProduct?.price.id}
              // subscriptionData={
              //   billingMonthly === "monthly"
              //     ? (monthlyPrice[0]?.matchesPriceId &&
              //         monthlyPrice[0]?.subscriptionData) ||
              //       {}
              //     : billingMonthly === "quarterly"
              //     ? (quartelyPrice[0]?.matchesPriceId &&
              //         quartelyPrice[0]?.subscriptionData) ||
              //       {}
              //     : (yearlyPrice[0]?.matchesPriceId &&
              //         yearlyPrice[0]?.subscriptionData) ||
              //       {}
              // }
              // inputVisible={inputVisible}
              details={details}
              // billingMonthly={
              //   billingMonthly === "monthly"
              //     ? true
              //     : billingMonthly === "quarterly"
              //     ? true
              //     : true
              // }
              // additionalData={
              //   billingMonthly === "monthly"
              //     ? (monthlyPrice[0]?.additionalData &&
              //         monthlyPrice[0]?.additionalData) ||
              //       {}
              //     : billingMonthly === "quarterly"
              //     ? (quartelyPrice[0]?.additionalData &&
              //         quartelyPrice[0]?.additionalData) ||
              //       {}
              //     : (yearlyPrice[0]?.additionalData &&
              //         yearlyPrice[0]?.additionalData) ||
              //       {}
              // }
            />
          </CardContent>
        </Card>
      </Grid>}

      <Container style={{ maxWidth: "2000px", marginTop: "20px", padding: 0 }}>
        <Grid
          container
          sx={{
            backgroundColor: "#F2F4F6",
            width: "100%",
            borderRadius: "8px",
            p: 0.4,
            border: "1px solid #F2F4F7",
          }}
          className="custom-tab"
        >
          <Grid item xs={6}>
            <Button
              // variant="contained"
              fullWidth
              sx={{
                backgroundColor:
                  mode === "trial"
                    ? billingMonthly === "monthly"
                      ? "#0071F6"
                      : "transparent"
                    : billingMonthly === "monthly"
                      ? "white"
                      : "transparent",
                color:
                  mode === "trial"
                    ? billingMonthly === "monthly"
                      ? "#fff"
                      : "#28287B"
                    : billingMonthly === "monthly"
                      ? "var(--primary-dark-purple, #050C46)"
                      : "#8181B0",
                "&:hover": {
                  backgroundColor:
                    mode === "trial"
                      ? billingMonthly === "monthly"
                        ? "#0071F6"
                        : "transparent"
                      : billingMonthly === "monthly"
                        ? "white"
                        : "transparent",
                },
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                letterSpacing: "0em",
                boxShadow:
                  billingMonthly === "monthly" && "0px 1px 2px 0px #1018280F",
                borderRadius: "5px",
                // mr: 0.5,
                py: 1,
                height: "100%",
              }}
              onClick={() => {
                setBillingMonthly("monthly");
              }}
            >
              Monthly
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              // variant="contained"
              fullWidth
              sx={{
                backgroundColor:
                  mode === "trial"
                    ? billingMonthly === "quarterly"
                      ? "#0071F6"
                      : "transparent"
                    : billingMonthly === "quarterly"
                      ? "white"
                      : "transparent",
                color:
                  mode === "trial"
                    ? billingMonthly === "quarterly"
                      ? "#fff"
                      : "#28287B"
                    : billingMonthly === "quarterly"
                      ? "var(--primary-dark-purple, #050C46)"
                      : "#8181B0",
                "&:hover": {
                  backgroundColor:
                    mode === "trial"
                      ? billingMonthly === "quarterly"
                        ? "#0071F6"
                        : "transparent"
                      : billingMonthly === "quarterly"
                        ? "white"
                        : "transparent",
                },
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                letterSpacing: "0em",
                boxShadow:
                  billingMonthly === "quarterly" && "0px 1px 2px 0px #1018280F",
                borderRadius: "5px",
                // mr: 0.5,
                py: 1,
              }}
              onClick={() => {
                setBillingMonthly("quarterly");
              }}
            >
              Quaterly{" "}
              <Typography
                sx={{
                  display: "inline",
                  backgroundColor: "#00AA38",
                  color: "#FFFFFF",
                  borderRadius: "40px",
                  padding: "4px 8px 4px 8px",
                  ml: 1,
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: "600",
                }}
              >
                Save 15%
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              // variant="contained"
              fullWidth
              sx={{
                backgroundColor:
                  mode === "trial"
                    ? billingMonthly === "yearly"
                      ? "#0071F6"
                      : "transparent"
                    : billingMonthly === "yearly"
                      ? "white"
                      : "transparent",
                color:
                  mode === "trial"
                    ? billingMonthly === "yearly"
                      ? "#fff"
                      : "#28287B"
                    : billingMonthly === "yearly"
                      ? "var(--primary-dark-purple, #050C46)"
                      : "#8181B0",
                "&:hover": {
                  backgroundColor:
                    mode === "trial"
                      ? billingMonthly === "yearly"
                        ? "#0071F6"
                        : "transparent"
                      : billingMonthly === "yearly"
                        ? "white"
                        : "transparent",
                },
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                letterSpacing: "0em",
                boxShadow:
                  billingMonthly === "yearly" && "0px 1px 2px 0px #1018280F",
                borderRadius: "5px",
                // mr: 0.5,
                py: 1,
              }}
              onClick={() => {
                setBillingMonthly("yearly");
              }}
            >
              Annually{" "}
              <Typography
                sx={{
                  display: "inline",
                  backgroundColor: "#00AA38",
                  color: "#FFFFFF",
                  borderRadius: "40px",
                  padding: "4px 8px 4px 8px",
                  ml: 1,
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: "600",
                }}
              >
                Save 25%
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
          {productData?.length > 0 &&
            productData?.map((data) => {
              let monthlyPrice;
              let quartelyPrice;
              let yearlyPrice;
              monthlyPrice =
                data.priceArray &&
                data.priceArray.length > 0 &&
                data.priceArray.filter(
                  (item) =>
                    item?.metadata?.monthType === "monthly" && item.unit_amount
                );

              quartelyPrice =
                data.priceArray &&
                data.priceArray.length > 0 &&
                data.priceArray.filter(
                  (item) =>
                    item?.metadata?.monthType === "quarterly" && item.unit_amount
                );
              yearlyPrice =
                data.priceArray &&
                data.priceArray.length > 0 &&
                data.priceArray.filter(
                  (item) =>
                    item?.metadata?.monthType === "yearly" && item.unit_amount
                );
              let isSubscribed =
                billingMonthly === "monthly"
                  ? monthlyPrice[0]?.matchesPriceId
                  : billingMonthly === "quarterly"
                    ? quartelyPrice[0]?.matchesPriceId
                    : yearlyPrice[0]?.matchesPriceId;

              let SubscriptionId =
                billingMonthly === "monthly"
                  ? monthlyPrice[0]?.matchesPriceId &&
                  monthlyPrice[0]?.subscriptionData?.SubscriptionId
                  : billingMonthly === "quarterly"
                    ? quartelyPrice[0]?.matchesPriceId &&
                    quartelyPrice[0]?.subscriptionData?.SubscriptionId
                    : yearlyPrice[0]?.matchesPriceId &&
                    yearlyPrice[0]?.subscriptionData?.SubscriptionId;

              let additionalAgentNumber =
                billingMonthly === "monthly"
                  ? monthlyPrice[0]?.matchesPriceId &&
                  monthlyPrice[0]?.subscriptionData?.additionalQuentity
                  : billingMonthly === "quarterly"
                    ? quartelyPrice[0]?.matchesPriceId &&
                    quartelyPrice[0]?.subscriptionData?.additionalQuentity
                    : yearlyPrice[0]?.matchesPriceId &&
                    yearlyPrice[0]?.subscriptionData?.additionalQuentity;

              return (
                <Grid item xs={12}>
                  <Card
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #ECE9F5",
                      // boxShadow: "0px 4px 6px -2px #10182808",
                      //box-shadow: 0px 12px 16px -4px #10182814;
                      p: 2,
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      "&.MuiCard-root.MuiPaper-root": {
                        boxShadow: "0px 12px 16px -4px #10182814",
                        padding: 0,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        width: { xs: "100%", sm: "225px" },
                        height: { xs: "fit-content", sm: "100%" },
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "24px",
                      }}
                      className="addit-div"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: { xs: "center", sm: "center" },
                          width: "100%",
                          gap: "24px",
                        }}
                      >
                        {data?.metadata?.planName === "Growth Package" ? (
                          <Box
                            sx={{
                              display: "flex",
                              backgroundColor: "#FF5C1F",
                              borderRadius: "14px",
                              padding: "4px",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "16px",
                                padding: "4px 10px",
                                color: "var(--primary-dark-purple, #050C46)",
                                width: "100%",
                                // fontFamily: "DM Sans",
                              }}
                            >
                              {data?.metadata?.planName?.charAt(0).toUpperCase() +
                                data?.metadata?.planName?.slice(1)}{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                color: "#FFFFFF",
                                padding: "4px 10px",
                                // fontFamily: "DM Sans",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              Most Popular
                            </Typography>
                          </Box>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "20px",
                              color: "var(--primary-dark-purple, #050C46)",
                              backgroundColor: "#ECE9F5",
                              borderRadius: "16px",
                              padding: "4px 10px 4px 10px",
                              // fontFamily: "DM Sans",
                            }}
                          >
                            {data?.metadata?.planName?.charAt(0).toUpperCase() +
                              data?.metadata?.planName?.slice(1)}{" "}
                          </Typography>
                        )}

                        <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "28px",
                                fontWeight: 700,
                                lineHeight: "32px",
                                color: "var(--primary-dark-purple, #050C46)",
                                // fontFamily: "DM Sans",
                              }}
                            >
                              {/* $ */}
                              {/* {billingMonthly === "monthly"
                                ? currencyFormatter?.format(
                                  monthlyPrice &&
                                  monthlyPrice[0]?.unit_amount / 100
                                )
                                : billingMonthly === "quarterly"
                                  ? currencyFormatter?.format(
                                    quartelyPrice &&
                                    quartelyPrice[0]?.unit_amount / 100
                                  )
                                  : currencyFormatter?.format(
                                    yearlyPrice &&
                                    yearlyPrice[0]?.unit_amount / 100
                                  )} */}
                              {billingMonthly === "monthly"
                                ? currencyFormatter?.format(
                                  monthlyPrice &&
                                  monthlyPrice[0]?.metadata?.monthlyPrice
                                )
                                : billingMonthly === "quarterly"
                                  ? currencyFormatter?.format(
                                    quartelyPrice &&
                                    quartelyPrice[0]?.metadata?.monthlyPrice
                                  )
                                  : currencyFormatter?.format(
                                    yearlyPrice &&
                                    yearlyPrice[0]?.metadata?.monthlyPrice
                                  )}
                              {/* ${data?.price ? data?.price?.unit_amount : "N/A"} */}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                color: "var(--text-grey, #475467)",
                                fontFamily: "Inter",
                              }}
                            >
                              /month
                              {/* {billingMonthly === "monthly"
                                ? `/month`
                                : billingMonthly === "quarterly"
                                  ? `/quarterly`
                                  : `/yearly`} */}
                            </Typography>
                          </Box>
                          {billingMonthly !== "monthly" && (
                            <Typography
                              sx={{
                                color: "#475467",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              style={{ marginTop: "6px" }}
                            >
                              {billingMonthly === "quarterly"
                                ? "Billed Quarterly"
                                : billingMonthly === "yearly"
                                ? "Billed Yearly"
                                : ""}
                            </Typography>
                          )}
                        </Box>
                        {billingMonthly === "monthly" &&
                          monthlyPrice &&
                          !monthlyPrice[0]?.matchesPriceId && (
                            <>
                              <CardActions sx={{ width: "100%", p: 0 }}>
                                <Button
                                  disabled={details?.userSubscription?.status == "cancelled" ? true : false || disBtn}
                                  fullWidth
                                  variant="contained"
                                  sx={{ borderRadius: "8px",fontSize:'14px' }}
                                  onClick={() =>
                                    details &&
                                      details.userSubscription &&
                                      Object.keys(details.userSubscription)
                                        .length !== 0
                                      ? handleCheckoutForm(
                                        monthlyPrice && monthlyPrice[0]?.id,
                                        monthlyPrice[0]
                                      )
                                      : agreeFunction(
                                        monthlyPrice && monthlyPrice[0]?.id
                                      )
                                  }
                                >
                                  {details &&
                                    details?.userSubscription &&
                                    Object.keys(details?.userSubscription)
                                      .length != 0
                                    ? "Update Plan"
                                    : "Get Started"}
                                </Button>
                              </CardActions>
                            </>
                          )}
                        {billingMonthly === "quarterly" &&
                          quartelyPrice &&
                          !quartelyPrice[0]?.matchesPriceId && (
                            <>
                              <CardActions sx={{ width: "100%", p: 0 }}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  sx={{ borderRadius: "8px",fontSize:'14px' }}
                                  disabled={details?.userSubscription?.status == "cancelled" ? true : false}
                                  onClick={() =>
                                    details &&
                                      details.userSubscription &&
                                      Object.keys(details.userSubscription)
                                        .length !== 0
                                      ? handleCheckoutForm(
                                        quartelyPrice && quartelyPrice[0]?.id,
                                        quartelyPrice[0]
                                      )
                                      : agreeFunction(
                                        quartelyPrice && quartelyPrice[0]?.id
                                      )
                                  }
                                >
                                  {details &&
                                    Object.keys(details?.userSubscription)
                                      .length != 0
                                    ? "Update Plan"
                                    : "Get Started"}
                                </Button>
                              </CardActions>
                            </>
                          )}
                        {billingMonthly === "yearly" &&
                          yearlyPrice &&
                          !yearlyPrice[0]?.matchesPriceId && (
                            <>
                              <CardActions sx={{ width: "100%", p: 0 }}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  sx={{ borderRadius: "8px",fontSize:'14px' }}
                                  disabled={details?.userSubscription?.status == "cancelled" ? true : false}
                                  onClick={() =>
                                    details &&
                                      details.userSubscription &&
                                      Object.keys(details.userSubscription)
                                        .length !== 0
                                      ? handleCheckoutForm(
                                        yearlyPrice && yearlyPrice[0]?.id,
                                        yearlyPrice[0]
                                      )
                                      : agreeFunction(
                                        yearlyPrice && yearlyPrice[0]?.id
                                      )
                                  }
                                >
                                  {details &&
                                    Object.keys(details?.userSubscription)
                                      .length != 0
                                    ? "Update Plan"
                                    : "Get Started"}
                                </Button>
                              </CardActions>
                            </>
                          )}
                        {/* {isSubscribed && (
                          <Button
                            className="additional-btn"
                            onClick={() => handleInputBox(true)}
                          >
                            Add Agents
                          </Button>
                        )} */}
                        {/* && showInputBox */}
                        {isSubscribed && (
                          // <>
                          //   <div className="field-additional">
                          //     <div className="quantity-div">
                          //       <h4>Add-on AI Agent 1</h4>
                          //       <span
                          //         className="minus-icon"
                          //         onClick={() =>
                          //           handlePlusMinus(
                          //             "minus",
                          //             data?.metadata,
                          //             additionalAgentNumber
                          //           )
                          //         }
                          //       >
                          //         <svg
                          //           xmlns="http://www.w3.org/2000/svg"
                          //           version="1.1"
                          //           xmlnsXlink="http://www.w3.org/1999/xlink"
                          //           width="14"
                          //           height="14"
                          //           x="0"
                          //           y="0"
                          //           viewBox="0 0 121.805 121.804"
                          //           style={{
                          //             enableBackground: "new 0 0 512 512",
                          //           }}
                          //           xmlSpace="preserve"
                          //         >
                          //           <g>
                          //             <path
                          //               d="M7.308 68.211h107.188a7.309 7.309 0 0 0 7.309-7.31 7.308 7.308 0 0 0-7.309-7.309H7.308a7.31 7.31 0 0 0 0 14.619z"
                          //               fill="#000000"
                          //               opacity="1"
                          //               data-original="#000000"
                          //             ></path>
                          //           </g>
                          //         </svg>
                          //       </span>

                          //       <input
                          //         type="number"
                          //         max={data?.metadata?.Agents}
                          //         value={inputValue}
                          //         onChange={(e) =>
                          //           handleInputChange(e, data?.metadata)
                          //         }
                          //         onBlur={() =>
                          //           handleInputBlur(data?.priceArray[0]?.id)
                          //         }
                          //         disabled={true}
                          //       />
                          //       <span
                          //         className="plus-icon"
                          //         onClick={() =>
                          //           handlePlusMinus(
                          //             "plus",
                          //             data?.metadata,
                          //             additionalAgentNumber
                          //           )
                          //         }
                          //       >
                          //         <svg
                          //           xmlns="http://www.w3.org/2000/svg"
                          //           version="1.1"
                          //           xmlnsXlink="http://www.w3.org/1999/xlink"
                          //           width="14"
                          //           height="14"
                          //           x="0"
                          //           y="0"
                          //           viewBox="0 0 426.667 426.667"
                          //           style={{
                          //             enableBackground: "new 0 0 512 512",
                          //           }}
                          //           xmlSpace="preserve"
                          //           class=""
                          //         >
                          //           <g>
                          //             <path
                          //               d="M405.332 192H234.668V21.332C234.668 9.559 225.109 0 213.332 0 201.559 0 192 9.559 192 21.332V192H21.332C9.559 192 0 201.559 0 213.332c0 11.777 9.559 21.336 21.332 21.336H192v170.664c0 11.777 9.559 21.336 21.332 21.336 11.777 0 21.336-9.559 21.336-21.336V234.668h170.664c11.777 0 21.336-9.559 21.336-21.336 0-11.773-9.559-21.332-21.336-21.332zm0 0"
                          //               fill="#000000"
                          //               opacity="1"
                          //               data-original="#000000"
                          //             ></path>
                          //           </g>
                          //         </svg>
                          //       </span>
                          //     </div>
                          //     <Button
                          //       className="btn btn-success"
                          //       onClick={() =>
                          //         buyExtraAgent(
                          //           billingMonthly === "monthly"
                          //             ? monthlyPrice[0]?.additionalData?.id
                          //             : billingMonthly === "quarterly"
                          //               ? quartelyPrice[0]?.additionalData?.id
                          //               : yearlyPrice[0]?.additionalData?.id,
                          //           SubscriptionId
                          //         )
                          //       }
                          //       disabled={inputValue === 0 ? true : false}
                          //     >
                          //       Add Agent
                          //     </Button>
                          //     <Button
                          //       className="btn btn-success cancel-additional"
                          //       onClick={() => handleInputBox(false)}
                          //     >
                          //       Cancel
                          //     </Button>
                          //   </div>
                          // </>
                          <CardActions sx={{ width: "100%", p: 0 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              disabled
                              sx={{ borderRadius: "8px" }}
                            >
                              Current Plan
                            </Button>
                          </CardActions>
                        )}
                      </Box>
                    </CardContent>
                    <Divider
                      sx={{
                        height: { xs: "0%", sm: "100%" },
                        width: { xs: "100%", sm: "0%" },
                      }}
                      orientation="vertical"
                    />
                    <CardContent
                      sx={{
                        p: 0,
                        "&.MuiCardContent-root": {
                          p: "24px",
                          pt: "16px",
                        },
                      }}
                      className="feature-div"
                    >
                      <Features
                        featureList={data.features}
                        plan={data?.metadata?.planName}
                        data={data?.metadata}
                        setAddPrice={setAddPrice}
                        priceid={data?.priceArray && data?.priceArray[0]?.id}
                        subscriptionData={
                          billingMonthly === "monthly"
                            ? (monthlyPrice[0]?.matchesPriceId &&
                              monthlyPrice[0]?.subscriptionData) ||
                            {}
                            : billingMonthly === "quarterly"
                              ? (quartelyPrice[0]?.matchesPriceId &&
                                quartelyPrice[0]?.subscriptionData) ||
                              {}
                              : (yearlyPrice[0]?.matchesPriceId &&
                                yearlyPrice[0]?.subscriptionData) ||
                              {}
                        }
                        inputVisible={inputVisible}
                        details={details}
                        billingMonthly={
                          billingMonthly === "monthly"
                            ? true
                            : billingMonthly === "quarterly"
                              ? true
                              : true
                        }
                        additionalData={
                          billingMonthly === "monthly"
                            ? (monthlyPrice[0]?.additionalData &&
                              monthlyPrice[0]?.additionalData) ||
                            {}
                            : billingMonthly === "quarterly"
                              ? (quartelyPrice[0]?.additionalData &&
                                quartelyPrice[0]?.additionalData) ||
                              {}
                              : (yearlyPrice[0]?.additionalData &&
                                yearlyPrice[0]?.additionalData) ||
                              {}
                        }
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      {details && details.customerId ? (
        <div className="payment-card">
          <p className="payment-title">Invoice List</p>
          {/* {cardData && cardData?.map((e) =>
            <>
              <div className="payment-card-wrap">
                <p className="card-info">Card information</p>
                <p className="card-code">{e.brand.toUpperCase()} Ending in {e.last4}</p>
                <p className="name-on-card">Name on card</p>
                <p className="card-name">{e.name}</p>
              </div>
            </>
          )} */}
          {/* <div className="payment-btn">
            <button onClick={geterateBillingPortal} className="payment-update">Update credit card</button>
          </div> */}
          <table>
            <thead>
              <tr>
                <th>Amount Paid</th>
                <th>Paid Date</th>
                <th>Next Billing Date</th>
                {/* <th>Status</th> */}
              </tr>
            </thead>

            {invoiceData &&
              invoiceData?.map((e) => (
                // <table >
                <tbody>
                  <tr>
                    <td className="card-name">
                      <a href={`${e.invoice_pdf}`} className="card-name">
                        {currencyFormatter?.format(Math.abs(e.total / 100))
                        }{" "}
                      </a>
                    </td>
                    <td>{formatTimestamp(e.lines.data[0].period.start)}</td>
                    {details?.userSubscription?.SubscriptionId === e.subscription && details?.userSubscription?.priceId === e.lines?.data[1]?.price.id && (
                      e.billing_reason === "subscription_update" ?
                        <>
                          <td>
                            <span>{formatTimestamp(e.lines.data[0].period.end)}</span>
                          </td>
                          {/* <td>
                            <span>{"Upgrade"}</span>
                          </td> */}
                        </> :
                        <>
                          <td>
                            <span>{formatTimestamp(e.lines.data[0].period.end)}</span>
                          </td>
                          {/* <td>
                            <span>{"Create"}</span>
                          </td> */}
                        </>
                    )}
                    {details?.userSubscription?.SubscriptionId === e.subscription && details?.userSubscription?.priceId === e.lines?.data[0]?.price.id && (
                      <td>
                        <span>{formatTimestamp(e.lines.data[0].period.end)}</span>
                      </td>
                    )}
                  </tr>
                </tbody>
                // </table>
                // <div className="payment-card-wrap">
                //   <a href={`${e.invoice_pdf}`} className="card-name">
                //     {
                //       /*e.
                //   amount_paid*/ currencyFormatter?.format(Math.abs(e.total / 100))
                //     }{" "}
                //     {/* &nbsp;&nbsp; || &nbsp;&nbsp; {formatTimestamp(e.created)} */}
                //     &nbsp;&nbsp; || &nbsp;&nbsp; {formatTimestamp(e.lines.data[0].period.start)}
                //     &nbsp;&nbsp; - &nbsp;&nbsp; {formatTimestamp(e.lines.data[0].period.end)}
                //     {/* &nbsp;&nbsp; || &nbsp;&nbsp; {formatTimestamp(e.lines.data[0].period.start)} */}
                //   </a>
                // </div>
              ))}
          </table>
          <div className="payment-btn">
            {seeData ? (
              <button
                onClick={() => {
                  getInvoiceData();
                }}
                className="payment-update"
              >
                See Less
              </button>
            ) : (
              <button
                onClick={() => {
                  getInvoiceData(100);
                }}
                className="payment-update"
              >
                See More
              </button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {disBtn ? (
        <div className="loader-wrap">
          <div className="spinLoder">
            <CircularProgress />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Plan;
