import React from "react";

export const RefreshIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.167 4.27118C15.9348 5.55912 17.0837 7.6454 17.0837 10C17.0837 13.912 13.9123 17.0833 10.0003 17.0833H9.58366M5.83366 15.7288C4.06588 14.4409 2.91699 12.3546 2.91699 10C2.91699 6.08799 6.08831 2.91668 10.0003 2.91668H10.417M10.8337 18.6667L9.16699 17L10.8337 15.3333M9.16699 4.66668L10.8337 3.00001L9.16699 1.33334"
          stroke="#050C46"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
