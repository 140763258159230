import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Stack,
  Typography,
  TextField,
  Box,
  IconButton,
  Checkbox,
  Chip,
  Button,
} from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TECHNOLOGYOPTIONS } from "src/data/campaign";

const CustomRenderOption = (props) => {
  const {
    extra,
    option,
    state,
    ownerState,
    setOwnerState,
    setValue: onChange,
    vid,
    setShowSubHeadings,
    singleSelect,
    options,
    isTechnology,
    selectedValue,
    setAllTechnology,
    allTechnology,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(
    ownerState.technology === undefined
      ? false
      : isTechnology
      ? selectedValue?.findIndex(({ title }) => title === option.title) !== -1
      : ownerState[vid]?.findIndex(({ title }) => title === option.title) !== -1
  );
  const [subCount, setSubCount] = useState(0);
  useEffect(() => {
    if (isTechnology) {
      setSelected(
        selectedValue?.findIndex(({ title }) => title === option.title) !== -1
      );
    } else {
      setSelected(
        ownerState[vid]?.findIndex(({ title }) => title === option.title) !== -1
      );
    }
  }, [selectedValue, ownerState]);

  const handleChange = (e) => {
    setSelected(e.target.checked);
    if (e.target.checked) {
      if (!option.grpId) {
        let allSelected = options.filter((industryObj) => {
          return industryObj.grpId === option.title;
        });
        setSubCount(allSelected.length);
        setAllTechnology((prev) => {
          const curr = prev;
          const uniqueCheck = new Set(curr?.map((val) => val?.title));
          allSelected = allSelected.filter(
            ({ title, grpId }) => !uniqueCheck.has(title)
          );
          let newValue = [];
          newValue = [...curr, ...allSelected, option];
          return newValue;
        });
        setOwnerState((prev) => {
          // const curr = vid === "blockedIndustries" ? prev[vid] : prev;
          const curr = prev;
          const uniqueCheck = new Set(curr?.map((val) => val.title));
          allSelected = allSelected.filter(
            ({ title, grpId }) => !uniqueCheck.has(title)
          );
          let newValue = [];
          if (isTechnology) {
            newValue = [...curr, ...allSelected];
          } else {
            newValue = [...curr, ...allSelected, option];
          }

          return newValue;
        });
      } else {
        setAllTechnology((prev) => {
          const newValue = [...prev, option];
          return newValue;
        });
        setOwnerState((prev) => {
          // const newValue =
          //   vid === "blockedIndustries"
          //     ? [...prev[vid], option]
          //     : [...prev, option];
          const newValue = [...prev, option];

          const totCount = options.filter(
            ({ grpId }) => grpId === option.grpId
          ).length;

          let headSubSelCount = 0;
          newValue.forEach(({ title, grpId }) => {
            if (grpId === option.grpId) {
              headSubSelCount += 1;
            }
          });

          if (totCount === headSubSelCount) {
            newValue.push({ title: option.grpId });
          }
          let value = [];
          console.log(newValue, "newValue");
          return newValue;
        });
      }
    } else {
      if (!option.grpId) {
        setOwnerState((prev) => {
          // const newValue =
          //   vid === "blockedIndustries"
          //     ? prev[vid].filter(
          //         ({ grpId, title }) =>
          //           grpId !== option.title && !(title === option.title)
          //       )
          //     : prev.filter(
          //         ({ grpId, title }) =>
          //           grpId !== option.title && !(title === option.title)
          //       );
          const newValue = prev?.filter(
            ({ grpId, title }) =>
              grpId !== option.title && !(title === option.title)
          );
          // return {
          //   ...prev,
          //   [vid]: singleSelect ? newValue.slice(-1) : newValue,
          // };
          return newValue;
        });
      } else {
        setOwnerState((prev) => {
          // let newValue =
          //   vid === "blockedIndustries"
          //     ? prev[vid].filter(({ title }) => title !== option.title)
          //     : prev.filter(({ title }) => title !== option.title);
          let newValue = prev.filter(({ title }) => title !== option.title);
          const totCount = options.filter(
            ({ grpId }) => grpId === option.grpId
          ).length;

          let headSubSelCount = 0;
          newValue.forEach(({ title, grpId }) => {
            if (grpId === option.grpId) {
              headSubSelCount += 1;
            }
          });
          if (totCount !== headSubSelCount) {
            newValue = newValue.filter(
              ({ title, grpId }) => title !== option.grpId
            );
          }
          // if (vid === "blockedIndustries") {
          //   return {
          //     ...prev,
          //     [vid]: singleSelect ? newValue.slice(-1) : newValue,
          //   };
          // } else {
          //   return newValue;
          // }
          return newValue;
        });
      }
    }
  };

  useEffect(() => {
    if (expanded) {
      setShowSubHeadings((prev) => [...prev, option.title]);
    } else {
      setShowSubHeadings((prev) => prev.filter((val) => option.title !== val));
    }
  }, [expanded]);

  const groupCount = !option.grpId
    ? options.filter(({ grpId }) => grpId === option.title).length
    : 0;

  return (
    <>
      <Box
        component="li"
        sx={{
          "& > img": {
            mr: 2,
            flexShrink: 0,
          },
        }}
        {...extra}
      >
        <Stack sx={{ width: "100%", ml: Boolean(option.grpId) ? 2 : 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                // icon={checkboxOfIcon}
                // checkedIcon={checkboxOnIcon}
                style={{ marginRight: 8 }}
                // checked={state.selected}
                checked={selected}
                onChange={handleChange}
              />
              {/* <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.value?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.value?.toLowerCase()}.png 2x`}
                alt=""
              /> */}
              <Typography
                sx={{ fontSize: "14px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded((prev) => !prev);
                }}
              >
                {option.title}{" "}
                {!option.grpId && groupCount > 0 && <>({groupCount})</>}
              </Typography>
            </Stack>
            {!Boolean(option.grpId) && groupCount > 0 && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded((prev) => !prev);
                }}
              >
                {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

const mapTitleToValue = (options, title) => {
  const found = options.find((option) => option.title === title);
  return found ? found.value : null;
};

const ApolloTechFilter = (props) => {
  const {
    view,
    vid,
    customVID,
    state,
    setState,
    onChange,
    singleSelect,
    options,
    placeholder,
    isTechnology,
    techTitle,
    setAllTechnology,
    allTechnology,
  } = props;

  //   const selectedValues = industryTypes.filter((type) =>
  //     state[vid]?.includes(type.title)
  //   );

  const selectedValues = state[vid];

  const disabled = view === true;
  const [showSubHeadings, setShowSubHeadings] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // restructure array incase array is flat
  let tempValue = state[vid];

  // const selectedValue = tempValue && tempValue.length > 0 && tempValue.map((selected) => {

  const selectedValue = tempValue?.map((selected) => {
    if (selected?.title) {
      return selected;
    } else {
      return {
        title: selected,
        value: mapTitleToValue(options, selected),
      };
    }

    //  return title;
  });

  return (
    <>
      {/* <Typography sx={{ color: "#050C46", fontSize: "14px", fontWeight: "500", mb:'10px'}}>{placeholder}</Typography> */}
      <Autocomplete
        // freeSolo
        disabled={disabled}
        multiple
        id="filter-industry"
        sx={{ width: "100%" }}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        // groupBy={(option) => option.grpId }
        renderInput={(params) => (
          <Stack spacing={1}>
            {/* <Typography sx={{ fontSize: "14px", color: "#050C46" }}>
            {vid === "blockedIndustries"
              ? "Exclude Industries (New)"
              : "Industries (New)"}
          </Typography> */}
            <TextField
              {...params}
              id="outlined-basic"
              // label={placeholder}
              variant="outlined"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                mt: "10px",
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          </Stack>
        )}
        renderOption={(props, option, persState) => {
          if (
            option.grpId &&
            !showSubHeadings.includes(option.grpId) &&
            !isSearching
          )
            return;
          return (
            <CustomRenderOption
              extra={props}
              option={option}
              state={persState}
              ownerState={state}
              vid={customVID ? customVID : vid}
              setShowSubHeadings={setShowSubHeadings}
              setValue={onChange}
              setOwnerState={setState}
              singleSelect={singleSelect}
              options={options}
              isTechnology={isTechnology}
              selectedValue={allTechnology ? allTechnology : techTitle}
              setAllTechnology={setAllTechnology}
            />
          );
        }}
        filterOptions={(options, state) => {
          setIsSearching(true);
          if (!state.inputValue) {
            setIsSearching(false);
            return options;
          }
          // else {
          //   return [{ title: state.inputValue }];
          // }
          return options.filter((val) =>
            new RegExp(state.inputValue, "ig").test(val.title)
          );
        }}
        // value={state[customVID ? customVID : vid]}
        value={techTitle ? techTitle : selectedValue}
        onChange={(event, newValue, reason) => {
          if (reason === "removeOption") {
            // if (vid === "blockedIndustries") {
            //   onChange(
            //     customVID ? customVID : vid,

            //     singleSelect ? newValue.slice(-1) : newValue
            //   );
            // } else {
            //   console.log("deleted", newValue);
            //   setState(newValue);
            // }
            setAllTechnology(newValue);
            setState(newValue);
          }
          if (reason === "clear") {
            // onChange(customVID ? customVID : vid, []);
            setState([]);
            setAllTechnology([]);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option?.title ? option?.title : option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: "#050C46",
                color: "white",
                "& .MuiChip-deleteIcon": {
                  backgroundColor: "transparent !important",
                  color: "white !important",
                  display: disabled ? "none" : "",
                  "&:hover": {
                    color: "white",
                  },
                },
              }}
            />
          ))
        }
      />
      <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          sx={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 600,
            mr: 0.5,
            color: "blue",
            "&:hover": { color: "red" },
          }}
          onClick={() => {
            setAllTechnology([]);
          }}
        >
          Clear
        </Button>
      </Box>
    </>
  );
};

export default ApolloTechFilter;
